import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { signup } from "../ducks/auth";

import SignupForm from "./SignupForm";

const SignupWrapper = styled.div`
  max-width: 670px;
  margin: 16px auto;
  padding: 24px;

  border-radius: 10px;
  background: #fff;
`;

const SignupContent = styled.div`
  max-width: 430px;
  margin: auto;
`;

const SignupFormStyled = styled(SignupForm)`
  max-width: 430px;
  margin: auto;
  padding: 24px;
  padding-bottom: 0;
`;

const Header = styled.h1`
  text-align: center;
`;

const Description = styled.p`
  text-align: center;
  color: #777777
  font-size: 1em;
`;

const LoginLink = styled(Link)`
  text-align: center;
  display: block;
`;

const SignupPage = ({ signup }) => (
  <SignupWrapper>
    <SignupContent>
      <Header>Crie sua conta</Header>
      <Description>
        E comece agora a melhorar a produtividade e a comunicação da sua
        empresa.
      </Description>

      <SignupFormStyled submit={signup} />

      <LoginLink to="/">Já possui uma conta? Faça login aqui.</LoginLink>
    </SignupContent>
  </SignupWrapper>
);

export default connect(null, { signup })(SignupPage);
