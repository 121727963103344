import axios from "axios";

export default {
    fetchAll: () => {
        return axios
            .get("/api/v1/permissions")
            .then(response => response.data.data);
    },
    fetchAllPages: () => {
        return axios
            .get("/api/v1/permissions/pages")
            .then(response => response.data.data);
    },
    create: data => {
        return axios
            .post(`/api/v1/permissions`, data)
            .then(response => response.data.data);
    },
    update: (id, data) => {
        return axios
            .post(`/api/v1/permissions/${id}`, data)
            .then(response => response.data.data);
    },
    delete: id => {
        return axios.delete(`/api/v1/permissions/${id}`);
    }
};
