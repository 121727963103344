import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Table,
  Dimmer,
  Loader,
  Button,
  Label
} from "semantic-ui-react";

import { SortableTableHeader } from "../../Shared";

import {
  getLoading,
  getPermissions,
  fetchAll,
  editClick,
  deleteClick
} from "../ducks/permission";

const HEADERS = [
  { name: null, label: "Nome" },
  { name: null, label: "Páginas com acesso" },
  { name: null, label: "Ações" }
];

class PermissionTable extends Component {
  componentDidMount() {
    this.props.fetchAll();
  }

  renderTableBody() {
    const { permissions, canEdit } = this.props;
    return (
      <Table.Body>
        {permissions.map((permission, key) => (
          <Table.Row key={permission.id}>
            <Table.Cell>{permission.name}</Table.Cell>
            <Table.Cell>
              {permission.pages.map(p => (
                <Label key={p.id} color={p.rule.edit ? "blue" : undefined}>
                  {p.name}
                </Label>
              ))}
            </Table.Cell>
            <Table.Cell width={3}>
              <Button
                icon="edit"
                basic
                disabled={!canEdit}
                onClick={() => this.props.editClick(permission)}
                style={{ marginRight: "20px" }}
              />
              <Button
                icon="delete"
                basic
                disabled={!canEdit}
                onClick={() => this.props.deleteClick(permission)}
                style={{ marginRight: "20px" }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  render() {
    const { loading } = this.props;
    return (
      <Container fluid>
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        <Table selectable>
          <SortableTableHeader headers={HEADERS} />
          {this.renderTableBody()}
        </Table>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: getLoading(state),
    permissions: getPermissions(state)
  };
}

export default connect(mapStateToProps, { fetchAll, editClick, deleteClick })(
  PermissionTable
);
