import firebase from "firebase/app";
import "firebase/messaging";

export function firebaseInit() {
  if (window && window.process && window.process.type) {
    // Only config firebase on web, electron uses native notification
    return;
  }

  var config = {
    apiKey: "AIzaSyC7up2mP85-iLHp-XKk1DDJmSAvWeIC2G4",
    authDomain: "go4-andon.firebaseapp.com",
    databaseURL: "https://go4-andon.firebaseio.com",
    projectId: "go4-andon",
    storageBucket: "go4-andon.appspot.com",
    messagingSenderId: "937494942459",
    appId: "1:937494942459:web:d926b90dc8382fb527965e"
  };
  firebase.initializeApp(config);

  if (!firebase.messaging.isSupported()) {
    alert(
      "Notificações não habilitadas, verifique se você está usando uma conexão segura (https) e utilizando um navegador suportado (Chrome ou Firefox)"
    );
    return;
  }

  try {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a sevice worker
    //   `messaging.setBackgroundMessageHandler` handler.
    firebase.messaging().onMessage(function (payload) {
      console.log("Message received. ", payload);
      // ...
    });
  } catch (e) {
    console.error(e);
  }
}

export function getFirebaseToken() {
  if (window && window.process && window.process.type) {
    // Only config firebase on web, electron uses native notification
    return Promise.reject("electron app");
  } else if (!firebase.messaging.isSupported()) {
    return Promise.reject("Firebase messaging not supported");
  }

  return firebase
    .messaging()
    .getToken()
    .then((token) => {
      if (token) {
        return token;
      } else {
        throw new Error(
          "current origin does not have permission to show notifications"
        );
      }
    });
}
