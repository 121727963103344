import { createAction } from "redux-actions";
import api from "../../../api";

import get from "lodash/get";

import { OCCURRENCE_STATUS as STATUS } from "../../../constants";

import { setErrorMessage } from "../../App";
import { getMachines } from "../../Machines";

import {
  MACHINE_SELECTED,
  CARD_LOADING,
  OCCURRENCE_LOADING,
  OCCURRENCE_CANCELED,
  OCCURRENCE_FINISHED,
  OCCURRENCE_ARRIVED,
  OCCURRENCE_REGISTERED,
  OCCURRENCE_ESCALATED,
  REDUCER_NAME,
  ACHIEVEMENT_LOADING,
  ACHIEVEMENT_SENDED,
  SHOW_FINISH_OBSERVATION_FORM,
  CLOSE_FINISH_OBSERVATION_FORM,
  ADD_MACHINE_BOOKMARK,
  REMOVE_MACHINE_BOOKMARK,
} from "../constants";

export const machineSelected = createAction(MACHINE_SELECTED);
export const cardLoading = createAction(CARD_LOADING);
export const occurrenceLoading = createAction(OCCURRENCE_LOADING);
export const occurrenceRegistered = createAction(OCCURRENCE_REGISTERED);
export const occurrenceArrived = createAction(OCCURRENCE_ARRIVED);
export const occurrenceFinished = createAction(OCCURRENCE_FINISHED);
export const occurrenceCanceled = createAction(OCCURRENCE_CANCELED);
export const occurrenceEscalated = createAction(OCCURRENCE_ESCALATED);
export const achievementLoading = createAction(ACHIEVEMENT_LOADING);
export const achievementSended = createAction(ACHIEVEMENT_SENDED);
export const showFinishObservationForm = createAction(
  SHOW_FINISH_OBSERVATION_FORM
);

export const closeFinishObservationForm = createAction(
  CLOSE_FINISH_OBSERVATION_FORM
);
export const addMachineBookmark = createAction(ADD_MACHINE_BOOKMARK);
export const removeMachineBookmark = createAction(REMOVE_MACHINE_BOOKMARK);

const defaultErrorHandler = (dispatch) => (error) => {
  dispatch(cardLoading(false));
  dispatch(occurrenceLoading(false));
  dispatch(
    setErrorMessage(
      get(error, "response.data.error", "Problema na conexão, tente novamente")
    )
  );
};

export const registerOccurrence = (data) => (dispatch) => {
  const now = new Date().toISOString();

  dispatch(occurrenceLoading(true));
  return api.occurrences
    .create({ ...data, started_on: now, waited_on: now })
    .then((occurrence) => dispatch(occurrenceRegistered(occurrence)))
    .catch(defaultErrorHandler(dispatch));
};

export const onArrivedOccurrence = (occurrenceId) => (dispatch) => {
  dispatch(occurrenceLoading(true));
  return api.occurrences
    .update(occurrenceId, { arrived_on: new Date().toISOString() })
    .then((occurrence) => dispatch(occurrenceArrived(occurrence)))
    .catch(defaultErrorHandler(dispatch));
};

export const onFinishedOccurrence = (occurrenceId) => (dispatch) => {
  dispatch(occurrenceLoading(true));
  return api.occurrences
    .update(occurrenceId, {
      finished_on: new Date().toISOString(),
    })
    .then((occurrence) => dispatch(occurrenceFinished(occurrence)))
    .catch(defaultErrorHandler(dispatch));
};

export const onCanceledOccurrence = (occurrenceId) => (dispatch) => {
  dispatch(occurrenceLoading(true));
  return api.occurrences
    .update(occurrenceId, { canceled_on: new Date().toISOString() })
    .then((occurrence) => dispatch(occurrenceCanceled(occurrence)))
    .catch(defaultErrorHandler(dispatch));
};

export const selectMachine = (id) => (dispatch, getState) => {
  const machines = getMachines(getState());
  const staleMachine = machines.find((machine) => machine.id === id);
  if (staleMachine) {
    dispatch(machineSelected(staleMachine));
  }

  dispatch(cardLoading(true));

  return api.machines
    .getById(id)
    .then((machine) => dispatch(machineSelected(machine)))
    .catch(defaultErrorHandler(dispatch));
};

export const notifyAchievement = (id) => (dispatch, getState) => {
  const machine = getSelectedMachine(getState());

  if (!machine) return;

  dispatch(achievementLoading());

  return api.machines
    .notifyAchievement(machine.id)
    .then(() => dispatch(achievementSended()));
};

export const nextStateOccurrence = (formData) => (dispatch, getState) => {
  const state = getState();
  const occurrence = getActiveOccurrence(state);
  const machine = getSelectedMachine(state);

  const status = get(occurrence, "status");

  if (status === STATUS.WAITING) {
    dispatch(onArrivedOccurrence(occurrence.id));
  } else if (status === STATUS.ATTENDING) {
    dispatch(showFinishObservationForm());
    dispatch(onFinishedOccurrence(occurrence.id));
  } else {
    dispatch(
      registerOccurrence({ machine_id: get(machine, "id"), ...formData })
    );
  }
};

export const escalateOccurrence = () => (dispatch, getState) => {
  const occurrence = getActiveOccurrence(getState());

  if (!occurrence) return;

  dispatch(occurrenceLoading(true));
  return api.occurrences
    .escalate(occurrence.id)
    .then((occurrence) => {
      dispatch(occurrenceEscalated(occurrence));
    })
    .catch(defaultErrorHandler(dispatch));
};

export const submitFinishObservationForm = (formData) => (
  dispatch,
  getState
) => {
  const occurrenceId = getObservationOccurrenceId(getState());

  dispatch(occurrenceLoading(true));
  return api.occurrences
    .update(occurrenceId, formData)
    .then((occurrence) => dispatch(occurrenceFinished(occurrence)))
    .catch(defaultErrorHandler(dispatch))
    .then(() => dispatch(closeFinishObservationForm()));
};

const initialState = {
  achievementLoading: false,
  cardLoading: false,
  loading: false,
  selected: undefined,
  activeOccurrence: undefined,
  showFinishObservationForm: false,
  observationOccurrenceId: undefined,
  machinesBookmark: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ACHIEVEMENT_LOADING:
      return { ...state, achievementLoading: true };
    case ACHIEVEMENT_SENDED:
      return { ...state, achievementLoading: false };
    case OCCURRENCE_LOADING:
      return { ...state, loading: action.payload };
    case OCCURRENCE_REGISTERED:
      return {
        ...state,
        loading: false,
        activeOccurrence: action.payload,
      };
    case OCCURRENCE_ARRIVED:
      return {
        ...state,
        loading: false,
        activeOccurrence: action.payload,
      };
    case OCCURRENCE_FINISHED:
      return {
        ...state,
        loading: false,
        activeOccurrence: action.payload,
      };
    case OCCURRENCE_CANCELED:
      return {
        ...state,
        loading: false,
        activeOccurrence: action.payload,
      };
    case OCCURRENCE_ESCALATED:
      return {
        ...state,
        loading: false,
        activeOccurrence: action.payload,
      };
    case CARD_LOADING:
      return { ...state, cardLoading: action.payload };
    case MACHINE_SELECTED:
      return {
        ...state,
        cardLoading: false,
        selected: action.payload,
        activeOccurrence: action.payload.last_occurrence,
      };
    case SHOW_FINISH_OBSERVATION_FORM:
      return {
        ...state,
        showFinishObservationForm: true,
        observationOccurrenceId: get(state.activeOccurrence, "id"),
      };
    case CLOSE_FINISH_OBSERVATION_FORM:
      return { ...state, showFinishObservationForm: false };
    case ADD_MACHINE_BOOKMARK:
      const shouldAddBookmark = state.machinesBookmark.every(
        ({ id }) => id !== action.payload.id
      );

      return {
        ...state,
        machinesBookmark: shouldAddBookmark
          ? state.machinesBookmark.concat([action.payload])
          : state.machinesBookmark,
      };
    case REMOVE_MACHINE_BOOKMARK:
      return {
        ...state,
        machinesBookmark: state.machinesBookmark.filter(
          ({ id }) => id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const getShowFinishObservationForm = (state) =>
  state[REDUCER_NAME].showFinishObservationForm;

export const getAchievementLoading = (state) =>
  state[REDUCER_NAME].achievementLoading;
export const getCardLoading = (state) => state[REDUCER_NAME].cardLoading;
export const getLoading = (state) => state[REDUCER_NAME].loading;
export const getActiveOccurrence = (state) =>
  state[REDUCER_NAME].activeOccurrence;
export const getSelectedMachine = (state) => state[REDUCER_NAME].selected;
const getObservationOccurrenceId = (state) =>
  state[REDUCER_NAME].observationOccurrenceId;

export const getMachinesBookmark = (state) =>
  state[REDUCER_NAME].machinesBookmark;
