import React, { Component } from "react";

import { Modal, Button } from "semantic-ui-react";

class FormModal extends Component {
    state = { loading: false };

    constructor(props) {
        super(props);

        this.stopLoading = this.stopLoading.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    stopLoading() {
        this.setState({ loading: false });
    }

    onSaveClick() {
        this.setState({ loading: true });
        return this.props
            .formRef()
            .onSubmit()
            .then(() => this.stopLoading(), () => this.stopLoading());
    }

    render() {
        const { header, formRef, ...rest } = this.props;
        const { loading } = this.state;

        return (
            <Modal {...rest}>
                <Modal.Header style={{ fontSize: "1.5em" }}>
                    {header}
                </Modal.Header>
                <Modal.Content>{this.props.children}</Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.props.onClose()}>
                        Cancelar
                    </Button>
                    <Button
                        primary
                        onClick={() => this.onSaveClick()}
                        loading={loading}
                        id="form_modal_save_btn"
                    >
                        Salvar
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default FormModal;
