import { combineReducers } from "redux";

import { AppReducer, REDUCER_NAME as AppReducerName } from "./modules/App";

import {
  NotificationRulesReducer,
  REDUCER_NAME as NotificationRulesReducerName,
} from "./modules/NotificationRules";

import {
  FilterRulesReducer,
  REDUCER_NAME as FilterRulesReducerName,
} from "./modules/FilterRules";

import {
  PrioritiesReducer,
  REDUCER_NAME as PrioritiesReducerName,
} from "./modules/Priorities";

import {
  MachinesReducer,
  REDUCER_NAME as MachinesReducerName,
} from "./modules/Machines";

import {
  UsersReducer,
  REDUCER_NAME as UsersReducerName,
} from "./modules/Users";

import {
  PermissionsReducer,
  REDUCER_NAME as PermissionsReducerName,
} from "./modules/Permissions";

import {
  SectorsReducer,
  REDUCER_NAME as SectorsReducerName,
} from "./modules/Sectors";

import {
  CallTechnicianReducer,
  REDUCER_NAME as CallTechnicianReducerName,
} from "./modules/CallTechnician";

import {
  KanbanReducer,
  REDUCER_NAME as KanbanReducerName,
} from "./modules/Kanban";

import {
  OverviewReducer,
  REDUCER_NAME as OverviewReducerName,
} from "./modules/Overview";

import {
  OccurrencesReducer,
  REDUCER_NAME as OccurrencesReducerName,
} from "./modules/Occurrences";

import { AuthReducer, REDUCER_NAME as AuthReducerName } from "./modules/Auth";
import { USER_LOGOUT } from "./modules/Auth/constants";

import {
  TutorialReducer,
  REDUCER_NAME as TutorialReducerName,
} from "./modules/Tutorial";

import { HelpReducer, REDUCER_NAME as HelpReducerName } from "./modules/Help";

const rootReducer = combineReducers({
  [AppReducerName]: AppReducer,
  [AuthReducerName]: AuthReducer,
  [NotificationRulesReducerName]: NotificationRulesReducer,
  [FilterRulesReducerName]: FilterRulesReducer,
  [PrioritiesReducerName]: PrioritiesReducer,
  [MachinesReducerName]: MachinesReducer,
  [UsersReducerName]: UsersReducer,
  [PermissionsReducerName]: PermissionsReducer,
  [SectorsReducerName]: SectorsReducer,
  [CallTechnicianReducerName]: CallTechnicianReducer,
  [KanbanReducerName]: KanbanReducer,
  [OverviewReducerName]: OverviewReducer,
  [OccurrencesReducerName]: OccurrencesReducer,
  [TutorialReducerName]: TutorialReducer,
  [HelpReducerName]: HelpReducer,
});

export default (state, action = {}) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return rootReducer(state, action);
};
