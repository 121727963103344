import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import userHasPage from "../../../utils/userHasPage";

import { getUser, getLoading } from "../../Auth";

const UserRoute = ({ user, slug, loading, component: Component, ...rest }) => {
    if (loading) {
        return null;
    }
    return (
        <Route
            {...rest}
            render={props =>
                userHasPage(user, slug, false) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

UserRoute.propTypes = {
    component: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        user: getUser(state),
        loading: getLoading(state)
    };
}

export default connect(mapStateToProps)(UserRoute);
