import React from "react";
import { connect } from "react-redux";
import { Modal, Header } from "semantic-ui-react";

import get from "lodash/get";

import { SectorHighlight } from "../../Shared";

import { getAlertOccurrence, clearOccurrenceAlert } from "../ducks/kanban";

class OccurrenceAlertModal extends React.Component {
  render() {
    const { occurrence } = this.props;

    if (!occurrence) return null;

    return (
      <Modal
        basic
        closeIcon={true}
        open={!!occurrence}
        onClose={() => this.props.clearOccurrenceAlert()}
        style={{ fontSize: "2em" }}
        size="large"
      >
        <Modal.Header style={{ fontSize: "6em", textAlign: "center" }}>
          {get(occurrence, "machine.name")}
        </Modal.Header>
        <Modal.Content style={{textAlign: "center"}}>
          {occurrence.priority && <PriorityHighlight priority={occurrence.priority} />}
          {occurrence.sector && <SectorHighlight sector={occurrence.sector} />}
        </Modal.Content>
      </Modal>
    );
  }
}

const PriorityHighlight = ({ priority }) => (
    <Header as="h2" style={{fontSize: "1.5em", color: "#fff"}}>{priority.name}</Header>
)

function mapStateToProps(state) {
  return {
    occurrence: getAlertOccurrence(state),
  };
}

export default connect(mapStateToProps, { clearOccurrenceAlert })(
  OccurrenceAlertModal
);
