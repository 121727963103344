import { createAction } from "redux-actions";

import isEmpty from "lodash/isEmpty";

import {
    FETCH_ALL_REQUEST,
    FETCH_ALL_SUCCESS,
    UPDATE_SUCCESS,
    CREATE_SUCCESS,
    DELETE_SUCCESS,
    ADD_CLICK,
    EDIT_CLICK,
    EDIT_PWD_CLICK,
    DELETE_CLICK,
    DELETE_CANCELED,
    EDIT_CANCELED,
    EDIT_PWD_CANCELED,
    REDUCER_NAME
} from "../constants";

import api from "../../../api";

const fetchAllRequest = createAction(FETCH_ALL_REQUEST);
const fetchAllSuccess = createAction(FETCH_ALL_SUCCESS);

const updateSuccess = createAction(UPDATE_SUCCESS);
const createSuccess = createAction(CREATE_SUCCESS);
const deleteSuccess = createAction(DELETE_SUCCESS);

export const addClick = createAction(ADD_CLICK);
export const editClick = createAction(EDIT_CLICK);
export const editPwdClick = createAction(EDIT_PWD_CLICK);
export const deleteClick = createAction(DELETE_CLICK);
export const cancelDelete = createAction(DELETE_CANCELED);
export const cancelEdit = createAction(EDIT_CANCELED);
export const cancelPwdEdit = createAction(EDIT_PWD_CANCELED);

export const fetchAll = (force = false) => (dispatch, getState) => {
    const users = getUsers(getState());
    if (!isEmpty(users) && !force) {
        return dispatch(fetchAllSuccess(users));
    }

    dispatch(fetchAllRequest());

    return api.users.fetchAll().then(users => dispatch(fetchAllSuccess(users)));
};

export const submitEdit = data => (dispatch, getState) => {
    const editUser = getEditUser(getState());

    dispatch(fetchAllRequest());

    if (editUser) {
        return api.users
            .update(editUser.id, data)
            .then(() => dispatch(updateSuccess()))
            .then(() => dispatch(fetchAll(true)));
    } else {
        return api.users
            .create(data)
            .then(() => dispatch(createSuccess()))
            .then(() => dispatch(fetchAll(true)));
    }
};

export const submitPwdForm = data => (dispatch, getState) => {
    const editUser = getEditUser(getState());

    dispatch(fetchAllRequest());

    return api.users
        .resetPassword(editUser.id, data)
        .then(() => dispatch(cancelPwdEdit()))
        .then(() => dispatch(fetchAll(true)));
};

export const confirmDelete = () => (dispatch, getState) => {
    const editUser = getEditUser(getState());

    if (!editUser) return;

    dispatch(deleteSuccess());
    dispatch(fetchAllRequest());

    return api.users.delete(editUser.id).then(() => dispatch(fetchAll(true)));
};

const initialState = {
    users: [],
    pwdFormOpen: false,
    formOpen: false,
    confirmOpen: false,
    editUser: null,
    loading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_ALL_REQUEST:
            return { ...state, loading: true };
        case FETCH_ALL_SUCCESS:
            return { ...state, loading: false, users: action.payload };
        case ADD_CLICK:
            return { ...state, formOpen: true, editUser: null };
        case EDIT_CLICK:
            return { ...state, formOpen: true, editUser: action.payload };
        case EDIT_PWD_CLICK:
            return { ...state, pwdFormOpen: true, editUser: action.payload };
        case DELETE_CLICK:
            return { ...state, confirmOpen: true, editUser: action.payload };
        case CREATE_SUCCESS:
            return { ...state, formOpen: false };
        case UPDATE_SUCCESS:
            return { ...state, formOpen: false, editUser: null };
        case DELETE_SUCCESS:
            return { ...state, confirmOpen: false, editUser: null };
        case EDIT_CANCELED:
            return { ...state, loading: false, formOpen: false, editUser: null };
        case EDIT_PWD_CANCELED:
            return { ...state, loading: false, pwdFormOpen: false, editUser: null };
        case DELETE_CANCELED:
            return { ...state, loading: false, confirmOpen: false, editUser: null };

        default:
            return state;
    }
};

export const getLoading = state => state[REDUCER_NAME].loading;
export const getUsers = state => state[REDUCER_NAME].users;
export const getPwdFormOpen = state => state[REDUCER_NAME].pwdFormOpen;
export const getFormOpen = state => state[REDUCER_NAME].formOpen;
export const getConfirmOpen = state => state[REDUCER_NAME].confirmOpen;
export const getEditUser = state => state[REDUCER_NAME].editUser;
