import isElectron from "./isElectron";
import get from "lodash/get";

let version = undefined;

if (isElectron()) {
  const packageJson = window
    .require("electron")
    .remote.require("../package.json");

  version = get(packageJson, "version");
}

export default version;
