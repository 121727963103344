import React from "react";

import isEmpty from "lodash/isEmpty";

import { Table, Pagination } from "semantic-ui-react";

export default ({ meta, onPageClick, totalLabel, columns }) => {
    if (isEmpty(meta)) return null;

    return (
        <Table.Footer>
            <Table.HeaderCell>Total: {meta.total}</Table.HeaderCell>
            <Table.HeaderCell colSpan={columns - 1} textAlign="right">
                <Pagination
                    activePage={meta.current_page}
                    onPageChange={(e, { activePage }) =>
                        onPageClick(activePage)
                    }
                    totalPages={meta.last_page}
                    siblingRange={3}
                />
            </Table.HeaderCell>
        </Table.Footer>
    );
};
