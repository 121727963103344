import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import api from "../../../api";
import socket from "../../../utils/socket";

const debounceOptions = {
  leading: true,
  trailing: false,
};

const useOccurrenceActivities = (occurrenceId) => {
  const [activities, setActivities] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (occurrenceId === undefined) {
      return;
    }

    function onOccurrencesUpdated(occurrence) {
      if (occurrence.id !== occurrenceId) {
        return;
      }

      setActivities(occurrence.activities);
    }

    api.occurrences
      .fetchActivities(occurrenceId)
      .then(setActivities)
      .then(() => {
        socket.on("occurrences-update", onOccurrencesUpdated);
      });

    return () => {
      socket.removeListener("occurrences-update", onOccurrencesUpdated);
    };
  }, [occurrenceId]);

  const submitActivity = (data) => {
    setSubmitting(true);

    return api.occurrences
      .submitActivity(occurrenceId, data)
      .then((activity) => setActivities([...activities, activity]))
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false));
  };

  return {
    activities,
    submitting,
    submitActivity: debounce(submitActivity, 1000, debounceOptions),
  };
};

export default useOccurrenceActivities;
