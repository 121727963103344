import React from "react";
import PropTypes from "prop-types";

import debounce from "lodash/debounce";

import validator from "validator";

import { Form, Button, Message } from "semantic-ui-react";

const OBSERVATION_MAX_LENGTH = 140;

class CallTechnicianForm extends React.Component {
  state = {
    data: {
      sector_id: 1,
      observation: ""
    },
    loading: false,
    errors: []
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.submitDebounced = debounce(this.props.submit, 1000, {
      leading: true,
      trailing: false
    });
  }

  onChange(e, props) {
    const name = props.name;
    const value = props.value;

    if (name === "observation" && value.length > OBSERVATION_MAX_LENGTH) {
      return;
    }

    this.setState({ data: { ...this.state.data, [name]: value } });
  }

  validate() {
    const { data } = this.state;

    let errors = [];

    if (!data.sector_id) {
      errors.push({ field: "sector_id", message: "Setor é obrigatório" });
    }

    if (validator.isEmpty(data.observation + "")) {
      errors.push({
        field: "observation",
        message: "Observação é obrigatório"
      });
    }

    return errors;
  }

  onSubmit(e) {
    e.preventDefault();
    const errors = this.validate();

    this.setState({ errors });

    if (errors.length === 0) {
      this.setState({ loading: true });
      return this.submitDebounced(this.state.data).then(() =>
        this.setState({ loading: false })
      );
    } else {
      return Promise.reject();
    }
  }

  render() {
    const { data, errors, loading } = this.state;
    const { disabled, sectors, sectorsLoading } = this.props;

    const errorsFields = errors.map(e => e.field);

    return (
      <Form
        className="call-technician-form"
        onSubmit={this.onSubmit}
        error={errorsFields.length > 0}
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <h3>Abrir chamado</h3>
        <Form.Dropdown
          required
          label="Setor"
          selection
          name="sector_id"
          onChange={this.onChange}
          value={data.sector_id}
          options={sectors.map(s => ({
            key: s.id,
            value: s.id,
            text: s.name
          }))}
          disabled={disabled}
          loading={sectorsLoading}
          error={errorsFields.indexOf("sector") !== -1}
        />
        <Form.TextArea
          label="Observações"
          name="observation"
          value={data.observation}
          onChange={this.onChange}
          placeholder="Observações"
          error={errorsFields.indexOf("observation") !== -1}
          disabled={disabled}
          required
        />
        Caracteres restantes: {OBSERVATION_MAX_LENGTH - data.observation.length}
        <Message error list={errors.map(e => e.message)} />
        <Form.Field
          style={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          <Button
            id="register_occurrence_btn"
            onClick={this.onSubmitDebounced}
            primary
            disabled={disabled}
            loading={loading}
          >
            Abrir chamado
          </Button>
        </Form.Field>
      </Form>
    );
  }
}

CallTechnicianForm.propTypes = {
  submit: PropTypes.func.isRequired,
  sectors: PropTypes.array.isRequired
};

CallTechnicianForm.defaultProps = {
  sectors: []
};

export default CallTechnicianForm;
