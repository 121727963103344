import axios from "axios";
import Occurrence from "../models/Occurrence";

export default {
  fetchAll: (params) => {
    return axios.get("/api/v1/occurrences", { params }).then((response) => ({
      data: response.data.data.map(
        (data) => new Occurrence(data, response.timeOffset)
      ),
      links: response.data.links,
      meta: response.data.meta,
    }));
  },
  fetchKanban: (params) => {
    return axios.get("/api/v1/occurrences/kanban", { params });
  },
  fetchActivities: (id) => {
    return axios
      .get(`/api/v1/occurrences/${id}/activities`)
      .then((response) => response.data.data);
  },
  stats: (params) => {
    return axios
      .get("/api/v1/occurrences/stats", { params })
      .then((response) => response.data.data);
  },
  export: () => {
    return axios
      .get("/api/v1/occurrences/export", { responseType: "arraybuffer" })
      .then((response) => response.data);
  },
  create: (data) => {
    return axios
      .post(`/api/v1/occurrences/`, data)
      .then(
        (response) => new Occurrence(response.data.data, response.timeOffset)
      );
  },
  update: (id, data) => {
    return axios
      .post(`/api/v1/occurrences/${id}`, data)
      .then(
        (response) => new Occurrence(response.data.data, response.timeOffset)
      );
  },
  escalate: (id) => {
    return axios
      .post(`/api/v1/occurrences/${id}/escalate`)
      .then(
        (response) => new Occurrence(response.data.data, response.timeOffset)
      );
  },
  submitActivity: (id, data) => {
    return axios
      .post(`/api/v1/occurrences/${id}/activities`, data)
      .then((response) => response.data.data);
  },
  delete: (id) => {
    return axios.delete(`/api/v1/occurrences/${id}`);
  },
};
