export const FETCH_ALL_REQUEST = "NotificationRules/FETCH_ALL_REQUEST";
export const FETCH_ALL_SUCCESS = "NotificationRules/FETCH_ALL_SUCCESS";
export const CREATE_SUCCESS = "NotificationRules/CREATE_SUCCESS";
export const UPDATE_SUCCESS = "NotificationRules/UPDATE_SUCCESS";
export const DELETE_SUCCESS = "NotificationRules/DELETE_SUCCESS";
export const ADD_CLICK = "NotificationRules/ADD_CLICK";
export const EDIT_CLICK = "NotificationRules/EDIT_CLICK";
export const DELETE_CLICK = "NotificationRules/DELETE_CLICK";
export const DELETE_CANCEL = "NotificationRules/DELETE_CANCEL";
export const EDIT_CANCEL = "NotificationRules/EDIT_CANCEL";

export const REDUCER_NAME = "notification_rules";
