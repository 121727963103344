export const REDUCER_NAME = "tutorial";

export const JOYRIDE_CB = "Tutorial/JOYRIDE_CALLBACK";
export const START_TUTORIAL = "Tutorial/START_TUTORIAL";

export const STEPS = [
  {
    title: "Bem vindo ao GO4Andon",
    content:
      "Este guia irá te ajudar a navegar pela plataforma e conhecer todas suas funcionalidades.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    title: "Configurações",
    content:
      "Para poder utilizar o sistema é preciso configurar ele antes. Clique em próximo para iniciar a configuração.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    title: "Máquinas",
    content: "Aqui você irá gerenciar as máquinas da sua empresa.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    content: "Clique aqui para cadastrar uma nova máquina",
    target: ".add-machine-btn",
    placement: "auto",
    spotlightClicks: true,
    styles: {
      tooltipFooter: {
        opacity: 0,
      },
      buttonClose: {
        display: "none",
      },
    },
  },
  {
    title: "Máquinas",
    content:
      "Pronto! Todos os chamados precisam de um setor responsável. Vamos cadastrar um agora.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    title: "Setores",
    content: "Aqui você irá gerenciar todos os setores.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    content: "Clique aqui para cadastrar um novo setor.",
    target: ".add-sector-btn",
    placement: "auto",
    spotlightClicks: true,
    styles: {
      tooltipFooter: {
        opacity: 0,
      },
      buttonClose: {
        display: "none",
      },
    },
  },
  {
    title: "Setores",
    content:
      "Pronto! estamos quase acabando, agora é preciso cadastrar os níveis de escalonamento para o setor.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    title: "Níveis de escalonamento",
    content:
      "Níveis de Escalonamento ajudam a notificar superiores quanto a demora nos atendimentos. Cadastre os níveis de acordo com a hierarquia dos setores da sua empresa. Você também pode associar um som de alerta ao nível que será tocado nas telas de Kanban e Chamar Técnico.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    content: "Clique aqui para cadastrar um nível de escalonamento.",
    target: ".add-priority-btn",
    placement: "auto",
    spotlightClicks: true,
    styles: {
      tooltipFooter: {
        opacity: 0,
      },
      buttonClose: {
        display: "none",
      },
    },
  },
  {
    title: "Níveis de escalonamento",
    content:
      "Com as configurações básicas do andon, podemos agora abrir chamados para as máquinas cadastradas.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    title: "Chamar técnico",
    content:
      "Está será a tela principal para a abertura e atualizações de chamados.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    content: "Selectione a máquina para a qual deseja abrir um chamado",
    target: ".call-technician-machine-dropdown",
    placement: "auto",
    spotlightClicks: true,
    disableOverlay: true,
    styles: {
      tooltipFooter: {
        opacity: 0,
      },
      buttonClose: {
        display: "none",
      },
    },
  },
  {
    content:
      "Selecione o setor, informe uma observação inicial e você está pronto para abrir o chamado",
    target: ".call-technician-form",
    placement: "auto",
    spotlightClicks: true,
    styles: {
      tooltipFooter: {
        opacity: 0,
      },
      buttonClose: {
        display: "none",
      },
    },
  },
  {
    content: "Pressione este botão para registrar a chegada do técnico",
    target: ".call-technician-btn",
    placement: "auto",
    spotlightClicks: true,
    styles: {
      tooltipFooter: {
        opacity: 0,
      },
      buttonClose: {
        display: "none",
      },
    },
  },
  {
    content:
      "Agora registre o fim do atendimento e informe as observações finais sobre o que foi feito caso necessário",
    target: ".call-technician-btn",
    placement: "auto",
    spotlightClicks: true,
    disableOverlay: false,
    styles: {
      tooltipFooter: {
        opacity: 0,
      },
      buttonClose: {
        display: "none",
      },
    },
  },
  {
    title: "Chamar técnico",
    content:
      "Pronto! 🎉 Voce abriu o primeiro chamado na plataforma GO4Andon. Enquanto um chamado estiver ativo, você pode também cancelá-lo, escalonar seu nível manualmente e deixar registros de atividades durante o antedimento",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
  {
    content:
      "Esperamos que a nossa ferramenta ajude você e sua empresa a melhorar sua operação. Qualquer dúvida pode nos enviar uma mensagem no menu de ajuda, no canto superior direito.",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "body",
  },
];
