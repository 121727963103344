import isElectron from "./isElectron";

let socket = null;
if (isElectron()) {
  socket = require("./socket-electron").default;
} else {
  socket = require("./socket-normal").default;
}

export default {
  connect: function (token) {
    if (!token) {
      console.log("no token set");
      return;
    }
    console.log("connecting socket abstract");

    const { namespace } = JSON.parse(atob(token.split(".")[1]));
    socket.connect(token, namespace);
  },

  on: function (channel, cb) {
    console.log("subscribed to", channel);
    socket.on(channel, cb);
  },

  removeListener: function (channel, cb) {
    console.log("unsubscribed from", channel);
    return socket.removeListener(channel, cb);
  },

  removeAllListeners: function (channel) {
    socket.removeAllListeners(channel);
  },

  isConnected: function () {
    return socket.isConnected();
  },

  disconnect: function () {
    return socket.disconnect();
  },
};
