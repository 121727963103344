export const FETCH_ALL_REQUEST = "FilterRules/FETCH_ALL_REQUEST";
export const FETCH_ALL_SUCCESS = "FilterRules/FETCH_ALL_SUCCESS";
export const CREATE_SUCCESS = "FilterRules/CREATE_SUCCESS";
export const UPDATE_SUCCESS = "FilterRules/UPDATE_SUCCESS";
export const DELETE_SUCCESS = "FilterRules/DELETE_SUCCESS";
export const ADD_CLICK = "FilterRules/ADD_CLICK";
export const EDIT_CLICK = "FilterRules/EDIT_CLICK";
export const DELETE_CLICK = "FilterRules/DELETE_CLICK";
export const DELETE_CANCEL = "FilterRules/DELETE_CANCEL";
export const EDIT_CANCEL = "FilterRules/EDIT_CANCEL";

export const REDUCER_NAME = "filter_rules";
