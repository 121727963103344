import { createAction } from "redux-actions";
import isEmpty from "lodash/isEmpty";
import api from "../../../api";

import {
  FETCH_ALL_REQUEST,
  FETCH_ALL_SUCCESS,
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  DELETE_SUCCESS,
  ADD_CLICK,
  EDIT_CLICK,
  DELETE_CLICK,
  DELETE_CANCEL,
  EDIT_CANCEL,
  REDUCER_NAME
} from "../constants";

const allFetchedRequest = createAction(FETCH_ALL_REQUEST);
const allFetchedSuccess = createAction(FETCH_ALL_SUCCESS);

const createSuccess = createAction(CREATE_SUCCESS);
const updateSuccess = createAction(UPDATE_SUCCESS);
const deleteSuccess = createAction(DELETE_SUCCESS);

export const addClick = createAction(ADD_CLICK);
export const editClick = createAction(EDIT_CLICK);
export const deleteClick = createAction(DELETE_CLICK);

export const cancelDelete = createAction(DELETE_CANCEL);
export const cancelEdit = createAction(EDIT_CANCEL);

export const fetchAll = (force = false) => (dispatch, getState) => {
  const rules = getRules(getState());
  if (!isEmpty(rules) && !force) {
    return dispatch(allFetchedSuccess(rules));
  }
  dispatch(allFetchedRequest());

  return api.filterRules
    .fetchAll()
    .then(rules => dispatch(allFetchedSuccess(rules)));
};

export const submitForm = data => (dispatch, getState) => {
  const editRule = getEditRule(getState());

  dispatch(allFetchedRequest());

  let promise = null;
  if (editRule) {
    promise = api.filterRules
      .update(editRule.id, data)
      .then(() => dispatch(updateSuccess()));
  } else {
    promise = api.filterRules
      .create(data)
      .then(() => dispatch(createSuccess()));
  }

  return promise.then(() => dispatch(fetchAll(true)));
};

export const confirmDelete = () => (dispatch, getState) => {
  const editRule = getEditRule(getState());

  dispatch(deleteSuccess());
  dispatch(allFetchedRequest());

  return api.filterRules
    .delete(editRule.id)
    .then(() => dispatch(fetchAll(true)));
};

const initialState = {
  rules: [],
  editRule: null,
  loading: false,
  formOpen: false,
  confirmOpen: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return { ...state, loading: true };
    case FETCH_ALL_SUCCESS:
      return { ...state, loading: false, rules: action.payload };
    case ADD_CLICK:
      return { ...state, formOpen: true, editRule: null };
    case EDIT_CLICK:
      return { ...state, formOpen: true, editRule: action.payload };
    case DELETE_CLICK:
      return { ...state, confirmOpen: true, editRule: action.payload };
    case CREATE_SUCCESS:
      return { ...state, formOpen: false };
    case UPDATE_SUCCESS:
      return { ...state, formOpen: false, editRule: null };
    case DELETE_SUCCESS:
      return { ...state, confirmOpen: false, editRule: null };
    case DELETE_CANCEL:
      return { ...state, confirmOpen: false, editRule: null };
    case EDIT_CANCEL:
      return { ...state, formOpen: false, editRule: null };
    default:
      return state;
  }
};

export const getConfirmOpen = state => state[REDUCER_NAME].confirmOpen;
export const getEditRule = state => state[REDUCER_NAME].editRule;
export const getFormOpen = state => state[REDUCER_NAME].formOpen;
export const getLoading = state => state[REDUCER_NAME].loading;
export const getRules = state => state[REDUCER_NAME].rules;
