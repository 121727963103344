const ipcRenderer = window.ipcRenderer;

class SocketElectron {
  constructor() {
    this._listeners = {};
  }

  connect(token, namespace) {
    ipcRenderer.send("socket-connect", token, namespace);
  }

  on(channel, listener) {
    if (!this._listeners[channel]) {
      this._listeners[channel] = [];
    }

    const modifiedListener = {
      // we must remove the first argument from ipcListener
      ipcListener: (_, data) => {
        console.log("received event from electron", channel, data);
        listener(data);
      },
      original: listener,
    };

    this._listeners[channel].push(modifiedListener);

    ipcRenderer.on(`socket.${channel}`, modifiedListener.ipcListener);
  }

  disconnect() {
    ipcRenderer.send("socket-disconnect");
  }

  removeListener(channel, listener) {
    if (!this._listeners[channel]) {
      return;
    }

    const listenerToRemove = this._listeners[channel].find(
      ({ original }) => original === listener
    );

    if (!listenerToRemove) {
      return;
    }

    this._listeners[channel] = this._listeners[channel].filter(
      (listener) => listener === listenerToRemove
    );

    ipcRenderer.removeListener(
      `socket.${channel}`,
      listenerToRemove.ipcListener
    );
  }

  removeAllListeners(channel) {
    if (!channel) {
      Object.keys(this._listeners).forEach((channel) => {
        this._removeAllListenersFromChannel(channel);
      });
    } else {
      this._removeAllListenersFromChannel(channel);
    }
  }

  _removeAllListenersFromChannel(channel) {
    this._listeners[channel] = [];
    ipcRenderer.removeAllListeners(`socket.${channel}`);
  }
}

export default new SocketElectron();
