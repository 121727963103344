import React, { Component } from "react";
import { Container, Grid } from "semantic-ui-react";

import { withChat } from "../../../utils/chat";
import ContactForm from "./ContactForm";

class HelpPage extends Component {
  render() {
    return (
      <Container fluid>
        <Grid>
          <Grid.Row centered>
            <h3>Entre em contato conosco</h3>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width="8">
              <ContactForm />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default withChat(HelpPage);
