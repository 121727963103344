export const FETCH_ALL_REQUEST = "Users/FETCH_ALL_REQUEST";
export const FETCH_ALL_SUCCESS = "Users/FETCH_ALL_SUCCESS";

export const UPDATE_SUCCESS = "Users/UPDATE_SUCCESS";
export const CREATE_SUCCESS = "Users/CREATE_SUCCESS";
export const DELETE_SUCCESS = "Users/DELETE_SUCCESS";
export const ADD_CLICK = "Users/ADD_CLICK";
export const EDIT_CLICK = "Users/EDIT_CLICK";
export const EDIT_PWD_CLICK = "Users/EDIT_PWD_CLICK";
export const DELETE_CLICK = "Users/DELETE_CLICK";
export const DELETE_CANCELED = "Users/DELETE_CANCELED";
export const EDIT_CANCELED = "Users/EDIT_CANCELED";
export const EDIT_PWD_CANCELED = "Users/EDIT_PWD_CANCELED";

export const REDUCER_NAME = "users";
