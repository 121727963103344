import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import RavenMiddleware from "redux-raven-middleware";

import { HashRouter as Router, Route } from "react-router-dom";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import moment from "moment";
import "moment/locale/pt-br";

import rootReducer from "./rootReducer";

import { authenticationChain } from "./modules/Auth";
import { setKanbanFilter } from "./modules/Kanban";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "semantic-ui-css/semantic.min.css";
import "./index.css";

import { App } from "./modules/App";
import { unregister } from "./registerServiceWorker";

import * as storage from "./utils/storage";
import { firebaseInit } from "./utils/firebase";
import { ravenInit } from "./utils/raven";

import {
  analyticsInit,
  analyticsMiddleware,
  withAnalytics,
} from "./utils/analytics";

import { chatInit } from "./utils/chat";

if (process.env.NODE_ENV !== "development") {
  chatInit();
  analyticsInit();
  ravenInit();
  LogRocket.init("go4-andon/go4-andon");
  setupLogRocketReact(LogRocket);
}

firebaseInit();

moment.locale("pt-BR");

const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      RavenMiddleware(),
      analyticsMiddleware,
      LogRocket.reduxMiddleware()
    )
  )
);

if (storage.get("remember")) {
  storage.useLocalStorage(true);
}

if (storage.get("access_token")) {
  store.dispatch(authenticationChain(storage.get("access_token")));
}

if (storage.get("kanban_filter")) {
  store.dispatch(setKanbanFilter(storage.get("kanban_filter")));
}

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <Route component={withAnalytics(App)} />
    </Provider>
  </Router>,
  document.getElementById("root")
);
unregister();
