import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Confirm, Button, Header } from "semantic-ui-react";

import get from "lodash/get";

import UserTable from "./UserTable";
import { FormModal } from "../../Shared";

import UserEditForm from "./UserEditForm";
import PasswordResetForm from "./PasswordResetForm";

import { getUser } from "../../Auth";
import userHasPage from "../../../utils/userHasPage";

import {
  getNotificationRules,
  fetchAllNotificationRules
} from "../../NotificationRules";

import { getFilterRules, fetchAllFilterRules } from "../../FilterRules";

import { getPermissions, fetchAllPermissions } from "../../Permissions";

import {
  getEditUser,
  getConfirmOpen,
  getPwdFormOpen,
  getFormOpen,
  confirmDelete,
  submitEdit,
  submitPwdForm,
  addClick,
  cancelEdit,
  cancelDelete,
  cancelPwdEdit
} from "../ducks/user";

class UsersPage extends Component {
  componentDidMount() {
    this.props.fetchAllNotificationRules();
    this.props.fetchAllFilterRules();
    this.props.fetchAllPermissions();
  }

  render() {
    const {
      canEdit,
      notificationRules,
      filterRules,
      permissions,
      pwdFormOpen,
      formOpen,
      confirmOpen,
      editUser
    } = this.props;

    return (
      <Container fluid>
        <Header as="h1">Usuários</Header>

        <Button
          id="add_btn"
          primary
          icon="add"
          content="Novo usuário"
          disabled={!canEdit}
          onClick={() => this.props.addClick()}
        />

        <UserTable canEdit={canEdit} />

        <FormModal
          id="edit_modal"
          size="small"
          open={formOpen}
          onClose={() => this.props.cancelEdit()}
          header={editUser ? "Editar Usuário" : "Novo Usuário"}
          formRef={() => this.editForm}
        >
          <UserEditForm
            user={editUser}
            notificationRules={notificationRules}
            filterRules={filterRules}
            permissions={permissions}
            ref={form => (this.editForm = form)}
            submit={data => this.props.submitEdit(data)}
          />
        </FormModal>

        <FormModal
          id="edit_pwd_modal"
          size="small"
          open={pwdFormOpen}
          onClose={() => this.props.cancelPwdEdit()}
          header={"Resetar a senha"}
          formRef={() => this.passwordForm}
        >
          <PasswordResetForm
            user={editUser}
            ref={form => (this.passwordForm = form)}
            submit={data => this.props.submitPwdForm(data)}
          />
        </FormModal>

        <Confirm
          open={confirmOpen}
          content={`Tem certeza que deseja excluir o usuário: ${get(
            editUser,
            "name"
          )}?`}
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.props.cancelDelete()}
          onConfirm={() => this.props.confirmDelete()}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const user = getUser(state);

  return {
    permissions: getPermissions(state),
    editUser: getEditUser(state),
    confirmOpen: getConfirmOpen(state),
    pwdFormOpen: getPwdFormOpen(state),
    formOpen: getFormOpen(state),
    notificationRules: getNotificationRules(state),
    filterRules: getFilterRules(state),
    canEdit: userHasPage(user, "priorities", true)
  };
}

export default connect(mapStateToProps, {
  fetchAllPermissions,
  fetchAllNotificationRules,
  fetchAllFilterRules,
  addClick,
  confirmDelete,
  submitEdit,
  submitPwdForm,
  cancelEdit,
  cancelDelete,
  cancelPwdEdit
})(UsersPage);
