export const MACHINE_GROUPS_FETCHING = "Overview/MACHINE_GROUPS_FETCHING";
export const MACHINE_GROUPS_FETCHED = "Overview/MACHINE_GROUPS_FETCHED";
export const MACHINE_GROUPS_CREATING = "Overview/MACHINE_GROUPS_CREATING";
export const MACHINE_GROUPS_CREATED = "Overview/MACHINE_GROUPS_CREATED";
export const OVERVIEW_FETCHING = "Overview/OVERVIEW_FETCHING";
export const OVERVIEW_FETCHED = "Overview/OVERVIEW_FETCHED";
export const MACHINE_GROUPS_UP = "Overview/MACHINE_GROUPS_UP";
export const MACHINE_GROUPS_DOWN = "Overview/MACHINE_GROUPS_DOWN";

export const EDIT_FORM_OPEN = "Overview/EDIT_FORM_OPEN";
export const EDIT_FORM_CLOSE = "Overview/EDIT_FORM_CLOSE";

export const DELETE_CONFIRM_CLOSE = "Overview/DELETE_CONFIRM_CLOSE";
export const DELETE_CONFIRM_OPEN = "Overview/DELETE_CONFIRM_OPEN";

export const FILTER_CHANGED = "Overview/FILTER_CHANGED";

export const REDUCER_NAME = "overview";
