import Raven from "raven-js";
import electronAppVersion from './electronAppVersion'
import appVersion from './appVersion'

export function ravenInit() {
    Raven.config("https://1b3132c1eab44ac69ca0c417a32c1670@sentry.io/287771", {
        release: appVersion,
        tags: { electronAppVersion }
    }).install();
}
