import React, { Component } from "react";
import { connect } from "react-redux";

import { Header, Button, Message, Container, Confirm } from "semantic-ui-react";

import get from "lodash/get";

import { getUser } from "../../Auth";
import userHasPage from "../../../utils/userHasPage";

import { FormModal } from "../../Shared";

import PriorityTable from "./PriorityTable";
import PriorityEditForm from "./PriorityEditForm";
import PrioritiesFilter from "./PrioritiesFilter";

import {
  getLoading,
  getPrioritiesFiltered,
  fetchAll,
  moveUp,
  moveDown,
  create,
  update,
  remove
} from "../ducks/priorities";

import {
  getSectors,
  fetchAll as fetchAllSectors
} from "../../Sectors/ducks/sector";

class PrioritiesPage extends Component {
  state = {
    editOpen: false,
    deleteConfirmationOpen: false
  };

  constructor(props) {
    super(props);

    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onEditSubmit = this.onEditSubmit.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.update = this.update.bind(this);
  }

  onEditClick(priority) {
    this.setState({ editOpen: true, selectedPriority: priority });
  }

  onDeleteClick(priority) {
    this.setState({
      deleteConfirmationOpen: true,
      selectedPriority: priority
    });
  }

  handleDeleteConfirm() {
    this.setState({ deleteConfirmationOpen: false });

    const { selectedPriority } = this.state;
    return this.props.remove(selectedPriority.id);
  }

  onEditClose() {
    this.setState({ editOpen: false });
  }

  onEditSubmit(data) {
    const { selectedPriority } = this.state;

    let promise = null;

    if (selectedPriority) {
      promise = this.props.update(selectedPriority.id, data);
    } else {
      promise = this.props.create(data);
    }

    return promise.then(() => this.setState({ editOpen: false }));
  }

  update() {
    this.props.fetchAllSectors();
    this.props.fetchAll();
  }

  onAddClick(e) {
    this.setState({ editOpen: true, selectedPriority: undefined });
  }

  componentDidMount() {
    this.update();
  }

  render() {
    const { editOpen, selectedPriority } = this.state;

    const { canEdit, sectors, priorities, loading } = this.props;
    return (
      <Container fluid>
        <Header as="h1">Níveis de escalonamento</Header>
        <Message
          info
          content="A sequência de escalonamento é indicada pela ordem da tabela, do nível mais alto para o mais baixo."
        />

        <div
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "12px"
          }}
        >
          <div style={{ marginTop: "auto" }}>
            <Button
              className="add-priority-btn"
              primary
              icon="add"
              content="Novo nível"
              onClick={this.onAddClick}
              id="add_btn"
              disabled={!canEdit}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <PrioritiesFilter />
          </div>
        </div>

        <PriorityTable
          canEdit={canEdit}
          ref={table => (this.table = table)}
          priorities={priorities}
          loading={loading}
          onArrowUp={priority => this.props.moveUp(priority.id)}
          onArrowDown={priority => this.props.moveDown(priority.id)}
          onEditClick={this.onEditClick}
          onAddClick={this.onAddClick}
          onDeleteClick={this.onDeleteClick}
        />

        <FormModal
          size="tiny"
          header="Editar nível de escalonamento"
          open={editOpen}
          onClose={this.onEditClose}
          formRef={() => this.form}
          id="edit_modal"
        >
          <PriorityEditForm
            ref={form => (this.form = form)}
            submit={this.onEditSubmit}
            priority={selectedPriority}
            sectors={sectors}
          />
        </FormModal>

        <Confirm
          open={this.state.deleteConfirmationOpen}
          content={`Tem certeza que deseja excluir o nível: ${get(
            selectedPriority,
            "name"
          )}?`}
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.setState({ deleteConfirmationOpen: false })}
          onConfirm={this.handleDeleteConfirm}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const user = getUser(state);

  return {
    loading: getLoading(state),
    priorities: getPrioritiesFiltered(state),
    sectors: getSectors(state),
    canEdit: userHasPage(user, "priorities", true)
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAllSectors,
    fetchAll,
    moveUp,
    moveDown,
    create,
    update,
    remove
  }
)(PrioritiesPage);
