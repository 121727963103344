import React from "react";
import { connect } from "react-redux";

import { Button, Confirm, Container, Header } from "semantic-ui-react";

import { getUser } from "../../Auth";
import userHasPage from "../../../utils/userHasPage";
import {
  getSelected,
  getEditFormOpen,
  getDownloadLoading,
  downloadExport,
  submitEdit,
  closeEdit,
  getDeleteConfirmOpen,
  confirmDelete,
  cancelDelete,
} from "../ducks/occurrences";

import OccurrenceStats from "./OccurrenceStats";
import OccurrenceTable from "./OccurrenceTable";
import OccurrenceTableFilter from "./OccurrenceTableFilter";
import Expandable from "./Expandable";

import OccurrenceEditForm from "./OccurrenceEditForm";
import { FormModal } from "../../Shared";

class OccurrencesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterVisible: true,
    };

    this.toggleFilter = this.toggleFilter.bind(this);
  }

  toggleFilter() {
    this.setState((state) => ({ isFilterVisible: !state.isFilterVisible }));
  }

  render() {
    const { isFilterVisible } = this.state;
    const {
      canEdit,
      formOpen,
      selected,
      downloadLoading,
      deleteConfirmOpen,
    } = this.props;

    return (
      <Container fluid>
        <Header as="h1">Estatísticas</Header>

        <OccurrenceStats />

        <Header as="h1">Chamados</Header>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: "12px",
          }}
        >
          <div style={{ marginTop: "auto" }}>
            <Button
              icon="download"
              content="Exportar para .xls"
              primary
              loading={downloadLoading}
              onClick={() =>
                this.props.downloadExport(
                  "relatorio_chamados.xlsx",
                  "application/vnd.ms-excel"
                )
              }
            />
          </div>
          <button
            onClick={this.toggleFilter}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              background: "transparent",
              border: "none",
              textDecoration: "underline",
              color: "#4183c4",
            }}
          >
            {isFilterVisible ? "Esconder Filtros" : "Ver Filtros"}
          </button>
        </div>

        <Expandable isVisible={isFilterVisible}>
          <OccurrenceTableFilter onChange={this.onFiltersChange} />
        </Expandable>

        <OccurrenceTable canEdit={canEdit} />

        <FormModal
          id="edit_modal"
          size="tiny"
          header="Editar Chamado"
          open={formOpen}
          onClose={this.props.closeEdit}
          formRef={() => this.form}
        >
          <OccurrenceEditForm
            ref={(form) => (this.form = form)}
            submit={this.props.submitEdit}
            occurrence={selected}
          />
        </FormModal>

        <Confirm
          open={deleteConfirmOpen}
          content="Tem certeza que deseja excluir?"
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.props.cancelDelete()}
          onConfirm={() => this.props.confirmDelete()}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const user = getUser(state);

  return {
    canEdit: userHasPage(user, "occurrences", true),
    selected: getSelected(state),
    formOpen: getEditFormOpen(state),
    downloadLoading: getDownloadLoading(state),
    deleteConfirmOpen: getDeleteConfirmOpen(state),
  };
}

export default connect(mapStateToProps, {
  closeEdit,
  submitEdit,
  downloadExport,
  confirmDelete,
  cancelDelete,
})(OccurrencesPage);
