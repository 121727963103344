import React from "react";
import styled from "styled-components";

const FlexContainer = styled.div`
  flex: 1;
  position: relative;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

const ScrollableContainer = ({ children }) => (
  <FlexContainer>
    <AbsoluteContainer>{children}</AbsoluteContainer>
  </FlexContainer>
);

export default ScrollableContainer;
