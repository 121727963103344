import React from "react";
import PropTypes from "prop-types";

import defaultTo from "lodash/defaultTo";

const TIMER_INTERVAL = 500;

class TimerCounter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elapsed: 0,
    };

    this.onTick = this.onTick.bind(this);
  }

  componentWillMount() {
    this.setState({ elapsed: this.getElapsed() });

    if (!this.props.paused) {
      this.timer = setInterval(this.onTick, TIMER_INTERVAL);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.paused && this.props.paused) {
      this.setState({ elapsed: this.getElapsed() });
      clearInterval(this.timer);
    } else if (prevProps.paused && !this.props.paused) {
      this.timer = setInterval(this.onTick, TIMER_INTERVAL);
    }
  }

  onTick() {
    if (!this.props.paused) {
      this.setState({ elapsed: this.getElapsed() });
    }
  }

  getElapsed() {
    const { start } = this.props;
    if (!start) {
      return 0;
    }

    const diff = Date.now() - start.getTime();
    return Math.round(diff / 1000);
  }

  render() {
    const { elapsed } = this.state;
    const { start, paused, color, style, value, ...rest } = this.props;

    const time_s = Math.max(defaultTo(value, elapsed), 0);

    const seconds = Math.floor(time_s % 60)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time_s / 60) % 60)
      .toString()
      .padStart(2, "0");
    const hours = Math.floor(time_s / 60 / 60)
      .toString()
      .padStart(2, "0");

    return (
      <div style={{ color, ...style }} {...rest}>
        {hours}:{minutes}:{seconds}
      </div>
    );
  }
}

TimerCounter.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  paused: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.number,
};

TimerCounter.defaultProps = {
  start: new Date(),
};

export default TimerCounter;
