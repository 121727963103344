import axios from "axios";
import Occurrence from "../models/Occurrence";

function mapMachine(response, machine) {
    return {
        ...machine,
        last_occurrence: machine.last_occurrence
            ? new Occurrence(machine.last_occurrence, response.timeOffset)
            : undefined
    };
}

export default {
    fetchAll: () => {
        return axios
            .get("/api/v1/machines/")
            .then(response => response.data.data);
    },
    getById: id => {
        return axios
            .get(`/api/v1/machines/${id}`)
            .then(response => mapMachine(response, response.data.data));
    },
    create: data => {
        return axios
            .post(`/api/v1/machines`, data)
            .then(response => response.data.data);
    },
    update: (id, data) => {
        return axios
            .post(`/api/v1/machines/${id}`, data)
            .then(response => response.data.data);
    },
    notifyAchievement: id => {
        return axios
            .post(`/api/v1/machines/${id}/achievement`)
            .then(response => response.data.data);
    },
    delete: id => {
        return axios.delete(`/api/v1/machines/${id}`);
    }
};
