import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import get from "lodash/get";

import { Container, Button, Dimmer, Loader, Table } from "semantic-ui-react";

import { SortableTableHeader } from "../../Shared";

import {
  getLoading,
  getUsers,
  fetchAll,
  editClick,
  deleteClick,
  editPwdClick
} from "../ducks/user";

const HEADERS = [
  { name: null, label: "Nome" },
  { name: null, label: "Regra de filtro" },
  { name: null, label: "Regra de notificações" },
  { name: null, label: "Regra de acesso" },
  { name: null, label: "Ações" }
];

class UserTable extends Component {
  componentDidMount() {
    this.props.fetchAll();
  }

  renderTableBody() {
    const { users, canEdit } = this.props;
    return (
      <Table.Body>
        {users.map((user, key) => (
          <Table.Row key={user.id}>
            <Table.Cell width={3}>{user.name}</Table.Cell>
            <Table.Cell>{get(user, "filter_rule.name")}</Table.Cell>
            <Table.Cell>{get(user, "notification_rule.name")}</Table.Cell>
            <Table.Cell>{get(user, "permission.name", "")}</Table.Cell>
            <Table.Cell width={3}>
              <Button
                basic
                icon="key"
                disabled={!canEdit}
                onClick={() => this.props.editPwdClick(user)}
                style={{ marginRight: "20px" }}
              />
              <Button
                basic
                icon="edit"
                disabled={!canEdit}
                onClick={() => this.props.editClick(user)}
                style={{ marginRight: "20px" }}
              />
              <Button
                basic
                icon="delete"
                disabled={!canEdit}
                onClick={() => this.props.deleteClick(user)}
                style={{ marginRight: "20px" }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  render() {
    const { loading } = this.props;

    return (
      <Container fluid>
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>

        <Table selectable>
          <SortableTableHeader headers={HEADERS} />
          {this.renderTableBody()}
        </Table>
      </Container>
    );
  }
}

UserTable.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onEditPasswordClick: PropTypes.func,
  onDeleteClick: PropTypes.func
};

UserTable.defaultProps = {
  users: [],
  loading: false,
  onAddClick: () => {},
  onEditClick: () => {},
  onEditPasswordClick: () => {},
  onDeleteClick: () => {}
};

function mapStateToProps(state) {
  return { loading: getLoading(state), users: getUsers(state) };
}

export default connect(mapStateToProps, {
  fetchAll,
  editClick,
  deleteClick,
  editPwdClick
})(UserTable);
