import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import moment from "moment";
import classNames from "classnames";

import { Button, Card, Grid, Segment } from "semantic-ui-react";

import OccurrenceTimerCounter from "./OccurrenceTimerCounter";
import OccurrenceTimerProgress from "./OccurrenceTimerProgress";

const KanbanCardExtra = ({ occurrence, onActivitiesClick }) => {
  const activitiesLength = occurrence.activities?.length ?? 0;

  return (
    <Grid verticalAlign="middle">
      <Grid.Column width={12}>
        {moment(occurrence.started_on).format("DD/MM - HH:mm:ss")}
      </Grid.Column>
      <Grid.Column width={4} textAlign="right">
        {onActivitiesClick && (
          <Button
            icon="comments"
            content={activitiesLength}
            title="Registro de atividades"
            basic
            disabled={activitiesLength <= 0}
            onClick={onActivitiesClick}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

const KanbanCard = ({ occurrence, onActivitiesClick, style }) => {
  const color = get(occurrence, "sector.color", "red");
  return (
    <Card
      fluid
      className={classNames({
        "kanban-alarm-state": !occurrence.isFinished(),
      })}
      style={{ animationName: `alarm-${color}`, ...style }}
    >
      <Card.Content>
        <Card.Header>{occurrence.getMachineName()}</Card.Header>
        <Card.Meta>Setor: {occurrence.getSectorName()}</Card.Meta>
        <Card.Meta>
          Prioridade de atendimento: {occurrence.getMachineLevel()}
        </Card.Meta>
        <Card.Meta>
          Nível de escalonamento: {occurrence.getPriorityName()}
        </Card.Meta>
        <Card.Description>
          {!isEmpty(occurrence.observation) && (
            <Segment
              secondary
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                background: "#f3f4f5b5",
              }}
            >
              {occurrence.observation}
            </Segment>
          )}

          <OccurrenceTimerCounter
            occurrence={occurrence}
            style={{
              fontSize: "2.5em",
              margin: "15px 0 15px",
              fontWeight: "bold",
            }}
          />
          {occurrence.isCanceled() ? (
            "Cancelado"
          ) : (
            <OccurrenceTimerProgress
              occurrence={occurrence}
              size="small"
              style={{ margin: "0px" }}
            />
          )}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <KanbanCardExtra
          occurrence={occurrence}
          onActivitiesClick={onActivitiesClick}
        />
      </Card.Content>
    </Card>
  );
};

export default KanbanCard;
