import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import get from "lodash/get";

import validator from "validator";

import { Form, Message, Popup, Icon } from "semantic-ui-react";

import {
  getMachineGroupsLoading,
  getMachineGroups,
  fetchAllMachineGroups,
  createMachineGroup,
} from "../../Overview";
import { MACHINE_LEVEL_OPTIONS } from "../../../constants";

class MachineEditForm extends React.Component {
  state = {
    data: {
      name: "",
      level: 0,
    },
    errors: [],
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e, props) {
    const name = props.name;
    const value = props.value;

    this.setState({ data: { ...this.state.data, [name]: value } });
  }

  validate() {
    const { data } = this.state;

    let errors = [];

    if (validator.isEmpty(data.name)) {
      errors.push({ field: "name", message: "Nome é obrigatório" });
    }

    if (validator.isEmpty(data.level + "")) {
      errors.push({
        field: "level",
        message: "Prioridade é obrigatório",
      });
    }

    if (validator.isEmpty(data.machine_group_id + "")) {
      errors.push({
        field: "machine_group_id",
        message: "Grupo é obrigatório",
      });
    }

    if (this.props.groupsLoading) {
      errors.push({
        field: "machine_group_id",
        message:
          "Aguarde enquanto a criação do grupo de máquina terminar e tente novamente",
      });
    }

    return errors;
  }

  onSubmit() {
    const errors = this.validate();

    this.setState({ errors });

    if (errors.length === 0) {
      return this.props.submit(this.state.data);
    } else {
      return Promise.reject();
    }
  }

  componentDidMount() {
    const { machine } = this.props;
    if (machine) {
      this.setState({
        data: {
          ...machine,
          machine_group_id: get(machine, "group.id"),
        },
      });
    }

    this.props.fetchAllMachineGroups();
  }

  render() {
    const { groups, groupsLoading } = this.props;
    const { data, errors } = this.state;

    const errorsFields = errors.map((e) => e.field);

    return (
      <Form onSubmit={this.onSubmit} error={errorsFields.length > 0}>
        <Form.Input
          label="Nome"
          type="text"
          name="name"
          value={data.name}
          onChange={this.onChange}
          placeholder="Nome"
          required
          autoFocus
          error={errorsFields.indexOf("name") !== -1}
        />

        <Form.Field error={errorsFields.indexOf("level") !== -1}>
          <label>
            Prioridade de atendimento
            <Popup
              trigger={<Icon name="help circle" size="large" color="blue" />}
              content="Máquinas com maior prioridade aparecem por primeiro na visualização de Kanban"
              basic
            />
          </label>
          <Form.Dropdown
            name="level"
            value={data.level}
            onChange={this.onChange}
            placeholder="Prioridade de atendimento"
            selection
            options={MACHINE_LEVEL_OPTIONS}
          />
        </Form.Field>

        <Form.Field error={errorsFields.indexOf("machine_group_id") !== -1}>
          <label>
            Grupo
            <Popup
              trigger={<Icon name="help circle" size="large" color="blue" />}
              content="Grupos ajudam você a organizar as máquinas na aba de visão geral"
              basic
            />
          </label>
          <Form.Dropdown
            required
            name="machine_group_id"
            loading={groupsLoading}
            value={data.machine_group_id}
            onChange={this.onChange}
            placeholder="Ex: Pintura, Embaladoras"
            selection
            search
            noResultsMessage="Digite o nome do grupo para criá-lo"
            allowAdditions
            additionLabel="Criar grupo: "
            onAddItem={(e, data) =>
              this.props
                .createMachineGroup({ name: data.value })
                .then((group) =>
                  this.onChange(null, {
                    name: "machine_group_id",
                    value: group.id,
                  })
                )
            }
            options={groups.map((group) => ({
              text: group.name,
              value: group.id,
            }))}
          />
        </Form.Field>

        <Message error list={errors.map((e) => e.message)} />
      </Form>
    );
  }
}

MachineEditForm.propTypes = {
  submit: PropTypes.func.isRequired,
  machine: PropTypes.shape({
    name: PropTypes.string,
    level: PropTypes.number,
  }),
};

function mapStateToProps(state) {
  return {
    groups: getMachineGroups(state),
    groupsLoading: getMachineGroupsLoading(state),
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAllMachineGroups,
    createMachineGroup,
  },
  null,
  { forwardRef: true }
)(MachineEditForm);
