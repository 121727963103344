export const MACHINE_SELECTED = "CallTechnician/MACHINE_SELECTED";
export const CARD_LOADING = "CallTechnician/CARD_LOADING";
export const OCCURRENCE_LOADING = "CallTechnician/OCCURRENCE_LOADING";
export const OCCURRENCE_CANCELED = "CallTechnician/OCCURRENCE_CANCELED";
export const OCCURRENCE_FINISHED = "CallTechnician/OCCURRENCE_FINISHED";
export const OCCURRENCE_ARRIVED = "CallTechnician/OCCURRENCE_ARRIVED";
export const OCCURRENCE_REGISTERED = "CallTechnician/OCCURRENCE_REGISTERED";
export const OCCURRENCE_ESCALATED = "CallTechnician/OCCURRENCE_ESCALATED";

export const ACHIEVEMENT_LOADING = "CallTechnician/ACHIEVEMENT_LOADING";
export const ACHIEVEMENT_SENDED = "CallTechnician/ACHIEVEMENT_SENDED";

export const SHOW_FINISH_OBSERVATION_FORM =
  "CallTechnician/SHOW_FINISH_OBSERVATION_FORM";
export const CLOSE_FINISH_OBSERVATION_FORM =
  "CallTechnician/CLOSE_FINISH_OBSERVATION_FORM";

export const ADD_MACHINE_BOOKMARK = "CallTechnician/ADD_MACHINE_BOOKMARK";
export const REMOVE_MACHINE_BOOKMARK = "CallTechnician/REMOVE_MACHINE_BOOKMARK";

export const REDUCER_NAME = "call_technician";
