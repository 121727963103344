import { createAction } from "redux-actions";
import api from "../../../api";
import isEmpty from "lodash/isEmpty";

import {
  SECTOR_FETCHING,
  SECTOR_FETCHED,
  SECTOR_CREATED,
  SECTOR_UPDATED,
  SECTOR_DELETED,
  REDUCER_NAME,
} from "../constants";

import isElectron from "../../../utils/isElectron";

let setSectorsShortcuts = null;
if (isElectron()) {
  const ipcRenderer = window.ipcRenderer;
  setSectorsShortcuts = (sectors) =>
    ipcRenderer.send("set-sectors-shortcuts", sectors);
}

export const sectorsFetched = createAction(SECTOR_FETCHED);
export const sectorCreated = createAction(SECTOR_CREATED);
export const sectorUpdated = createAction(SECTOR_UPDATED);
export const sectorDeleted = createAction(SECTOR_DELETED);

export const fetchAll = (force = false) => (dispatch, getState) => {
  const sectors = getSectors(getState());
  if (!isEmpty(sectors) && !force) {
    return dispatch(sectorsFetched(sectors));
  }

  dispatch({ type: SECTOR_FETCHING });

  return api.sectors
    .fetchAll()
    .then((sectors) => dispatch(sectorsFetched(sectors)));
};

export const createSector = (data) => (dispatch, getState) => {
  dispatch({ type: SECTOR_FETCHING });

  return api.sectors
    .create(data)
    .then((sector) => dispatch(sectorCreated(sector)));
};

export const updateSector = (id, data) => (dispatch, getState) => {
  dispatch({ type: SECTOR_FETCHING });

  return api.sectors
    .update(id, data)
    .then((sector) => dispatch(sectorUpdated(sector)));
};

export const deleteSector = (id) => (dispatch, getState) => {
  dispatch({ type: SECTOR_FETCHING });

  return api.sectors.delete(id).then(() => dispatch(sectorDeleted()));
};

const initialState = {
  sectors: [],
  loading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SECTOR_FETCHED:
      if (setSectorsShortcuts) {
        const sectors = action.payload
          .filter((sector) => sector.hotkey)
          .map((sector) => ({ id: sector.id, hotkey: sector.hotkey }));
        setSectorsShortcuts(sectors);
      }
      return {
        ...state,
        sectors: action.payload,
        loading: false,
      };
    case SECTOR_FETCHING:
      return { ...state, loading: true };
    case SECTOR_CREATED:
    case SECTOR_UPDATED:
    case SECTOR_DELETED:
      //TODO(Hudo): Properly handle all this actions and not force a fetchAll
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSectors = (state) => state[REDUCER_NAME].sectors;
export const getLoading = (state) => state[REDUCER_NAME].loading;
