import React from "react";
import { connect } from "react-redux";

import { Grid, Loader, Dimmer } from "semantic-ui-react";

import { secondsToString } from "../../../utils/timeToString";

import {
    getParams,
    getStats,
    getStatsLoading,
    fetchStats
} from "../ducks/occurrences";

import StatsCard from "./StatsCard";

class OccurrenceStats extends React.Component {
    componentDidUpdate(prevProps, prevState) {
        if (this.props.params !== prevProps.params) {
            this.props.fetchStats(this.props.params);
        }
    }

    componentDidMount() {
        this.props.fetchStats(this.props.params);
    }

    render() {
        const { stats, loading } = this.props;
        const avg_arriving =
            stats.avg_arriving !== undefined
                ? secondsToString(stats.avg_arriving)
                : "";

        const avg_attending =
            stats.avg_attending !== undefined
                ? secondsToString(stats.avg_attending)
                : "";

        const number = stats.number !== undefined ? stats.number : "";

        return (
            <Grid>
                <Grid.Row columns={3}>
                    <Dimmer active={loading} inverted>
                        <Loader />
                    </Dimmer>
                    <Grid.Column>
                        <StatsCard
                            value={number}
                            description="Número de atendimentos"
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <StatsCard
                            value={avg_arriving}
                            description="Tempo médio de espera"
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <StatsCard
                            value={avg_attending}
                            description="Tempo médio de atendimento"
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        params: getParams(state),
        stats: getStats(state),
        loading: getStatsLoading(state)
    };
}

export default connect(mapStateToProps, { fetchStats })(OccurrenceStats);
