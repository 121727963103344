import React from "react";
import PropTypes from "prop-types";

import get from "lodash/get";
import set from "lodash/set";
import clone from "lodash/clone";

import validator from "validator";

import { Button, Form, Message, Popup, Icon } from "semantic-ui-react";

function secondsToTime(seconds) {
  if (seconds === undefined) {
    return {
      seconds: "",
      minutes: "",
      hours: "",
    };
  }
  return {
    seconds: seconds % 60,
    minutes: Math.floor((seconds / 60) % 60),
    hours: Math.floor(seconds / 3600),
  };
}

function timeToSeconds(time) {
  return (
    Number(time.hours) * 3600 + Number(time.minutes) * 60 + Number(time.seconds)
  );
}

const UploadButton = ({ name, value, onChange, onDelete, icon, accept }) => {
  const fileInput = React.useRef();

  return (
    <span>
      <Button
        primary
        icon={icon}
        onClick={(e) => {
          e.preventDefault();
          fileInput.current.click();
        }}
        content="Selecionar arquivo"
      />
      {!!value && (
        <span>
          <b>Arquivo: </b> {get(value, "name", "")}
          <Button
            basic
            icon="close"
            onClick={(e) => {
              e.preventDefault();
              onChange(null, { name, value: null });
            }}
            style={{ marginLeft: "24px" }}
          />
        </span>
      )}
      <input
        accept={accept ? accept : ""}
        type="file"
        style={{ display: "none" }}
        onChange={() => {
          onChange(null, { name, value: fileInput.current.files[0] });
        }}
        ref={fileInput}
      />
    </span>
  );
};

class PriorityEditForm extends React.Component {
  state = {
    data: {
      name: "",
      sound: "",
      sector_id: undefined,
      started_timeout: secondsToTime(0),
      arrived_timeout: secondsToTime(0),
    },
    errors: [],
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e, props) {
    const name = props.name;
    const value = props.value;

    const data = set(clone(this.state.data), name, value);
    this.setState({ data });
  }

  validate() {
    const { data } = this.state;

    let errors = [];

    if (!data.sector_id) {
      errors.push({ field: "sector_id", message: "Setor é obrigatório" });
    }

    if (validator.isEmpty(data.name)) {
      errors.push({ field: "name", message: "Nome é obrigatório" });
    }

    return errors;
  }

  onSubmit() {
    const errors = this.validate();

    this.setState({ errors });

    if (errors.length === 0) {
      const { data } = this.state;

      let formData = new FormData();

      formData.append("name", data.name);
      formData.append("sector_id", data.sector_id);
      formData.append("started_timeout_s", timeToSeconds(data.started_timeout));
      formData.append("arrived_timeout_s", timeToSeconds(data.arrived_timeout));
      if (data.sound instanceof File) {
        formData.append("sound_file", data.sound);
      } else if (!data.sound) {
        formData.append("delete_sound", true);
      }

      return this.props.submit(formData);
    } else {
      return Promise.reject();
    }
  }

  componentDidMount() {
    const { priority } = this.props;
    if (priority) {
      const started_timeout = secondsToTime(priority.started_timeout_s);
      const arrived_timeout = secondsToTime(priority.arrived_timeout_s);

      this.setState({
        data: {
          name: priority.name,
          sound: priority.sound,
          sector_id: priority.sector_id,
          started_timeout,
          arrived_timeout,
        },
      });
    }
  }

  render() {
    const { data, errors } = this.state;
    const { sectors } = this.props;

    const errorsFields = errors.map((e) => e.field);

    return (
      <Form onSubmit={this.onSubmit} error={errorsFields.length > 0}>
        <Form.Input
          label="Nome"
          type="text"
          name="name"
          value={data.name}
          onChange={this.onChange}
          placeholder="Ex: Técnico, Gerente, Diretor"
          required
          autoFocus
          error={errorsFields.indexOf("name") !== -1}
        />

        <Form.Dropdown
          label="Setor"
          selection
          name="sector_id"
          onChange={this.onChange}
          value={data.sector_id}
          options={sectors.map((s) => ({
            key: s.id,
            value: s.id,
            text: s.name,
          }))}
          required
          error={errorsFields.indexOf("sector_id") !== -1}
        />

        <Form.Field>
          <label>
            Tempo máximo de espera
            <Popup
              trigger={<Icon name="help circle" size="large" color="blue" />}
              content="Caso não seja registrado o inicio do atendimento até o tempo máximo de espera, o chamado será elevado para o próximo nível."
              basic
            />
          </label>
          <Form.Group inline>
            <Form.Input
              type="number"
              name="started_timeout.hours"
              value={data.started_timeout.hours}
              min={0}
              onChange={this.onChange}
              style={{ width: "70px" }}
            />
            <label>horas</label>
            <Form.Input
              type="number"
              name="started_timeout.minutes"
              value={data.started_timeout.minutes}
              min={0}
              onChange={this.onChange}
              style={{ width: "70px" }}
            />
            <label>minutos</label>
            <Form.Input
              type="number"
              name="started_timeout.seconds"
              value={data.started_timeout.seconds}
              min={0}
              onChange={this.onChange}
              style={{ width: "70px" }}
            />
            <label>segundos</label>
          </Form.Group>
        </Form.Field>

        <Form.Field>
          <label>
            Tempo máximo de atendimento
            <Popup
              trigger={<Icon name="help circle" size="large" color="blue" />}
              content="Caso não seja registrado a finalização do atendimento até o tempo máximo de atendimento, o chamado será elevado para o próximo nível."
              basic
            />
          </label>
          <Form.Group inline>
            <Form.Input
              type="number"
              name="arrived_timeout.hours"
              value={data.arrived_timeout.hours}
              min={0}
              onChange={this.onChange}
              style={{ width: "70px" }}
            />
            <label>horas</label>
            <Form.Input
              type="number"
              name="arrived_timeout.minutes"
              value={data.arrived_timeout.minutes}
              min={0}
              onChange={this.onChange}
              style={{ width: "70px" }}
            />
            <label>minutos</label>
            <Form.Input
              type="number"
              name="arrived_timeout.seconds"
              value={data.arrived_timeout.seconds}
              min={0}
              onChange={this.onChange}
              style={{ width: "70px" }}
            />
            <label>segundos</label>
          </Form.Group>
        </Form.Field>

        <Form.Field>
          <label>
            Som de alerta (.mp3)
            <Popup
              trigger={<Icon name="help circle" size="large" color="blue" />}
              content="O som de alerta irá tocar nas telas de Chamar Técnico, Kanban e Visão Geral quando houver um chamado ativo."
              basic
            />
          </label>
          <UploadButton
            icon="file audio outline"
            name="sound"
            accept=".mp3"
            onChange={this.onChange}
            value={data.sound}
          />
        </Form.Field>

        <Message error list={errors.map((e) => e.message)} />
      </Form>
    );
  }
}

PriorityEditForm.propTypes = {
  submit: PropTypes.func.isRequired,
  priority: PropTypes.shape({
    name: PropTypes.string,
    started_timeout_s: PropTypes.number,
    arrived_timeout_s: PropTypes.number,
  }),
};

export default PriorityEditForm;
