export const FETCH_ALL_REQUEST = "Permissions/FETCH_ALL_REQUEST";
export const FETCH_ALL_SUCCESS = "Permissions/FETCH_ALL_SUCCESS";
export const FETCH_ALL_PAGES_SUCCESS = "Permissions/FETCH_ALL_PAGES_SUCCESS";

export const UPDATE_SUCCESS = "Permissions/UPDATE_SUCCESS";
export const CREATE_SUCCESS = "Permissions/CREATE_SUCCESS";
export const DELETE_SUCCESS = "Permissions/DELETE_SUCCESS";
export const ADD_CLICK = "Permissions/ADD_CLICK";
export const EDIT_CLICK = "Permissions/EDIT_CLICK";
export const DELETE_CLICK = "Permissions/DELETE_CLICK";
export const DELETE_CANCELED = "Permissions/DELETE_CANCELED";
export const EDIT_CANCELED = "Permissions/EDIT_CANCELED";

export const REDUCER_NAME = "permissions";
