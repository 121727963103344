import axios from "axios";
import Occurrence from "../models/Occurrence";

function mapMachine(response, machine) {
    return {
        ...machine,
        last_occurrence: machine.last_occurrence
            ? new Occurrence(machine.last_occurrence, response.timeOffset)
            : undefined
    };
}

function mapGroup(response, group) {
    return {
        ...group,
        machines: group.machines
            ? group.machines.map(machine => mapMachine(response, machine))
            : undefined
    };
}

export default {
    fetchAll: () => {
        return axios
            .get("/api/v1/machine-groups/")
            .then(response => response.data.data);
    },
    getById: id => {
        return axios
            .get(`/api/v1/machine-groups/${id}`)
            .then(response => response.data.data);
    },
    overview: params => {
        return axios
            .get(`/api/v1/machine-groups/overview`, { params })
            .then(response =>
                response.data.data.map(group => mapGroup(response, group))
            );
    },
    create: data => {
        return axios
            .post(`/api/v1/machine-groups`, data)
            .then(response => response.data.data);
    },
    update: (id, data) => {
        return axios
            .post(`/api/v1/machine-groups/${id}`, data)
            .then(response => response.data.data);
    },
    up: id => {
        return axios
            .post(`/api/v1/machine-groups/${id}/up`)
            .then(response =>
                response.data.data.map(group => mapGroup(response, group))
            );
    },
    down: id => {
        return axios
            .post(`/api/v1/machine-groups/${id}/down`)
            .then(response =>
                response.data.data.map(group => mapGroup(response, group))
            );
    },
    delete: id => {
        return axios.delete(`/api/v1/machine-groups/${id}`);
    }
};
