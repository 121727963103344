import axios from "axios";

export default {
    fetchAll: () => {
        return axios
            .get("/api/v1/priorities/")
            .then(response => response.data.data);
    },
    create: data => {
        return axios
            .post(`/api/v1/priorities`, data)
            .then(response => response.data.data);
    },
    update: (id, data) => {
        return axios
            .post(`/api/v1/priorities/${id}`, data)
            .then(response => response.data.data);
    },
    up: id => {
        return axios
            .post(`/api/v1/priorities/${id}/up`)
            .then(response => response.data.data);
    },
    down: id => {
        return axios
            .post(`/api/v1/priorities/${id}/down`)
            .then(response => response.data.data);
    },
    delete: id => {
        return axios.delete(`/api/v1/priorities/${id}`);
    }
};
