import get from "lodash/get";
import { MACHINE_LEVEL_OPTIONS } from "../constants";

function parseDate(date, offset) {
  if (date) {
    const parsedDate = new Date(date);
    return offset ? new Date(parsedDate.getTime() - offset) : parsedDate;
  } else {
    return null;
  }
}

export default class {
  constructor(data, offset) {
    for (const prop in data) {
      this[prop] = data[prop];
    }

    this.originals = { ...data };

    this.started_on = parseDate(data.started_on);
    this.arrived_on = parseDate(data.arrived_on);
    this.finished_on = parseDate(data.finished_on);
    this.canceled_on = parseDate(data.canceled_on);
    this.updated_at = parseDate(data.updated_at, offset);
    this.created_at = parseDate(data.created_at, offset);
  }

  getSectorName() {
    return get(this, "sector.name");
  }

  getMachineName() {
    return get(this, "machine.name", "");
  }

  getMachineLevel() {
    const level = get(this, "machine.level", 0);
    return get(
      MACHINE_LEVEL_OPTIONS.find(({ value }) => value === level),
      "text",
      ""
    );
  }

  getPriorityName() {
    return get(this, "priority.name", "");
  }

  isSuccess() {
    return this.finished_on !== null && this.canceled_on === null;
  }
  isCanceled() {
    return this.canceled_on !== null;
  }
  isFinished() {
    return this.finished_on !== null || this.canceled_on !== null;
  }
  isArrived() {
    return (
      this.arrived_on !== null &&
      this.finished_on === null &&
      this.canceled_on === null
    );
  }
  isWaiting() {
    return (
      this.started_on !== null &&
      this.arrived_on === null &&
      this.finished_on === null &&
      this.canceled_on === null
    );
  }
  hasLevel(level) {
    return this.priority && this.priority.level === level;
  }
}
