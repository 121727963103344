import React from "react";
import { connect } from "react-redux";

import { Button, Card, Header, List } from "semantic-ui-react";

import {
    upMachineGroup,
    downMachineGroup,
    openEditForm,
    openDeleteConfirm
} from "../ducks/overview";

import OverViewMachineCard from "./OverviewMachineCard";

class OverviewMachineGroup extends React.Component {
    renderActionButtons() {
        const { group, isLast, isFirst, canEdit } = this.props;

        return [
            <Button.Group
                key="0"
                size="tiny"
                basic
                style={{ marginRight: "20px" }}
            >
                <Button
                    icon="arrow up"
                    basic
                    disabled={isFirst || !canEdit}
                    onClick={() => this.props.upMachineGroup(group.id)}
                />
                <Button
                    icon="arrow down"
                    basic
                    disabled={isLast || !canEdit}
                    onClick={() => this.props.downMachineGroup(group.id)}
                />
            </Button.Group>,
            <Button
                key="1"
                basic
                icon="edit"
                size="tiny"
                disabled={!canEdit}
                onClick={() => this.props.openEditForm(group)}
            />,
            <Button
                key="2"
                basic
                icon="delete"
                size="tiny"
                disabled={!canEdit}
                onClick={() => this.props.openDeleteConfirm(group)}
            />
        ];
    }

    render() {
        const { group } = this.props;
        const machines = group.machines;

        const machineCards = machines.map(machine => (
            <OverViewMachineCard machine={machine} key={machine.id} />
        ));

        return (
            <List.Item
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                style={{ marginTop: "22px" }}
            >
                <List.Header style={{ marginBottom: "8px", display: "flex" }}>
                    <Header
                        as="h2"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                        {group.name}
                    </Header>
                    <div style={{ marginLeft: "20px" }}>
                        {this.renderActionButtons()}
                    </div>
                </List.Header>

                <List.Description>
                    <Card.Group>{machineCards}</Card.Group>
                </List.Description>
            </List.Item>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {
    upMachineGroup,
    downMachineGroup,
    openEditForm,
    openDeleteConfirm
})(OverviewMachineGroup);
