import React, { useState } from "react";
import { Form } from "semantic-ui-react";

const initialState = {
  data: { text: "" },
};

const AcitivityForm = ({ onSubmit, loading }) => {
  const [state, setState] = useState(initialState);

  const onChange = (e, { name, value }) => {
    setState({ data: { ...state.data, [name]: value } });
  };

  const handleSubmit = () => {
    onSubmit(state.data).then(() => setState(initialState));
  };

  const { data } = state;

  return (
    <Form id="activity_submit_form" onSubmit={handleSubmit}>
      <Form.Input
        required
        disabled={loading}
        action={{ content: "Enviar", primary: true, loading }}
        placeholder="Registre sua atividade durante o atendimento"
        name="text"
        value={data.text}
        onChange={onChange}
      />
    </Form>
  );
};

export default AcitivityForm;
