import React, { Component } from "react";
import PropTypes from "prop-types";

import get from "lodash/get";

import { Grid, Button, Dimmer, Loader, Table, Label } from "semantic-ui-react";

import { SortableTableHeader } from "../../Shared";

const HEADERS = [
  { name: null, label: "Nome" },
  { name: null, label: "Cor" },
  { name: null, label: "Atalho" },
  { name: null, label: "Ações" }
];

const TableBody = ({ sectors, onEdit, onDelete, canEdit }) => (
  <Table.Body>
    {sectors.map((sector, key) => (
      <Table.Row key={sector.id}>
        <Table.Cell>{sector.name}</Table.Cell>
        <Table.Cell>
          <Label color={sector.color} />
        </Table.Cell>
        <Table.Cell>{get(sector, "hotkey", "")}</Table.Cell>
        <Table.Cell width={3}>
          <Button
            basic
            icon="edit"
            disabled={!canEdit}
            onClick={() => onEdit(sector)}
            style={{ marginRight: "20px" }}
          />
          <Button
            basic
            icon="delete"
            disabled={!canEdit}
            onClick={() => onDelete(sector)}
            style={{ marginRight: "20px" }}
          />
        </Table.Cell>
      </Table.Row>
    ))}
  </Table.Body>
);

class SectorTable extends Component {
  render() {
    const { sectors, loading, canEdit } = this.props;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h1>Setores</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <Dimmer active={loading} inverted>
              <Loader />
            </Dimmer>
            <Button
              className="add-sector-btn"
              primary
              icon="add"
              content="Novo setor"
              disabled={!canEdit}
              onClick={this.props.onAddClick}
            />
            <Table selectable>
              <SortableTableHeader headers={HEADERS} />
              <TableBody
                canEdit={canEdit}
                sectors={sectors}
                onEdit={this.props.onEditClick}
                onDelete={this.props.onDeleteClick}
              />
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

SectorTable.propTypes = {
  sectors: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  Click: PropTypes.func,
  onDeleteClick: PropTypes.func
};

SectorTable.defaultProps = {
  sectors: [],
  loading: false,
  onAddClick: () => {},
  onEditClick: () => {},
  Click: () => {},
  onDeleteClick: () => {}
};

export default SectorTable;
