import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import find from "lodash/find";
import filter from "lodash/filter";

import { Menu, Icon, Dropdown, Image, Popup } from "semantic-ui-react";

import userHasPage from "../../../utils/userHasPage";

import { getUser, logout } from "../../Auth";
import { startTutorial } from "../../Tutorial/ducks/tutorial";

import { MENU_ITENS } from "../../../constants";

import { getWaitingOccurrences } from "../../Kanban/ducks/kanban";

import userPlaceholderImg from "../../../assets/imgs/user-placeholder.jpg";
import Logo from "../../../assets/imgs/logo.png";

const MenuItem = ({ item, customProps }) => (
  <Menu.Item
    as={Link}
    to={item.to}
    active={customProps.location.pathname === item.to}
    {...(item.customProps ? item.customProps(customProps) : {})}
  >
    {item.label}
  </Menu.Item>
);

const PopupMenuItem = ({ item, customProps, subMenu }) => (
  <Popup
    flowing
    hoverable
    position="bottom center"
    trigger={
      <Menu.Item
        active={
          find(item.subMenu, {
            to: customProps.location.pathname,
          }) !== undefined
        }
        {...(item.customProps ? item.customProps(customProps) : {})}
      >
        {item.label}
        <Icon name="dropdown" style={{ width: "auto" }} />
      </Menu.Item>
    }
  >
    <Menu secondary>
      {subMenu.map((item, key) => (
        <MenuItem item={item} customProps={customProps} key={item.slug} />
      ))}
    </Menu>
  </Popup>
);

const TopNavigation = (props) => {
  const { user, logout, startTutorial } = props;

  const menu = MENU_ITENS.map((item, key) => {
    const subMenu = filter(item.subMenu, (subItem) =>
      userHasPage(user, subItem.slug)
    );

    if (subMenu && subMenu.length >= 3) {
      return (
        <PopupMenuItem
          item={item}
          key={key}
          customProps={props}
          subMenu={subMenu}
        />
      );
    } else if (userHasPage(user, item.slug)) {
      return <MenuItem item={item} key={key} customProps={props} />;
    } else {
      return null;
    }
  });

  return (
    <Menu>
      <Menu.Item header>
        <img src={Logo} alt="" style={{ marginRight: "6px" }} />
        Andon
      </Menu.Item>

      {menu}

      <Menu.Menu position="right">
        <Dropdown
          item
          trigger={
            <span>
              <Image avatar src={userPlaceholderImg} />
              {user.name}
            </span>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={"/help"}>
              Ajuda
            </Dropdown.Item>
            <Dropdown.Item onClick={() => startTutorial()}>
              Tutorial
            </Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}>Sair</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

function mapStateToProps(state) {
  return {
    hasWaitingOccurrences: getWaitingOccurrences(state).length > 0,
    user: getUser(state),
  };
}

export default connect(mapStateToProps, { logout, startTutorial })(
  TopNavigation
);
