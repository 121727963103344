import axios from "axios";

export default {
    fetchAll: () => {
        return axios.get("api/v1/sectors").then(response => response.data.data);
    },

    create: data => {
        return axios
            .post("api/v1/sectors", data)
            .then(response => response.data.data);
    },

    update: (id, data) => {
        return axios
            .post(`/api/v1/sectors/${id}`, data)
            .then(response => response.data.data);
    },

    delete: id => {
        return axios.delete(`/api/v1/sectors/${id}`);
    }
};
