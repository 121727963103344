import React, { Component } from "react";
import { Form, Message } from "semantic-ui-react";

class FinishObservationForm extends Component {
    state = {
        data: {
            finish_observation: ""
        },
        errors: []
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e, { name, value }) {
        this.setState({ data: { ...this.state.data, [name]: value } });
    }

    onSubmit() {
        return this.props.submit(this.state.data);
    }

    render() {
        const { data, errors } = this.state;
        const errorsFields = errors.map(e => e.field);

        return (
            <Form
                id="finish_obersvation_edit_form"
                onSubmit={this.onSubmit}
                error={errors.length > 0}
            >
                <Form.TextArea
                    label="Observações Finais"
                    name="finish_observation"
                    value={data.finish_observation}
                    onChange={this.onChange}
                    placeholder="Observações"
                    error={errorsFields.indexOf("finish_observation") !== -1}
                />

                <Message error list={errors.map(e => e.message)} />
            </Form>
        );
    }
}

export default FinishObservationForm;
