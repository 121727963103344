import React from "react";
import PropTypes from "prop-types";

import { Message, Form } from "semantic-ui-react";

import get from "lodash/get";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: "",
        password: "",
        remember: false,
      },
      loading: false,
      hasError: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
  }

  onChange(e, props) {
    const name = props.name;
    const value = props.checked !== undefined ? props.checked : props.value;

    this.setState({
      data: { ...this.state.data, [name]: value },
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true, hasError: false });
    this.props.submit(this.state.data).catch(this.onError);
  }

  onError(error) {
    const status = get(error, "response.status");
    let msg =
      "Erro no sistema, por favor tente novamente. Caso o problema persista entre em contato com o suporte";

    if (status === 401) {
      msg = "Usuário ou senha incorretos, por favor tente novamente";
    }

    this.setState({
      loading: false,
      hasError: true,
      errorMessage: msg,
    });
  }

  render() {
    const { className } = this.props;
    const { data, loading, hasError, errorMessage } = this.state;

    return (
      <Form
        onSubmit={this.onSubmit}
        loading={loading}
        error={hasError}
        className={className}
      >
        <Form.Input
          label="Email"
          type="text"
          name="username"
          id="username"
          value={data.username}
          onChange={this.onChange}
          placeholder="Email"
          required
          autoFocus
        />

        <Form.Input
          label="Senha"
          type="password"
          id="password"
          name="password"
          value={data.password}
          onChange={this.onChange}
          className="form-control"
          placeholder="Senha"
          required
        />

        <Form.Checkbox
          label="Lembrar"
          name="remember"
          id="remember"
          checked={data.remember}
          onChange={this.onChange}
        />

        <Form.Button
          primary
          style={{
            width: "100%",
            maxWidth: "240px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Entrar
        </Form.Button>

        <Message error content={errorMessage} />
      </Form>
    );
  }
}

LoginForm.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default LoginForm;
