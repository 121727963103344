import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import { Container, Modal, Button, Responsive } from "semantic-ui-react";

import get from "lodash/get";

import { USER_ROUTES, GUEST_ROUTES } from "../../../routes";

import { setErrorMessage, getErrorMessage } from "../ducks/app";

import { GuestRoute, UserRoute, TopNavigation } from "../../Shared";
import { getActiveOccurrence } from "../../CallTechnician";
import { getUser } from "../../Auth";

import { AppTutorial } from "../../Tutorial";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fluid: false,
    };

    this.handleOnUpdate = this.handleOnUpdate.bind(this);
  }

  renderGuestRoutes(location) {
    return GUEST_ROUTES.map((route) => (
      <GuestRoute
        key={route.path}
        location={location}
        exact
        path={route.path}
        component={route.component}
        slug={route.slug}
      />
    ));
  }

  renderUserRoutes(location) {
    return USER_ROUTES.map((route) => (
      <UserRoute
        key={route.path}
        location={location}
        exact
        path={route.path}
        component={route.component}
        slug={route.slug}
      />
    ));
  }

  handleOnUpdate(e, { width }) {
    this.setState({ fluid: width >= Responsive.onlyWidescreen.minWidth });
  }

  render() {
    const { isAuthenticated, location, occurrence, errorMessage } = this.props;
    const { fluid } = this.state;

    let className = "";

    if (occurrence && !occurrence.isFinished()) {
      className = "kanban-alarm-state";
    }

    return (
      <Responsive
        as={Container}
        fluid
        className={className}
        style={{
          minHeight: "100vh",
          animationName: `alarm-${get(occurrence, "sector.color")}`,
          padding: fluid && "0px 30px",
        }}
        fireOnMount
        onUpdate={this.handleOnUpdate}
      >
        <AppTutorial />
        <ToastContainer />

        <Container fluid={fluid} style={{ marginTop: "14px" }}>
          {isAuthenticated && <TopNavigation location={location} />}
          {this.renderGuestRoutes(location)}
          {this.renderUserRoutes(location)}
          {/* </ErrorBoundary> */}
        </Container>

        <Modal
          size="small"
          open={!!errorMessage}
          onClose={() => this.props.setErrorMessage()}
        >
          <Modal.Header>Erro!</Modal.Header>
          <Modal.Content>
            <p>{errorMessage}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => this.props.setErrorMessage()}>
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
      </Responsive>
    );
  }
}

function mapStateToProps(state) {
  return {
    occurrence: getActiveOccurrence(state),
    errorMessage: getErrorMessage(state),
    isAuthenticated: !!getUser(state),
  };
}

export default connect(mapStateToProps, { setErrorMessage })(App);
