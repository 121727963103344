import React from "react";

import { Card } from "semantic-ui-react";

import { CardWithMarker } from "../../Shared";

export default ({ value, description }) => (
    <CardWithMarker markerColor="#1a4687" fluid>
        <Card.Content>
            <Card.Description
                style={{
                    fontSize: "3em",
                    lineHeight: "1em",
                    fontWeight: "bold"
                }}
            >
                {value}
            </Card.Description>
            <Card.Description
                style={{ fontSize: "1.5em", lineHeight: "1.5em" }}
            >
                {description}
            </Card.Description>
        </Card.Content>
    </CardWithMarker>
);
