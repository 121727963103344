import { createAction } from "redux-actions";
import { REDUCER_NAME, SET_ERROR_MESSAGE } from "../constants";

export const setErrorMessage = createAction(SET_ERROR_MESSAGE);

const initialState = { error_message: undefined };

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return { ...state, error_message: action.payload };
    default:
      return state;
  }
};

export const getErrorMessage = state => state[REDUCER_NAME].error_message;
