import _get from "lodash/get";

import Occurrence from "../models/Occurrence";

export function mapMachine(response, machine) {
  return {
    ...machine,
    last_occurrence: machine.last_occurrence
      ? new Occurrence(machine.last_occurrence, response.timeOffset)
      : undefined,
  };
}

export function mapGroup(response, group) {
  return {
    ...group,
    machines: group.machines
      ? group.machines.map((machine) => mapMachine(response, machine))
      : undefined,
  };
}

export function extractErrors(response, defaultError) {
  const apiErrors = _get(response, "data.errors", {});
  const errorFields = Object.keys(apiErrors);

  const formattedApiErrors = errorFields.reduce((errors, field) => {
    const fieldErrors = apiErrors[field].map((message) => ({ field, message }));

    return errors.concat(fieldErrors);
  }, []);

  if (formattedApiErrors.length === 0) {
    return [defaultError];
  }

  return formattedApiErrors;
}
