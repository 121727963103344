import React from "react";
import { connect } from "react-redux";

import {
  Confirm,
  Container,
  Grid,
  Header,
  List,
  Loader,
} from "semantic-ui-react";

import get from "lodash/get";

import { getUser, getSocketConnected } from "../../Auth";
import userHasPage from "../../../utils/userHasPage";

import {
  fetchOverview,
  closeEditForm,
  closeDeleteConfirm,
  updateMachineGroup,
  deleteMachineGroup,
  getOverviewFiltered,
  getLoading,
  getEditFormOpen,
  getSelected,
  getDeleteConfirmOpen,
  getSoundList,
} from "../ducks/overview";

import { ListAudioPlayer, FormModal } from "../../Shared";

import OverviewFilter from "./OverviewFilter";
import OverviewLegend from "./OverviewLegend";
import OverviewMachineGroup from "./OverviewMachineGroup";

import MachineGroupEditForm from "./MachineGroupEditForm";

import socket from "../../../utils/socket";

class OverviewPage extends React.Component {
  constructor(props) {
    super(props);

    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onOverviewUpdate = this.onOverviewUpdate.bind(this);
  }

  componentDidMount() {
    if (this.props.socketConnected) {
      socket.on("occurrences-update", this.onOverviewUpdate);
    }

    this.props.fetchOverview();
  }

  componentWillUnmount() {
    if (this.props.socketConnected) {
      socket.removeAllListeners("occurrences-update");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.socketConnected && !prevProps.socketConnected) {
      socket.on("occurrences-update", this.onOverviewUpdate);
    }
  }

  onOverviewUpdate(data) {
    this.props.fetchOverview();
  }

  onEditFormSubmit({ id, ...data }) {
    return this.props
      .updateMachineGroup(id, data)
      .then(() => this.props.closeEditForm())
      .then(() => this.props.fetchOverview());
  }

  onDeleteConfirm() {
    const { selectedMachineGroup } = this.props;

    this.props.closeDeleteConfirm();

    return this.props
      .deleteMachineGroup(selectedMachineGroup.id)
      .then(() => this.props.fetchOverview());
  }

  render() {
    const {
      groupsOverviewFetching,
      groupsOverview,
      formOpen,
      selectedMachineGroup,
      deleteConfirmOpen,
      canEdit,
      soundList,
    } = this.props;

    const groupViews = groupsOverview.map((group, index) => (
      <OverviewMachineGroup
        group={group}
        key={group.id || group.name}
        isFirst={index === 0}
        isLast={index === groupsOverview.length - 1}
        canEdit={canEdit && group.id}
      />
    ));

    return (
      <Container fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <Header as="h1">
                Visão Geral
                <Loader active={groupsOverviewFetching} inline />
              </Header>

              <div style={{ maxWidth: "400px" }}>
                <OverviewFilter />
              </div>

              <List relaxed="very">{groupViews}</List>
            </Grid.Column>
            <Grid.Column width={3} floated="right">
              <OverviewLegend />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <FormModal
          size="tiny"
          header="Editar Grupo"
          open={formOpen}
          onClose={() => this.props.closeEditForm()}
          formRef={() => this.form}
          id="edit_modal"
        >
          <MachineGroupEditForm
            ref={(form) => (this.form = form)}
            submit={this.onEditFormSubmit}
            machineGroup={selectedMachineGroup}
          />
        </FormModal>

        <Confirm
          open={deleteConfirmOpen}
          content={`Tem certeza que deseja excluir o grupo: ${get(
            selectedMachineGroup,
            "name"
          )}?`}
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.props.closeDeleteConfirm()}
          onConfirm={this.onDeleteConfirm}
        />

        <ListAudioPlayer sources={soundList} delayMS={0} />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const user = getUser(state);
  return {
    soundList: getSoundList(state),
    groupsOverview: getOverviewFiltered(state),
    groupsOverviewFetching: getLoading(state),
    formOpen: getEditFormOpen(state),
    deleteConfirmOpen: getDeleteConfirmOpen(state),
    selectedMachineGroup: getSelected(state),
    canEdit: userHasPage(user, "overview", true),
    socketConnected: getSocketConnected(state),
  };
}

export default connect(mapStateToProps, {
  fetchOverview,
  closeEditForm,
  closeDeleteConfirm,
  updateMachineGroup,
  deleteMachineGroup,
})(OverviewPage);
