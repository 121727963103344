import io from "socket.io-client";

let socket;
let conn;

const loggerCallback = (event, ...args) => {
  console.log(`[WS] received ${event} with ${args}`);
};

export default {
  connect: function (token, namespace) {
    const addr = process.env.REACT_APP_API_HOST + namespace;
    console.log("connecting socket react to: ", addr);
    socket = io(addr, {
      query: {
        token: token,
      },
    });
    socket.onAny(loggerCallback);

    conn = socket.connect();
  },

  on: function (channel, cb) {
    conn.on(channel, cb);
  },

  isConnected: function () {
    return conn && conn.connected;
  },

  disconnect: function () {
    return socket.disconnect();
  },

  removeListener: function (channel, cb) {
    return conn.off(channel, cb);
  },

  removeAllListeners: function (channel) {
    socket.removeAllListeners(channel);
  },
};
