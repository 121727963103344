import React from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import styled from "styled-components";

const Bar = styled(Grid.Row)`
  margin-top: 1em;
`;

const BarLabel = styled.span`
  font-weight: 700;
  margin-right: 8px;
`;

const RemoveIcon = styled(Icon)`
  margin: 0 0 0 0.5em !important;
`;

const MachineBookmark = ({ machine, isSelected, onClick, onRemoveClick }) => {
  return (
    <Button basic color={isSelected ? "blue" : undefined} onClick={onClick}>
      {machine.name}
      <RemoveIcon
        name="delete"
        onClick={(e) => {
          e.stopPropagation();
          onRemoveClick(e);
        }}
      />
    </Button>
  );
};

const MachinesBookmarkBar = ({
  machinesBookmark,
  selectedMachine,
  onMachineClick,
  onMachineRemove,
}) => {
  return (
    <Bar>
      <BarLabel>Acesso rápido:</BarLabel>
      {machinesBookmark.map((machine) => (
        <MachineBookmark
          key={machine.id}
          machine={machine}
          isSelected={machine.id === selectedMachine?.id}
          onClick={() => onMachineClick(machine.id)}
          onRemoveClick={() => onMachineRemove(machine.id)}
        />
      ))}
    </Bar>
  );
};

export default MachinesBookmarkBar;
