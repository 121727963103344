import { createAction } from "redux-actions";
import Raven from "raven-js";
import ReactGA from "react-ga";
import LogRocket from "logrocket";
import api from "../../../api";
import setAuthorizationHeader from "../../../utils/setAuthorizationHeader";

import socket from "../../../utils/socket";
import * as storage from "../../../utils/storage";

import { getFirebaseToken } from "../../../utils/firebase";

import { startTutorial } from "../../Tutorial";
import { setupKanban } from "../../Kanban";

import {
  REDUCER_NAME,
  USER_LOGGED_IN,
  USER_LOGGING_IN,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
  USER_LOGOUT,
  USER_SIGNED_UP,
} from "../constants";

export const userSignedUp = createAction(USER_SIGNED_UP);
export const userLoggedIn = createAction(USER_LOGGED_IN);
export const userLoggingIn = createAction(USER_LOGGING_IN);
export const socketConnected = createAction(SOCKET_CONNECTED);
export const userLogout = createAction(USER_LOGOUT);
export const socketDisconnected = createAction(SOCKET_DISCONNECTED);

export const registerSocketListenners = () => (dispatch) => {
  socket.on("connect", () => dispatch(socketConnected()));
  socket.on("disconnect", () => dispatch(socketDisconnected()));
};

export const signup = (data) => (dispatch) => {
  return api.auth
    .signup(data)
    .then(() => dispatch(userSignedUp()))
    .then(() => {
      const credentials = {
        username: data.user_email,
        password: data.password,
      };
      return dispatch(login(credentials));
    });
};

export const login = (credentials) => (dispatch) => {
  if (credentials.remember) {
    storage.useLocalStorage(true);
    storage.set("remember", true);
  }

  return api.auth.login(credentials).then((response) => {
    dispatch(authenticationChain(response.access_token));
  });
};

export const fetchMe = () => (dispatch) => {
  return api.users.getMe().then((user) => {
    Raven.setUserContext(user);
    ReactGA.set({ userId: user.id });
    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,
    });

    dispatch(userLoggedIn(user));
    if (user.show_tutorial) {
      dispatch(startTutorial());
    }

    return user;
  });
};

export const connectSocket = () => (dispatch) => {
  return api.auth.getSocketToken().then((token) => {
    socket.connect(token);
    dispatch(registerSocketListenners());
  });
};

export const registerDevice = () => (dispatch) => {
  return getFirebaseToken()
    .then((token) => api.devices.create(token))
    .catch((err) => console.error(err));
};

export const authenticationChain = (access_token) => (dispatch) => {
  dispatch(userLoggingIn());

  storage.set("access_token", access_token);
  setAuthorizationHeader(access_token);

  dispatch(connectSocket())
    .then(() => dispatch(fetchMe()))
    .then((user) => dispatch(setupKanban(user)));
  dispatch(registerDevice());
};

export const logout = () => (dispatch) => {
  storage.clear();

  setAuthorizationHeader(null);
  socket.token = null;
  socket.disconnect();
  socket.removeAllListeners();

  return dispatch(userLogout());
};

const initialState = { socket_connected: false, loading: false, user: null };

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_LOGGING_IN:
      return { ...state, loading: true };
    case USER_LOGGED_IN:
      return { ...state, loading: false, user: action.payload };
    case SOCKET_CONNECTED:
      return { ...state, socket_connected: true };
    default:
      return state;
  }
};

export const getSocketConnected = (state) =>
  state[REDUCER_NAME].socket_connected;
export const getUser = (state) => state[REDUCER_NAME].user;
export const getLoading = (state) => state[REDUCER_NAME].loading;
