import React from "react";
import PropTypes from "prop-types";

import find from "lodash/find";
import differenceBy from "lodash/differenceBy";
import set from "lodash/set";

import validator from "validator";

import { Form, Message, Table, Icon, Checkbox } from "semantic-ui-react";

const PagesTable = ({ pages, onRemove, onChangeRule }) => (
  <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Nome</Table.HeaderCell>
        <Table.HeaderCell>Pode Editar?</Table.HeaderCell>
        <Table.HeaderCell>Remover Acesso</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {pages.map((page, key) => (
        <Table.Row key={page.id}>
          <Table.Cell>{page.name}</Table.Cell>
          <Table.Cell>
            {!!page.has_edit && (
              <Checkbox
                toggle
                name="edit"
                checked={page.rule.edit}
                onChange={(e, p) => onChangeRule(e, p, page.id)}
              />
            )}
          </Table.Cell>
          <Table.Cell>
            <Icon
              link
              name="delete"
              onClick={() => onRemove(page)}
              style={{ marginRight: "20px" }}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

class PermissionEditForm extends React.Component {
  state = {
    data: {
      name: "",
      pages: []
    },
    errors: []
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeRule = this.onChangeRule.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onChangePage(e, props) {
    const page = find(this.props.pages, { id: props.value });
    const pages = this.state.data.pages.concat([
      {
        ...page,
        rule: {
          edit: false,
          view: true
        }
      }
    ]);

    this.setState({ data: { ...this.state.data, pages } });
  }

  onChangeRule(e, props, id) {
    let pages = this.state.data.pages.slice();
    let page = find(pages, { id });

    set(page, `rule.${props.name}`, props.checked);

    this.setState({ data: { ...this.state.data, pages } });
  }

  onChange(e, props) {
    const name = props.name;
    const value = props.value;

    this.setState({ data: { ...this.state.data, [name]: value } });
  }

  onRemove(page) {
    const pages = differenceBy(this.state.data.pages, [page], "id");

    this.setState({ data: { ...this.state.data, pages } });
  }

  validate() {
    const { data } = this.state;

    let errors = [];

    if (validator.isEmpty(data.name)) {
      errors.push({ field: "name", message: "Nome é obrigatório" });
    }

    if (validator.isEmpty(data.level + "")) {
      errors.push({
        field: "level",
        message: "Prioridade é obrigatório"
      });
    }

    return errors;
  }

  onSubmit() {
    const errors = this.validate();

    this.setState({ errors });

    if (errors.length === 0) {
      return this.props.submit(this.state.data);
    } else {
      return Promise.reject();
    }
  }

  componentDidMount() {
    const { permission } = this.props;
    if (permission) {
      this.setState({
        data: {
          ...permission
        }
      });
    }
  }

  render() {
    const { data, errors } = this.state;

    const errorsFields = errors.map(e => e.field);

    const pages = differenceBy(this.props.pages, data.pages, "id");

    return (
      <Form onSubmit={this.onSubmit} error={errorsFields.length > 0}>
        <Form.Input
          label="Nome"
          type="text"
          name="name"
          value={data.name}
          onChange={this.onChange}
          placeholder="Nome"
          required
          autoFocus
          error={errorsFields.indexOf("name") !== -1}
        />

        <Form.Dropdown
          label="Páginas"
          name="page"
          selection
          onChange={this.onChangePage}
          placeholder="Selecione uma página"
          options={pages.map(page => ({
            key: page.id,
            text: page.name,
            value: page.id
          }))}
        />

        <label>Páginas com acesso:</label>
        <PagesTable
          pages={data.pages}
          onChangeRule={this.onChangeRule}
          onRemove={this.onRemove}
        />

        <Message error list={errors.map(e => e.message)} />
      </Form>
    );
  }
}

PermissionEditForm.propTypes = {
  submit: PropTypes.func.isRequired,
  machine: PropTypes.shape({
    name: PropTypes.string,
    level: PropTypes.number
  })
};

export default PermissionEditForm;
