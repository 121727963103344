import { createAction } from "redux-actions";

import isEmpty from "lodash/isEmpty";

import {
    FETCH_ALL_REQUEST,
    FETCH_ALL_SUCCESS,
    UPDATE_SUCCESS,
    CREATE_SUCCESS,
    DELETE_SUCCESS,
    ADD_CLICK,
    EDIT_CLICK,
    DELETE_CLICK,
    DELETE_CANCELED,
    EDIT_CANCELED,
    REDUCER_NAME
} from "../constants";

import api from "../../../api";

const fetchAllRequest = createAction(FETCH_ALL_REQUEST);
const fetchAllSuccess = createAction(FETCH_ALL_SUCCESS);

const updateSuccess = createAction(UPDATE_SUCCESS);
const createSuccess = createAction(CREATE_SUCCESS);
const deleteSuccess = createAction(DELETE_SUCCESS);

export const addClick = createAction(ADD_CLICK);
export const editClick = createAction(EDIT_CLICK);
export const deleteClick = createAction(DELETE_CLICK);
export const cancelDelete = createAction(DELETE_CANCELED);
export const cancelEdit = createAction(EDIT_CANCELED);

export const fetchAll = (force = false) => (dispatch, getState) => {
    const machines = getMachines(getState());
    if (!isEmpty(machines) && !force) {
        return dispatch(fetchAllSuccess(machines));
    }

    dispatch(fetchAllRequest());

    return api.machines
        .fetchAll()
        .then(machines => dispatch(fetchAllSuccess(machines)));
};

export const create = data => dispatch => {
    dispatch(fetchAllRequest());

    return api.machines
        .create(data)
        .then(() => dispatch(createSuccess()))
        .then(() => dispatch(fetchAll(true)));
};

export const submitEdit = data => (dispatch, getState) => {
    const editMachine = getEditMachine(getState());

    dispatch(fetchAllRequest());

    let promise = null;
    if (editMachine) {
        promise = api.machines
            .update(editMachine.id, data)
            .then(() => dispatch(updateSuccess()));
    } else {
        promise = api.machines
            .create(data)
            .then(() => dispatch(createSuccess()));
    }

    return promise.then(() => dispatch(fetchAll(true)));
};

export const confirmDelete = () => (dispatch, getState) => {
    const editMachine = getEditMachine(getState());

    if (!editMachine) return;

    dispatch(fetchAllRequest());

    return api.machines
        .delete(editMachine.id)
        .then(() => dispatch(deleteSuccess()))
        .then(() => dispatch(fetchAll(true)));
};

const initialState = {
    machines: [],
    formOpen: false,
    confirmOpen: false,
    editMachine: null,
    loading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_ALL_REQUEST:
            return { ...state, loading: true };
        case FETCH_ALL_SUCCESS:
            return { ...state, loading: false, machines: action.payload };
        case ADD_CLICK:
            return { ...state, formOpen: true, editMachine: null };
        case EDIT_CLICK:
            return { ...state, formOpen: true, editMachine: action.payload };
        case DELETE_CLICK:
            return { ...state, confirmOpen: true, editMachine: action.payload };
        case CREATE_SUCCESS:
            return { ...state, formOpen: false };
        case UPDATE_SUCCESS:
            return { ...state, formOpen: false, editMachine: null };
        case DELETE_SUCCESS:
            return { ...state, confirmOpen: false, editMachine: null };
        case EDIT_CANCELED:
            return { ...state, formOpen: false, editMachine: null };
        case DELETE_CANCELED:
            return { ...state, confirmOpen: false, editMachine: null };
        default:
            return state;
    }
};

export const getLoading = state => state[REDUCER_NAME].loading;
export const getMachines = state => state[REDUCER_NAME].machines;
export const getFormOpen = state => state[REDUCER_NAME].formOpen;
export const getConfirmOpen = state => state[REDUCER_NAME].confirmOpen;
export const getEditMachine = state => state[REDUCER_NAME].editMachine;
