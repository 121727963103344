import React, { Component } from "react";
import { connect } from "react-redux";

import { Form } from "semantic-ui-react";

import {
    getFilter,
    getMachineGroups,
    getMachineGroupsLoading,
    fetchAllMachineGroups,
    changeFilter
} from "../ducks/overview";

class OverviewFilter extends Component {
    componentDidMount() {
        this.props.fetchAllMachineGroups();
    }

    render() {
        const { groups, groupsLoading, filter } = this.props;

        let groupsOptions = groups.map(s => ({
            key: s.id,
            value: s.id,
            text: s.name
        }));
        groupsOptions.unshift({ key: 0, value: 0, text: "Todos" });

        return (
            <Form id="overview_filter">
                <Form.Dropdown
                    loading={groupsLoading}
                    label="Grupos"
                    multiple
                    closeOnChange={true}
                    selection
                    name="groups"
                    onChange={(e, props) => this.props.changeFilter(props)}
                    value={filter.groups}
                    options={groupsOptions}
                />
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {
        filter: getFilter(state),
        groups: getMachineGroups(state),
        groupsLoading: getMachineGroupsLoading(state)
    };
}

export default connect(mapStateToProps, {
    fetchAllMachineGroups,
    changeFilter
})(OverviewFilter);
