import React from "react";
import { connect } from "react-redux";
import { Grid, Header, Dimmer, Loader } from "semantic-ui-react";

import OccurrenceAlertModal from "./OccurrenceAlertModal";
import OccurrenceActivitiesModal from "./OccurrenceActivitiesModal";

import { KanbanCard, ListAudioPlayer } from "../../Shared";
import { getSocketConnected } from "../../Auth";

import {
  getAllOccurrences,
  getWaitingOccurrences,
  getArrivedOccurrences,
  getFinishedOccurrences,
  getLoading,
  getSoundList,
  fetchKanban,
  clearOccurrenceAlert,
  openOccurrenceActivitiesModal,
} from "../ducks/kanban";

const OccurrencesListColumn = ({ occurrences, title, onActivitiesClick }) => (
  <Grid.Column>
    <Header as="h3" dividing>
      {title}
      <Header.Subheader>{occurrences.length} total</Header.Subheader>
    </Header>

    {occurrences.map((occurrence) => (
      <KanbanCard
        occurrence={occurrence}
        key={occurrence.id}
        onActivitiesClick={() => onActivitiesClick(occurrence)}
      />
    ))}
  </Grid.Column>
);

class KanbanPage extends React.Component {
  componentDidMount() {
    this.props.fetchKanban();
  }

  render() {
    const {
      occurrences,
      occurrencesWaiting,
      occurrencesArrived,
      occurrencesFinished,
      soundList,
      loading,
      openOccurrenceActivitiesModal,
    } = this.props;

    return (
      <Grid columns="3">
        <OccurrenceAlertModal />
        <OccurrenceActivitiesModal />

        <Grid.Row style={{ margin: "1rem 0px" }}>
          <Grid.Column>{/* <KanbanFilter /> */}</Grid.Column>
          <Grid.Column textAlign="right" floated="right" width={1}>
            <Loader active={loading} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Dimmer active={loading && occurrences.length === 0} inverted />

          <OccurrencesListColumn
            title="Aguardando atendimento"
            occurrences={occurrencesWaiting}
            onActivitiesClick={openOccurrenceActivitiesModal}
          />

          <OccurrencesListColumn
            title="Em atendimento"
            occurrences={occurrencesArrived}
            onActivitiesClick={openOccurrenceActivitiesModal}
          />

          <OccurrencesListColumn
            title="Finalizado"
            occurrences={occurrencesFinished}
            onActivitiesClick={openOccurrenceActivitiesModal}
          />
        </Grid.Row>

        <ListAudioPlayer sources={soundList} delayMS={0} />
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: getLoading(state),
    occurrences: getAllOccurrences(state),
    occurrencesWaiting: getWaitingOccurrences(state),
    occurrencesArrived: getArrivedOccurrences(state),
    occurrencesFinished: getFinishedOccurrences(state),
    soundList: getSoundList(state),
    socketConnected: getSocketConnected(state),
  };
}

export default connect(mapStateToProps, {
  fetchKanban,
  clearOccurrenceAlert,
  openOccurrenceActivitiesModal,
})(KanbanPage);
