import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Container, Confirm, Header } from "semantic-ui-react";

import get from "lodash/get";

import { getUser } from "../../Auth";
import userHasPage from "../../../utils/userHasPage";

import PermissionTable from "./PermissionTable";
import { FormModal } from "../../Shared";
import PermissionEditForm from "./PermissionEditForm";

import {
  getFormOpen,
  getConfirmOpen,
  getEditPermission,
  getPages,
  fetchAllPages,
  addClick,
  cancelEdit,
  cancelDelete,
  submitEdit,
  confirmDelete
} from "../ducks/permission";

class PermissionsPage extends Component {
  componentDidMount() {
    this.props.fetchAllPages();
  }

  render() {
    const {
      canEdit,
      pages,
      confirmOpen,
      formOpen,
      editPermission
    } = this.props;
    return (
      <Container fluid>
        <Header as="h1">Regras de acesso</Header>

        <Button
          primary
          icon="add"
          content="Nova regra"
          onClick={() => this.props.addClick()}
          id="add_btn"
          disabled={!canEdit}
        />

        <PermissionTable canEdit={canEdit} />

        <FormModal
          size="tiny"
          header="Editar regra de acesso"
          open={formOpen}
          onClose={() => this.props.cancelEdit()}
          formRef={() => this.form}
          id="edit_modal"
        >
          <PermissionEditForm
            ref={form => (this.form = form)}
            submit={data => this.props.submitEdit(data)}
            permission={editPermission}
            pages={pages}
          />
        </FormModal>

        <Confirm
          open={confirmOpen}
          content={`Tem certeza que deseja excluir a regra: ${get(
            editPermission,
            "name"
          )}?`}
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.props.cancelDelete()}
          onConfirm={() => this.props.confirmDelete()}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const user = getUser(state);

  return {
    pages: getPages(state),
    formOpen: getFormOpen(state),
    confirmOpen: getConfirmOpen(state),
    editPermission: getEditPermission(state),
    canEdit: userHasPage(user, "permissions", true)
  };
}

export default connect(mapStateToProps, {
  fetchAllPages,
  addClick,
  cancelDelete,
  cancelEdit,
  submitEdit,
  confirmDelete
})(PermissionsPage);
