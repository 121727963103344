import React from "react";
import { connect } from "react-redux";
import validator from "validator";

import { Form, Message } from "semantic-ui-react";
import { getLoading, sendMessage } from "../ducks/help";

class ContactForm extends React.Component {
  state = {
    data: {
      message: "",
    },
    errors: [],
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e, props) {
    const name = props.name;
    const value = props.value;

    this.setState({ data: { ...this.state.data, [name]: value } });
  }

  validate() {
    const { data } = this.state;

    let errors = [];

    if (validator.isEmpty(data.message)) {
      errors.push({ field: "message", message: "Mensagem não pode ser vazia" });
    }

    return errors;
  }

  onSubmit() {
    const errors = this.validate();

    this.setState({ errors });

    if (errors.length === 0) {
      return this.props.sendMessage(this.state.data);
    } else {
      return Promise.reject();
    }
  }

  render() {
    const { loading } = this.props;
    const { data, errors } = this.state;

    const errorsFields = errors.map((e) => e.field);

    return (
      <Form
        onSubmit={this.onSubmit}
        loading={loading}
        error={errorsFields.length > 0}
      >
        <Form.TextArea
          label="Mensagem"
          name="message"
          value={data.message}
          onChange={this.onChange}
          placeholder="Deixe sua mensagem e nós responderemos via e-mail o mais rápido possível"
          required
          autoFocus
          error={errorsFields.indexOf("message") !== -1}
        />

        <Message error list={errors.map((e) => e.message)} />

        <Form.Button primary>Enviar</Form.Button>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: getLoading(state),
  };
}

export default connect(mapStateToProps, { sendMessage })(ContactForm);
