import React from "react";

import find from "lodash/find";

import { Button, Form } from "semantic-ui-react";

class EditConditionForm extends React.Component {
    state = {
        data: {
            sector_id: 0,
            priority_id: 0,
            machine_id: 0
        }
    };

    onChange = (e, props) => {
        const { name, value } = props;

        this.setState({ data: { ...this.state.data, [name]: value } });
    };

    onSubmit() {
        const { data } = this.state;
        const { sectors, priorities, machines } = this.props;

        const sector = find(sectors, { id: data.sector_id }) || {
            id: 0,
            name: "Todos"
        };
        const priority = find(priorities, { id: data.priority_id }) || {
            id: 0,
            name: "Todos"
        };
        const machine = find(machines, { id: data.machine_id }) || {
            id: 0,
            name: "Todas"
        };

        return this.props.submit({ sector, priority, machine });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data.sector_id !== this.state.data.sector_id) {
            // if changed sector, reset priority selector
            this.setState({
                ...this.state,
                data: { ...this.state.data, priority_id: 0 }
            });
        }
    }

    render() {
        const { data } = this.state;

        const sectorOptions = [
            { key: 0, value: 0, text: "Todos" },
            ...this.props.sectors.map(({ id, name }) => ({
                key: id,
                value: id,
                text: name
            }))
        ];

        const priorityOptions = [
            { key: 0, value: 0, text: "Todos" },
            ...this.props.priorities
                .filter(
                    priority =>
                        data.sector_id === 0 ||
                        data.sector_id === priority.sector_id
                )
                .map(({ id, name }) => ({
                    key: id,
                    value: id,
                    text: name
                }))
        ];

        const machineOptions = [
            { key: 0, value: 0, text: "Todas" },
            ...this.props.machines.map(({ id, name }) => ({
                key: id,
                value: id,
                text: name
            }))
        ];

        return (
            <Form.Group widths="equal" fluid="fluid">
                <Form.Dropdown
                    label="Setor"
                    search
                    selection
                    name="sector_id"
                    onChange={this.onChange}
                    value={data.sector_id}
                    options={sectorOptions}
                />

                <Form.Dropdown
                    label="Nível de escalonamento"
                    selection
                    name="priority_id"
                    onChange={this.onChange}
                    value={data.priority_id}
                    options={priorityOptions}
                    disabled={data.sector_id === 0}
                />

                <Form.Dropdown
                    label="Máquina"
                    search
                    selection
                    name="machine_id"
                    onChange={this.onChange}
                    value={data.machine_id}
                    options={machineOptions}
                />
                <Button
                    primary
                    icon="add"
                    onClick={() => this.onSubmit()}
                    style={{
                        marginTop: "auto",
                        marginBottom: "0",
                        lineHeight: "1.15"
                    }}
                />
            </Form.Group>
        );
    }
}

export default EditConditionForm;
