import React from "react";

import { Card } from "semantic-ui-react";

import "./CardWithMarker.css";

const CardWithMarker = ({ markerColor, className, children, ...rest }) => {
    return (
        <Card className={`withMarker ${className ? className : ""}`} {...rest}>
            <div
                className="marker"
                style={{ backgroundColor: markerColor, ...rest.style }}
            />
            <Card className={`innerCard ${className}`} {...rest}>
                {children}
            </Card>
        </Card>
    );
};

export default CardWithMarker;
