import axios from "axios";

export default {
    create: token => {
        const body = {
            platform: "web",
            fcm_token: token
        };
        return axios
            .post("api/v1/devices", body)
            .then(response => response.data);
    }
};
