export const REDUCER_NAME = "occurrences";

export const OCCURRENCES_FETCHED = "Occurrences/OCCURRENCES_FETCHED";
export const FILTER_CHANGED = "Occurrences/FILTER_CHANGED";
export const SORT_CHANGED = "Occurrences/SORT_CHANGED";
export const PAGE_CHANGED = "Occurrences/PAGE_CHANGED";
export const STATS_LOADING = "Occurrences/STATS_LOADING";
export const OCCURRENCES_LOADING = "Occurrences/OCCURRENCES_LOADING";
export const STATS_FETCHED = "Occurrences/STATS_FETCHED";
export const CLOSE_EDIT = "Occurrences/CLOSE_EDIT";
export const EDIT_CLICK = "Occurrences/EDIT_CLICK";
export const DOWNLOAD_LOADING = "Occurrences/DOWNLOAD_LOADING";
export const DOWNLOAD_DONE = "Occurrences/DOWNLOAD_DONE";
export const DELETE_CLICK = "Occurrences/DELETE_CLICK";
export const DELETE_CONFIRM = "Occurrences/DELETE_CONFIRM";
export const DELETE_CANCELED = "Occurrences/DELETE_CANCELED";
export const DELETE_SUCCESS = "Occurrences/DELETE_SUCCESS";
