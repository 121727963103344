import React from "react";
import PropTypes from "prop-types";

import get from "lodash/get";
import validator from "validator";

import { Divider, Form, Message, Header, Popup, Icon } from "semantic-ui-react";

import EditConditionForm from "./EditConditionForm";
import EditConditionTable from "./EditConditionTable";

class EditForm extends React.Component {
    state = {
        data: {
            name: "",
            conditions: []
        },
        errors: []
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onChange(e, props) {
        const { name, value } = props;

        this.setState({ data: { ...this.state.data, [name]: value } });
    }

    onRemove(key) {
        let conditions = this.state.data.conditions.slice();

        conditions.splice(key, 1);

        this.setState({ data: { ...this.state.data, conditions } });
    }

    handleConditionAdd(condition) {
        const { data } = this.state;

        const conditions = data.conditions.concat([condition]);

        this.setState({ data: { ...data, conditions } });
    }

    validate() {
        const { data } = this.state;

        let errors = [];

        if (validator.isEmpty(data.name)) {
            errors.push({ field: "name", message: "Nome é obrigatório" });
        }

        return errors;
    }

    onSubmit() {
        const errors = this.validate();

        this.setState({ errors });

        if (errors.length === 0) {
            const { data } = this.state;

            return this.props.submit({
                ...data,
                conditions: data.conditions.map(condition => {
                    const sector_id = get(condition, "sector.id", null);
                    const priority_id = get(condition, "priority.id", null);
                    const machine_id = get(condition, "machine.id", null);
                    return {
                        sector_id: sector_id === 0 ? null : sector_id,
                        priority_id: priority_id === 0 ? null : priority_id,
                        machine_id: machine_id === 0 ? null : machine_id
                    };
                })
            });
        } else {
            return Promise.reject();
        }
    }

    componentDidMount() {
        const { rule } = this.props;
        if (rule) {
            this.setState({
                data: { ...rule }
            });
        }
    }

    render() {
        const { data, errors } = this.state;

        const errorsFields = errors.map(e => e.field);

        return (
            <Form error={errorsFields.length > 0}>
                <Form.Input
                    label="Nome"
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={this.onChange}
                    placeholder="Nome"
                    required
                    autoFocus
                    error={errorsFields.indexOf("name") !== -1}
                />

                <Divider />

                <EditConditionForm
                    sectors={this.props.sectors}
                    priorities={this.props.priorities}
                    machines={this.props.machines}
                    submit={data => this.handleConditionAdd(data)}
                />

                <Header as="h4">
                    Condições
                    <Popup
                        trigger={
                            <Icon
                                name="help circle"
                                size="large"
                                color="blue"
                            />
                        }
                        content="O usuário receberá qualquer notificação que respeitar pelo menos uma das condições da tabela abaixo."
                        basic
                    />
                </Header>
                <EditConditionTable
                    conditions={data.conditions}
                    onDeleteClick={key => this.onRemove(key)}
                />

                <Message error list={errors.map(e => e.message)} />
            </Form>
        );
    }
}

EditForm.propTypes = {
    submit: PropTypes.func.isRequired
};

export default EditForm;
