import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { login } from "../ducks/auth";

import LogoSrc from "../../../assets/imgs/go4andon_logo.png";
import LoginForm from "./LoginForm";

const LoginWrapper = styled.div`
  max-width: 670px;
  margin: 16px auto;
  padding-bottom: 48px;

  border-radius: 10px;
  background: #fff;
`;

const LogoWrapper = styled.div`
  width: 100%;
  padding: 48px 0;

  background: #000;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const LoginContent = styled.div`
  max-width: 430px;
  margin: auto;
`;

const LoginLogo = styled.img`
  display: block;
  margin: auto;
  width: 60%;
`;

const LoginFormStyled = styled(LoginForm)`
  padding: 24px;
  padding-bottom: 0;
`;
const LoginLink = styled(Link)`
  text-align: center;
  display: block;
`;

const LoginPage = ({ login }) => (
  <LoginWrapper>
    <LogoWrapper>
      <LoginLogo src={LogoSrc} alt="GO4 Andon" />
    </LogoWrapper>

    <LoginContent>
      <LoginFormStyled submit={login} />
      <LoginLink to="/signup">Não possui uma conta? Crie a sua aqui.</LoginLink>
    </LoginContent>
  </LoginWrapper>
);

export default connect(null, { login })(LoginPage);
