import React, { Component } from "react";
import { connect } from "react-redux";

import { Form } from "semantic-ui-react";

import get from "lodash/get";

import {
    getSectors,
    getLoading as getLoadingSectors,
    fetchAll as fetchAllSectors
} from "../../Sectors";

import { getFilter, changeFilter } from "../ducks/priorities";

class PrioritiesFilter extends Component {
    constructor(props) {
        super(props);

        this.renderLabel = this.renderLabel.bind(this);
    }

    componentDidMount() {
        this.props.fetchAllSectors();
    }

    renderLabel(item, index, defaultLabelProps) {
        return {
            color: get(item, "label.color"),
            content: item.text
        };
    }

    render() {
        const { sectors, sectorsLoading, filter } = this.props;

        const sectorsOptions = [
            { key: 0, value: 0, text: "Todos" },
            ...sectors.map(s => ({
                label: { color: s.color },
                key: s.id,
                value: s.id,
                text: s.name
            }))
        ];

        return (
            <Form id="kanban_filter">
                <Form.Dropdown
                    loading={sectorsLoading}
                    label="Setores"
                    closeOnChange={true}
                    selection
                    name="sector_id"
                    onChange={(e, { name, value }) =>
                        this.props.changeFilter({ name, value })
                    }
                    value={filter.sector_id}
                    renderLabel={this.renderLabel}
                    options={sectorsOptions}
                />
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {
        sectors: getSectors(state),
        sectorsLoading: getLoadingSectors(state),
        filter: getFilter(state)
    };
}

function mapDispatchProps(dispatch) {
    return {
        fetchAllSectors: () => dispatch(fetchAllSectors()),
        changeFilter: data => dispatch(changeFilter(data))
    };
}

export default connect(mapStateToProps, mapDispatchProps)(PrioritiesFilter);
