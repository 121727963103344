import React from "react";
import PropTypes from "prop-types";

import { Icon, Message, Form } from "semantic-ui-react";
import validator from "validator";

import { extractErrors } from "../../../utils/apiHelpers";
import { gtag_report_conversion } from "../../../utils/ads";

import { MaskedNumberInput } from "../../Shared";

const GENERIC_API_ERROR = {
  field: "",
  message:
    "Erro no sistema, por favor tente novamente. Caso o problema persista entre em contato com o suporte em contato@go4andon.com.br",
};

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        company_name: "",
        user_name: "",
        user_email: "",
        cellphone: "",
        password: "",
        password_confirmation: "",
        terms_of_use: false,
      },
      loading: false,
      errors: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  validate(data) {
    let errors = [];

    if (validator.isEmpty(data.user_name)) {
      errors.push({
        field: "user_name",
        message: "Nome completo é obrigatório",
      });
    }

    if (validator.isEmpty(data.company_name)) {
      errors.push({
        field: "company_name",
        message: "Nome da empresa é obrigatório",
      });
    }

    if (!validator.isEmail(data.user_email)) {
      errors.push({ field: "user_email", message: "Email inválido" });
    }

    if (validator.isEmpty(data.password)) {
      errors.push({ field: "password", message: "Senha é obrigatória" });
    }

    if (!validator.equals(data.password, data.password_confirmation)) {
      errors.push({
        field: "password_confirmation",
        message: "Senhas não conferem",
      });
    }

    if (!validator.isMobilePhone(data.cellphone, "pt-BR")) {
      errors.push({
        field: "cellphone",
        message: "Número de celular não é válido",
      });
    }

    if (!data.terms_of_use) {
      errors.push({
        field: "terms_of_use",
        message: "O aceite dos termos é obrigatório",
      });
    }

    return errors;
  }

  onChange(e, props) {
    const name = props.name;
    const value = props.checked !== undefined ? props.checked : props.value;

    this.setState({
      data: { ...this.state.data, [name]: value },
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const errors = this.validate(this.state.data);

    if (errors.length === 0) {
      this.setState({ loading: true, errors: [] });

      gtag_report_conversion();

      return this.props.submit(this.state.data).catch((error) => {
        let apiErrors = extractErrors(error.response, GENERIC_API_ERROR);

        this.setState({ loading: false, errors: apiErrors });
      });
    } else {
      this.setState({ errors });
      return Promise.reject();
    }
  }

  render() {
    const { className } = this.props;
    const { data, loading, errors } = this.state;

    const errorsFields = errors.map((e) => e.field);

    return (
      <Form
        style={{ padding: 0 }}
        onSubmit={this.onSubmit}
        loading={loading}
        error={errors.length > 0}
        className={className}
      >
        <Form.Input
          label="Nome completo"
          type="text"
          name="user_name"
          id="user_name"
          value={data.user_name}
          onChange={this.onChange}
          placeholder="Nome completo"
          required
          autoFocus
          error={errorsFields.indexOf("user_name") !== -1}
        />

        <Form.Input
          label="Nome da empresa"
          type="text"
          name="company_name"
          id="company_name"
          value={data.company_name}
          onChange={this.onChange}
          placeholder="Nome da empresa"
          required
          error={errorsFields.indexOf("company_name") !== -1}
        />

        <Form.Input
          label="Email"
          type="email"
          name="user_email"
          id="user_email"
          value={data.user_email}
          onChange={this.onChange}
          placeholder="Email"
          required
          error={errorsFields.indexOf("user_email") !== -1}
        />

        <Form.Field required error={errorsFields.indexOf("cellphone") !== -1}>
          <label>
            WhatsApp <Icon name="whatsapp" />
          </label>
          <MaskedNumberInput
            name="cellphone"
            id="cellphone"
            placeholder="WhatsApp"
            format="(##) #####-####"
            mask="_"
            type="phone"
            onChange={this.onChange}
          />
        </Form.Field>

        <Form.Input
          label="Senha"
          type="password"
          id="password"
          name="password"
          value={data.password}
          onChange={this.onChange}
          className="form-control"
          placeholder="Senha"
          required
          error={errorsFields.indexOf("password") !== -1}
        />

        <Form.Input
          label="Confirmar senha"
          type="password"
          id="password_confirmation"
          name="password_confirmation"
          value={data.password_confirmation}
          onChange={this.onChange}
          className="form-control"
          placeholder="Confirmar senha"
          required
          error={errorsFields.indexOf("password_confirmation") !== -1}
        />

        <Form.Checkbox
          label={
            <label>
              Eu li e concordo com os{" "}
              <a href="termos-de-uso.pdf" target="_blank">
                Termos de Uso
              </a>{" "}
              e a{" "}
              <a href="politica-privacidade.pdf" target="_blank">
                Política de Privacidade
              </a>
            </label>
          }
          name="terms_of_use"
          id="terms-of-use"
          checked={data.terms_of_use}
          onChange={this.onChange}
          required
          error={errorsFields.indexOf("terms_of_use") !== -1}
        />

        <Form.Button
          primary
          style={{
            width: "100%",
            maxWidth: "240px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Cadastrar
        </Form.Button>

        <Message error list={errors.map((e) => e.message)} />
      </Form>
    );
  }
}

SignupForm.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default SignupForm;
