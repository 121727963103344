export const KANBAN_FILTER_CHANGED = "Kanban/KANBAN_FILTER_CHANGED";
export const KANBAN_FETCHED = "Kanban/KANBAN_FETCHED";
export const KANBAN_FETCHING = "Kanban/KANBAN_FETCHING";
export const KANBAN_UPDATED = "Kanban/KANBAN_UPDATED";
export const KANBAN_FILTER_SET = "Kanban/KANBAN_FILTER_SET";
export const KANBAN_ALERT = "Kanban/KANBAN_ALERT";
export const KANBAN_ALERT_CLEAR = "Kanban/KANBAN_ALERT_CLEAR";
export const KANBAN_OPEN_ACTIVITIES_MODAL =
  "Kanban/KANBAN_OPEN_ACTIVITIES_MODAL";
export const KANBAN_CLOSE_ACTIVITIES_MODAL =
  "Kanban/KANBAN_CLOSE_ACTIVITIES_MODAL";

export const REDUCER_NAME = "kanban";
