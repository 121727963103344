import React from "react";
import { Card } from "semantic-ui-react";
import styled from "styled-components";

import {
  ScrollableContainer,
  OccurrenceActivities,
  useOccurrenceActivities,
} from "../../Shared";

import OccurrenceActivityForm from "./OccurrenceActivityForm";

const ActivitiesContent = styled(Card.Content)`
  &&& {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 300px;

    background-color: rgb(248, 248, 249);
  }
`;

const ActivitiesContainer = styled(ScrollableContainer)`
  margin: 6px 0;
`;

const CallTechnicianOccurrenceActivities = ({ occurrenceId }) => {
  const { activities, submitting, submitActivity } = useOccurrenceActivities(
    occurrenceId
  );

  return (
    <ActivitiesContent>
      <h3>Registro de atividades</h3>

      <ActivitiesContainer>
        <OccurrenceActivities activities={activities} />
      </ActivitiesContainer>

      <OccurrenceActivityForm loading={submitting} onSubmit={submitActivity} />
    </ActivitiesContent>
  );
};

export default CallTechnicianOccurrenceActivities;
