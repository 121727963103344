import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { USER_ROUTES } from "../../../routes";
import userHasPage from "../../../utils/userHasPage";

import { getUser } from "../../Auth";

class DashboardPage extends Component {
  render() {
    const { user } = this.props;
    const route = USER_ROUTES.find(
      route => userHasPage(user, route.slug) && route.path !== "/dashboard"
    );

    return <div>{!!route && <Redirect to={{ pathname: route.path }} />}</div>;
  }
}

function mapStateToProps(state) {
  return {
    user: getUser(state)
  };
}

export default connect(mapStateToProps)(DashboardPage);
