import React, { Component } from "react";
import PropTypes from "prop-types";

import { Table, Dimmer, Loader, Button, Container } from "semantic-ui-react";

import get from "lodash/get";

import { SortableTableHeader } from "../../Shared";

import { secondsToString } from "../../../utils/timeToString";

const HEADERS = [
  { name: null, label: "Nome" },
  { name: null, label: "Setor" },
  { name: null, label: "Alerta" },
  { name: null, label: "Tempo máximo para chegada" },
  { name: null, label: "Tempo máximo para atendimento" },
  { name: null, label: "Ações" }
];

const TableBody = ({
  priorities,
  onArrowUp,
  onArrowDown,
  onEdit,
  onDelete,
  canEdit
}) => (
  <Table.Body>
    {priorities.map((priority, key) => (
      <Table.Row key={priority.id}>
        <Table.Cell>{priority.name}</Table.Cell>
        <Table.Cell>{get(priority, "sector.name")}</Table.Cell>
        <Table.Cell>{get(priority.sound, "name", "")}</Table.Cell>
        <Table.Cell>{secondsToString(priority.started_timeout_s)}</Table.Cell>
        <Table.Cell>{secondsToString(priority.arrived_timeout_s)}</Table.Cell>
        <Table.Cell width={4}>
          <Button.Group basic style={{ marginRight: "20px" }}>
            <Button
              icon="arrow up"
              basic
              disabled={!canEdit || key === 0}
              onClick={() => onArrowUp(priority)}
            />
            <Button
              icon="arrow down"
              basic
              disabled={!canEdit || key === priorities.length - 1}
              onClick={() => onArrowDown(priority)}
            />
          </Button.Group>

          <Button
            basic
            icon="edit"
            onClick={() => onEdit(priority)}
            style={{ marginRight: "20px" }}
          />
          <Button
            basic
            icon="delete"
            onClick={() => onDelete(priority)}
            style={{ marginRight: "20px" }}
          />
        </Table.Cell>
      </Table.Row>
    ))}
  </Table.Body>
);

class PriorityTable extends Component {
  render() {
    const { priorities, loading, canEdit } = this.props;
    return (
      <Dimmer.Dimmable as={Container} dimmed={loading} fluid>
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>

        <Table selectable>
          <SortableTableHeader headers={HEADERS} />
          <TableBody
            canEdit={canEdit}
            priorities={priorities}
            onArrowUp={this.props.onArrowUp}
            onArrowDown={this.props.onArrowDown}
            onEdit={this.props.onEditClick}
            onDelete={this.props.onDeleteClick}
          />
        </Table>
      </Dimmer.Dimmable>
    );
  }
}

PriorityTable.propTypes = {
  priorities: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onAddClick: PropTypes.func.isRequired,
  onArrowUp: PropTypes.func.isRequired,
  onArrowDown: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired
};

PriorityTable.defaultProps = {
  priorities: [],
  loading: false,
  onAddClick: () => {},
  onEditClick: () => {},
  onArrowUp: () => {},
  onArrowDown: () => {}
};

export default PriorityTable;
