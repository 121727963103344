import React from "react";

import get from "lodash/get";
import find from "lodash/find";

import { Card, Icon, Popup } from "semantic-ui-react";

import { KanbanCard } from "../../Shared";

import { SECTOR_COLORS, OCCURRENCE_STATUS as STATUS } from "../../../constants";

class OverviewMachineCard extends React.Component {
    renderMachineCard() {
        const { machine } = this.props;
        const status = get(machine, "last_occurrence.status", STATUS.FINISHED);

        let backgroundColor = undefined;
        let iconName = "check circle";
        if (status === STATUS.WAITING || status === STATUS.ATTENDING) {
            const color = get(machine.last_occurrence, "sector.color");
            backgroundColor = get(find(SECTOR_COLORS, { color }), "rgb");

            if (status === STATUS.WAITING) {
                iconName = "exclamation triangle";
            } else {
                iconName = "wrench";
            }
        }

        return (
            <Card
                key={machine.id}
                style={{
                    width: "auto",
                    fontSize: "1.2em",
                    backgroundColor
                }}
                className={
                    status === STATUS.WAITING ? "overview-waiting-state" : ""
                }
            >
                <Card.Content>
                    <Card.Header>
                        <Icon name={iconName} /> {machine.name}
                    </Card.Header>
                </Card.Content>
            </Card>
        );
    }

    renderWithOccurrence() {
        const { machine } = this.props;

        let occurrenceWithMachine = machine.last_occurrence;
        occurrenceWithMachine.machine = machine;

        return (
            <Popup
                trigger={this.renderMachineCard()}
                style={{ padding: "0", minWidth: "300px" }}
                wide
            >
                <KanbanCard
                    occurrence={occurrenceWithMachine}
                    style={{
                        border: "none",
                        boxShadow: "none",
                        animation: "none"
                    }}
                />
            </Popup>
        );
    }

    renderWithoutOccurrence() {
        return (
            <Popup
                trigger={this.renderMachineCard()}
                content="Sem chamado ativo"
                style={{ minWidth: "300px" }}
                wide
            />
        );
    }

    render() {
        const { machine } = this.props;
        const status = get(machine, "last_occurrence.status", STATUS.FINISHED);

        return status === STATUS.WAITING || status === STATUS.ATTENDING
            ? this.renderWithOccurrence()
            : this.renderWithoutOccurrence();
    }
}

export default OverviewMachineCard;
