import { createAction } from "redux-actions";

import isEmpty from "lodash/isEmpty";

import {
    FETCH_ALL_REQUEST,
    FETCH_ALL_SUCCESS,
    FETCH_ALL_PAGES_SUCCESS,
    UPDATE_SUCCESS,
    CREATE_SUCCESS,
    DELETE_SUCCESS,
    ADD_CLICK,
    EDIT_CLICK,
    DELETE_CLICK,
    DELETE_CANCELED,
    EDIT_CANCELED,
    REDUCER_NAME
} from "../constants";

import api from "../../../api";

const fetchAllRequest = createAction(FETCH_ALL_REQUEST);
const fetchAllSuccess = createAction(FETCH_ALL_SUCCESS);
const fetchAllPagesSuccess = createAction(FETCH_ALL_PAGES_SUCCESS);

const updateSuccess = createAction(UPDATE_SUCCESS);
const createSuccess = createAction(CREATE_SUCCESS);
const deleteSuccess = createAction(DELETE_SUCCESS);

export const addClick = createAction(ADD_CLICK);
export const editClick = createAction(EDIT_CLICK);
export const deleteClick = createAction(DELETE_CLICK);
export const cancelDelete = createAction(DELETE_CANCELED);
export const cancelEdit = createAction(EDIT_CANCELED);

export const fetchAll = (force = false) => (dispatch, getState) => {
    const permissions = getPermissions(getState());
    if (!isEmpty(permissions) && !force) {
        return dispatch(fetchAllSuccess(permissions));
    }

    dispatch(fetchAllRequest());

    return api.permissions
        .fetchAll()
        .then(permissions => dispatch(fetchAllSuccess(permissions)));
};

export const fetchAllPages = (force = false) => (dispatch, getState) => {
    const pages = getPages(getState());
    if (!isEmpty(pages) && !force) {
        return dispatch(fetchAllPagesSuccess(pages));
    }

    dispatch(fetchAllRequest());

    return api.permissions
        .fetchAllPages()
        .then(pages => dispatch(fetchAllPagesSuccess(pages)));
};

export const create = data => dispatch => {
    dispatch(fetchAllRequest());

    return api.permissions
        .create(data)
        .then(() => dispatch(createSuccess()))
        .then(() => dispatch(fetchAll(true)));
};

export const submitEdit = data => (dispatch, getState) => {
    const editPermission = getEditPermission(getState());

    dispatch(fetchAllRequest());

    let promise = null;
    if (editPermission) {
        promise = api.permissions
            .update(editPermission.id, data)
            .then(() => dispatch(updateSuccess()));
    } else {
        promise = api.permissions
            .create(data)
            .then(() => dispatch(createSuccess()));
    }

    return promise.then(() => dispatch(fetchAll(true)));
};

export const confirmDelete = () => (dispatch, getState) => {
    const editPermission = getEditPermission(getState());

    if (!editPermission) return;

    dispatch(deleteSuccess());
    dispatch(fetchAllRequest());

    return api.permissions
        .delete(editPermission.id)
        .then(() => dispatch(fetchAll(true)));
};

const initialState = {
    permissions: [],
    pages: [],
    formOpen: false,
    confirmOpen: false,
    editPermission: null,
    loading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_ALL_REQUEST:
            return { ...state, loading: true };
        case FETCH_ALL_SUCCESS:
            return { ...state, loading: false, permissions: action.payload };
        case FETCH_ALL_PAGES_SUCCESS:
            return { ...state, loading: false, pages: action.payload };
        case ADD_CLICK:
            return { ...state, formOpen: true, editPermission: null };
        case EDIT_CLICK:
            return { ...state, formOpen: true, editPermission: action.payload };
        case DELETE_CLICK:
            return {
                ...state,
                confirmOpen: true,
                editPermission: action.payload
            };
        case CREATE_SUCCESS:
            return { ...state, formOpen: false };
        case UPDATE_SUCCESS:
            return { ...state, formOpen: false, editPermission: null };
        case DELETE_SUCCESS:
            return { ...state, confirmOpen: false, editPermission: null };
        case EDIT_CANCELED:
            return { ...state, formOpen: false, editPermission: null };
        case DELETE_CANCELED:
            return { ...state, confirmOpen: false, editPermission: null };
        default:
            return state;
    }
};

export const getLoading = state => state[REDUCER_NAME].loading;
export const getPermissions = state => state[REDUCER_NAME].permissions;
export const getPages = state => state[REDUCER_NAME].pages;
export const getFormOpen = state => state[REDUCER_NAME].formOpen;
export const getConfirmOpen = state => state[REDUCER_NAME].confirmOpen;
export const getEditPermission = state => state[REDUCER_NAME].editPermission;
