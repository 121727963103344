import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

export default ({sector}) => {
    if(!sector) return null;

    return (
        <Segment inverted={true} color={sector.color} textAlign="center">
            <Header style={{fontSize: "6em"}}>{sector.name}</Header>
        </Segment>
    );
}
