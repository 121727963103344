import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";
import {
  Button,
  Icon,
  Container,
  Table,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import {
  getOrder,
  getOrderBy,
  getMeta,
  getPage,
  getOccurrences,
  getLoading,
  getParams,
  sortChanged,
  pageChanged,
  editClick,
  deleteClick,
  fetchAllOccurrences,
} from "../ducks/occurrences";

import { SortableTableHeader, PaginatedTableFooter } from "../../Shared";
import { secondsToString } from "../../../utils/timeToString";

const HEADERS = [
  { name: "started_on", label: "Data" },
  { name: "machine_name", label: "Máquina" },
  { name: "sector_name", label: "Setor" },
  { name: "waiting_time", label: "T. de espera" },
  { name: "attending_time", label: "T. de atendimento" },
  { name: "priority_level", label: "Escalonamento" },
  { name: null, label: "Observação" },
  { name: null, label: "Ações" },
];

class OccurrenceTable extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.params !== prevProps.params) {
      this.props.fetchAllOccurrences(this.props.params);
    }
  }

  componentDidMount() {
    this.props.fetchAllOccurrences(this.props.params);
  }

  renderTableBody() {
    const { occurrences, canEdit } = this.props;
    return (
      <Table.Body>
        {occurrences.map((occurrence) => (
          <Table.Row key={occurrence.id} error={occurrence.isCanceled()}>
            <Table.Cell>
              {moment(occurrence.started_on).format("DD/MM/YYYY - HH:mm:ss")}
            </Table.Cell>
            <Table.Cell>{occurrence.getMachineName()}</Table.Cell>
            <Table.Cell>{occurrence.getSectorName()}</Table.Cell>
            <Table.Cell>{secondsToString(occurrence.waiting_time)}</Table.Cell>
            <Table.Cell>
              {secondsToString(occurrence.attending_time)}
            </Table.Cell>
            <Table.Cell>{occurrence.getPriorityName()}</Table.Cell>
            <Table.Cell
              style={{
                maxWidth: "200px",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {occurrence.observation}
            </Table.Cell>
            <Table.Cell>
              <Button
                icon
                basic
                disabled={!canEdit}
                onClick={() => this.props.editClick(occurrence)}
                style={{ marginRight: "20px" }}
              >
                <Icon name="edit" />
              </Button>
              <Button
                icon
                basic
                disabled={!canEdit}
                onClick={() => this.props.deleteClick(occurrence)}
                style={{ marginRight: "20px" }}
              >
                <Icon name="delete" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  render() {
    const { loading, meta, order, order_by } = this.props;

    return (
      <Dimmer.Dimmable as={Container} dimmed={loading} fluid>
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        <Table selectable sortable>
          <SortableTableHeader
            headers={HEADERS}
            sortChanged={this.props.sortChanged}
            column={order_by}
            direction={order}
          />

          {this.renderTableBody()}

          <PaginatedTableFooter
            meta={meta}
            totalLabel="Total de chamado(s)"
            columns={HEADERS.length}
            onPageClick={this.props.pageChanged}
          />
        </Table>
      </Dimmer.Dimmable>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: getOrder(state),
    order_by: getOrderBy(state),
    page: getPage(state),
    meta: getMeta(state),
    occurrences: getOccurrences(state),
    loading: getLoading(state),
    params: getParams(state),
  };
}

export default connect(mapStateToProps, {
  sortChanged,
  pageChanged,
  editClick,
  fetchAllOccurrences,
  deleteClick,
})(OccurrenceTable);
