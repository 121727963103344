import React from "react";

import { Table } from "semantic-ui-react";

export default ({ headers, column, direction, sortChanged }) => (
    <Table.Header>
        <Table.Row>
            {headers.map(({ name, label }, key) => (
                <Table.HeaderCell
                    key={key}
                    sorted={column === name ? direction : null}
                    onClick={() => sortChanged ? sortChanged(name) : null}
                >
                    {label}
                </Table.HeaderCell>
            ))}
        </Table.Row>
    </Table.Header>
);
