import React from "react";
import { connect } from "react-redux";

import get from "lodash/get";

import { Button, Confirm, Container, Header } from "semantic-ui-react";

import { getSectors, fetchAll as fetchAllSectors } from "../../Sectors";

import {
  fetchAll as fetchAllPriorities,
  getPriorities
} from "../../Priorities";

import { fetchAll as fetchAllMachines, getMachines } from "../../Machines";
import {
  getEditRule,
  getFormOpen,
  getConfirmOpen,
  addClick,
  submitForm,
  confirmDelete,
  cancelDelete,
  cancelEdit
} from "../ducks/notification-rules";

import { getUser } from "../../Auth";
import userHasPage from "../../../utils/userHasPage";

import { FormModal } from "../../Shared";

import TableContainer from "./TableContainer";
import EditForm from "./EditForm";

class NotificationRulesPage extends React.Component {
  state = { formOpen: false };

  componentDidMount() {
    this.props.fetchAllSectors();
    this.props.fetchAllPriorities();
    this.props.fetchAllMachines();
  }

  render() {
    const { canEdit, confirmOpen, formOpen, editRule } = this.props;

    return (
      <Container fluid>
        <Header>Regras de Notificação</Header>
        <Button
          primary
          icon="add"
          content="Adicionar regra"
          onClick={() => this.props.addClick()}
          disabled={!canEdit}
        />
        <TableContainer />

        <FormModal
          open={formOpen}
          onClose={() => this.props.cancelEdit()}
          header={"Regra de notificação"}
          formRef={() => this.editForm}
        >
          <EditForm
            rule={editRule}
            submit={data => this.props.submitForm(data)}
            ref={form => (this.editForm = form)}
            sectors={this.props.sectors}
            priorities={this.props.priorities}
            machines={this.props.machines}
          />
        </FormModal>

        <Confirm
          open={confirmOpen}
          content={`Tem certeza que deseja excluir a regra: ${get(
            editRule,
            "name"
          )} ?`}
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.props.cancelDelete()}
          onConfirm={() => this.props.confirmDelete()}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    canEdit: userHasPage(getUser(state), "notification-rules", true),
    editRule: getEditRule(state),
    formOpen: getFormOpen(state),
    confirmOpen: getConfirmOpen(state),
    sectors: getSectors(state),
    priorities: getPriorities(state),
    machines: getMachines(state)
  };
}

export default connect(mapStateToProps, {
  addClick,
  submitForm,
  confirmDelete,
  cancelDelete,
  cancelEdit,
  fetchAllSectors,
  fetchAllPriorities,
  fetchAllMachines
})(NotificationRulesPage);
