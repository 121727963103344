module.exports.CACHE_BUST_MAGICCODE = "2rXkP";

module.exports.OCCURRENCE_STATUS = {
  FINISHED: "FINISHED",
  WAITING: "WAITING",
  ATTENDING: "ATTENDING",
  CANCELED: "CANCELED",
};

module.exports.USER_TYPES = {
  ADMIN: 0,
  NORMAL: 1,
};

module.exports.MENU_ITENS = [
  {
    label: "Chamar Técnico",
    to: "/call-technician",
    slug: "call-technician",
  },
  {
    label: "Kanban",
    to: "/kanban",
    slug: "kanban",
    customProps: ({ hasWaitingOccurrences }) =>
      hasWaitingOccurrences ? { className: "menu-alarm-state" } : {},
  },
  {
    label: "Configurações",
    to: "",
    slug: "",
    subMenu: [
      {
        label: "Máquinas",
        to: "/machines",
        slug: "machines",
      },
      {
        label: "Setores",
        to: "/sectors",
        slug: "sectors",
      },
      {
        label: "Usuários",
        to: "/users",
        slug: "users",
      },
      {
        label: "Níveis de escalonamento",
        to: "/priorities",
        slug: "priorities",
      },
      {
        label: "Regras de acesso",
        to: "/permissions",
        slug: "permissions",
      },
      {
        label: "Regras de notificações",
        to: "/notification-rules",
        slug: "notification-rules",
      },
      {
        label: "Regras de filtro",
        to: "/filter-rules",
        slug: "filter-rules",
      },
    ],
  },
  {
    label: "Histórico",
    to: "/occurrences",
    slug: "occurrences",
  },
  {
    label: "Visão Geral",
    to: "/overview",
    slug: "overview",
  },
];

module.exports.SECTOR_COLORS = [
  { color: "red", text: "vermelho", rgb: "#ffecec" },
  { color: "orange", text: "laranja", rgb: "#fbd3bb" },
  { color: "yellow", text: "amarelo", rgb: "#feeab7" },
  { color: "green", text: "verde", rgb: "#bce9c6" },
  { color: "blue", text: "azul", rgb: "#bcd9ef" },
  { color: "violet", text: "violeta", rgb: "#cfc2ee" },
  { color: "purple", text: "roxo", rgb: "#e1c1ed" },
  { color: "pink", text: "rosa", rgb: "#f5c2de" },
  { color: "brown", text: "marrom", rgb: "#e2d0c4" },
  { color: "grey", text: "cinza", rgb: "#d4d4d4" },
  { color: "black", text: "preto", rgb: "#bbbcbb" },
];

module.exports.SECTORS = [{ id: 0, name: "Manutenção" }];

module.exports.MACHINE_LEVEL_OPTIONS = [
  {
    text: "Baixa",
    value: 0,
  },
  {
    text: "Média",
    value: 1,
  },
  {
    text: "Alta",
    value: 2,
  },
];
