import React from "react";
import PropTypes from "prop-types";

import validator from "validator";

import { Message, Form } from "semantic-ui-react";

class MachineGroupEditForm extends React.Component {
    state = {
        data: {
            name: ""
        },
        errors: []
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = (e, props) => {
        const { name, value } = props;

        this.setState({
            data: { ...this.state.data, [name]: value }
        });
    };

    validate = () => {
        const { data } = this.state;

        let errors = [];

        // If user is new instead of updating, check for password validation
        if (validator.isEmpty(data.name)) {
            errors.push({
                field: "name",
                message: "Nome é obrigatório"
            });
        }

        return errors;
    };

    componentDidMount() {
        const { machineGroup } = this.props;
        if (machineGroup) {
            this.setState({
                data: {
                    id: machineGroup.id,
                    name: machineGroup.name
                }
            });
        }
    }

    onSubmit = () => {
        const errors = this.validate();

        this.setState({ errors });

        if (errors.length === 0) {
            return this.props.submit(this.state.data);
        } else {
            return Promise.reject();
        }
    };

    render() {
        const { data, errors } = this.state;

        const errorsFields = errors.map(e => e.field);

        return (
            <Form onSubmit={this.onSubmit} error={errorsFields.length > 0}>
                <Form.Input
                    label="Nome"
                    name="name"
                    value={data.name}
                    onChange={this.onChange}
                    placeholder="Nome"
                    required
                    error={errorsFields.indexOf("name") !== -1}
                />

                <Message error list={errors.map(e => e.message)} />
            </Form>
        );
    }
}

MachineGroupEditForm.propTypes = {
    submit: PropTypes.func.isRequired
};

export default MachineGroupEditForm;
