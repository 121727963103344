import React from "react";

export function chatInit() {
  window.hsConversationsSettings = { loadImmediately: false };
  const script = document.createElement("script");
  script.id = "hs-script-loader";
  script.async = true;
  script.defer = true;
  script.src = "//js.hs-scripts.com/8327171.js";
  window.document.body.appendChild(script);
}

const openChat = () => {
  if (!window.HubSpotConversations) {
    return;
  }

  window.HubSpotConversations.widget.load();
};

const removeChat = () => {
  if (!window.HubSpotConversations) {
    return;
  }

  window.HubSpotConversations.widget.remove();
};

export const withChat = (WrappedComponent) => {
  const HOC = class extends React.Component {
    componentDidMount() {
      openChat();
    }

    componentWillUnmount() {
      removeChat();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};
