import { createAction } from "redux-actions";
import { toast } from "react-toastify";

import {
  REDUCER_NAME,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
} from "../constants";

import api from "../../../api";

const sendMessageSuccess = createAction(SEND_MESSAGE_SUCCESS);
const sendMessageError = createAction(SEND_MESSAGE_ERROR);

export const sendMessage = (data) => (dispatch) => {
  dispatch({ type: SEND_MESSAGE });

  return api.contact
    .sendMessage(data)
    .then(() => {
      toast.success("Mensagem enviada com sucesso!");
      dispatch(sendMessageSuccess());
    })
    .catch(() => {
      toast.error(
        "Falha ao enviar mensagem, se o problema persistir envie um email para contato@go4andon.com.br!"
      );
      dispatch(sendMessageError());
    });
};

const initialState = {
  loading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SEND_MESSAGE:
      return { ...state, loading: true };
    case SEND_MESSAGE_SUCCESS:
    case SEND_MESSAGE_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getLoading = (state) => state[REDUCER_NAME].loading;
