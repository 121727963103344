import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Confirm } from "semantic-ui-react";

import get from "lodash/get";

import { FormModal } from "../../Shared";
import SectorEditForm from "./SectorEditForm";

import SectorTable from "./SectorTable";

import { getUser } from "../../Auth";
import userHasPage from "../../../utils/userHasPage";

import {
  getSectors,
  getLoading,
  fetchAll,
  createSector,
  updateSector,
  deleteSector
} from "../ducks/sector";

class SectorsPage extends Component {
  state = {
    editOpen: false,
    deleteConfirmationOpen: false
  };

  constructor(props) {
    super(props);

    this.onAddClick = this.onAddClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
  }

  onAddClick() {
    this.setState({ selectedSector: undefined, editOpen: true });
  }

  onEditClick(sector) {
    this.setState({ selectedSector: sector, editOpen: true });
  }

  onDeleteClick(sector) {
    this.setState({ selectedSector: sector, deleteConfirmationOpen: true });
  }

  onModalClose() {
    this.setState({ editOpen: false });
  }

  onSubmit(data) {
    const { selectedSector } = this.state;

    let promise = selectedSector
      ? this.props.updateSector(selectedSector.id, data)
      : this.props.createSector(data);

    return promise.then(() => this.onModalClose()).then(() => this.update());
  }

  handleDeleteConfirm() {
    this.setState({ deleteConfirmationOpen: false });

    const { selectedSector } = this.state;
    return this.props.deleteSector(selectedSector.id).then(() => this.update());
  }

  update() {
    return this.props.fetchAll(true);
  }

  componentDidMount() {
    this.update();
  }

  render() {
    const { editOpen, selectedSector } = this.state;
    const { sectors, loading, canEdit } = this.props;

    return (
      <Container fluid>
        <SectorTable
          canEdit={canEdit}
          sectors={sectors}
          loading={loading}
          onEditClick={this.onEditClick}
          onAddClick={this.onAddClick}
          onDeleteClick={this.onDeleteClick}
        />

        <FormModal
          id="edit_modal"
          size="small"
          open={editOpen}
          onClose={this.onModalClose}
          header={selectedSector ? "Editar Setor" : "Novo Setor"}
          formRef={() => this.editForm}
        >
          <SectorEditForm
            sector={selectedSector}
            ref={form => (this.editForm = form)}
            submit={this.onSubmit}
          />
        </FormModal>

        <Confirm
          open={this.state.deleteConfirmationOpen}
          content={`Tem certeza que deseja excluir o setor: ${get(
            selectedSector,
            "name"
          )}?`}
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.setState({ deleteConfirmationOpen: false })}
          onConfirm={this.handleDeleteConfirm}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const user = getUser(state);

  return {
    canEdit: userHasPage(user, "sectors", true),
    sectors: getSectors(state),
    loading: getLoading(state)
  };
}

export default connect(mapStateToProps, {
  fetchAll,
  createSector,
  updateSector,
  deleteSector
})(SectorsPage);
