import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectsStart: true,
      selectsEnd: false,
      selected: null,
      startDate: null,
      endDate: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.openCalendar = this.openCalendar.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
  }

  handleChange(date) {
    if (this.state.selectsStart) {
      this.setState({
        selected: date,
        startDate: date,
        selectsStart: false,
        selectsEnd: true,
      });
    } else {
      this.setState(
        {
          open: false,
          selected: date,
          endDate: date,
          selectsStart: true,
          selectsEnd: false,
        },
        () => {
          this.props.onRangePicked &&
            this.props.onRangePicked(this.state.startDate, date);
        }
      );
    }
  }

  openCalendar() {
    this.setState({ open: true });
  }

  closeCalendar() {
    this.setState({ open: false });
  }

  render() {
    let value = "";
    if (this.state.startDate && this.state.endDate) {
      value =
        moment(this.state.startDate).format("DD/MM/YY") +
        " - " +
        moment(this.state.endDate).format("DD/MM/YY");
    }

    return (
      <DatePicker
        value={value}
        open={this.state.open}
        selected={this.state.selected}
        selectsStart={this.state.selectsStart}
        selectsEnd={this.state.selectsEnd}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={this.handleChange}
        onFocus={this.openCalendar}
        onBlur={this.closeCalendar}
        onClickOutside={this.closeCalendar}
        onInputClick={this.openCalendar}
        shouldCloseOnSelect={false}
        disabledKeyboardNavigation={true}
      />
    );
  }
}

export default DateRangePicker;
