import Raven from "raven-js";

let remember = false;
const inElectron = window && window.process && window.process.type;

let store = undefined;
if (inElectron) {
  const Store = window.require("electron-store");
  store = new Store();
}

export function set(key, value, useLocal) {
  if (inElectron && store) {
    store.set(key, value);
  }

  if (remember === true || useLocal === true) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  sessionStorage.setItem(key, JSON.stringify(value));
}

export function get(key) {
  if (inElectron && store) {
    const value = store.get(key);
    if (value !== undefined) {
      return value;
    }
  }

  const value = sessionStorage.getItem(key) || localStorage.getItem(key);
  if (value !== undefined && value !== null) {
    try {
      return JSON.parse(value);
    } catch (error) {
      Raven.captureException(error, { extra: { key: key, value: value } });
    }
  }
}

export function useLocalStorage(value) {
  remember = value;
}

export function clear() {
  if (inElectron && store) {
    store.clear();
  }

  localStorage.clear();
  sessionStorage.clear();
}
