import React from "react";
import { Feed } from "semantic-ui-react";
import styled from "styled-components";
import moment from "moment";

const AuthorName = styled.span`
  color: #2185d0;
  text-decoration: underline;
`;

const OccurrenceActivity = ({ authorName, text, date }) => (
  <Feed.Event>
    <Feed.Content>
      <Feed.Summary>
        <AuthorName>{authorName}</AuthorName> escreveu uma mensagem:
        <Feed.Date>{date}</Feed.Date>
      </Feed.Summary>
      <Feed.Extra text>{text}</Feed.Extra>
    </Feed.Content>
  </Feed.Event>
);

const OccurrenceActivities = ({ activities }) => (
  <Feed>
    {activities.map((activity) => (
      <OccurrenceActivity
        key={activity.id}
        authorName={activity.author_name}
        text={activity.text}
        date={moment(activity.created_at).format("DD/MM - HH:mm:ss")}
      />
    ))}
  </Feed>
);

export default OccurrenceActivities;
