import axios from "axios";
import * as storage from "../utils/storage";

export default {
  fetchAll: () => {
    return axios.get("/api/v1/users/").then(response => response.data.data);
  },
  getMe: () => {
    if (storage.get("user")) {
      return Promise.resolve(storage.get("user"));
    }

    return axios.get("/api/v1/users/me").then(response => {
      storage.set("user", {
        ...response.data.data,
        // This keeps the tutorial to show when refreshing the page!
        show_tutorial: false
      });

      return response.data.data;
    });
  },
  create: data => {
    return axios
      .post(`/api/v1/users`, data)
      .then(response => response.data.data);
  },
  update: (id, data) => {
    return axios
      .post(`/api/v1/users/${id}`, data)
      .then(response => response.data.data);
  },
  resetPassword: (id, data) => {
    return axios
      .post(`/api/v1/users/${id}/resetPassword`, data)
      .then(response => response.data.data);
  },
  delete: id => {
    return axios.delete(`/api/v1/users/${id}`);
  }
};
