import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Dimmer,
  Dropdown,
  Grid,
  // Icon,
  Loader,
  Header,
} from "semantic-ui-react";

import get from "lodash/get";
import debounce from "lodash/debounce";

import FinishObservationForm from "./FinishObservationForm";
import CallTechnicianCard from "./CallTechnicianCard";
import MachinesBookmarkBar from "./MachinesBookmarkBar";

import { getSocketConnected } from "../../Auth";

import { FormModal, SectorHighlight } from "../../Shared";

import {
  getMachines,
  getLoading as getMachinesLoading,
  fetchAll as fetchAllMachines,
} from "../../Machines";
import {
  getCardLoading,
  getSelectedMachine,
  getActiveOccurrence,
  selectMachine,
  notifyAchievement,
  closeFinishObservationForm,
  submitFinishObservationForm,
  getAchievementLoading,
  getShowFinishObservationForm,
  getMachinesBookmark,
  addMachineBookmark,
  removeMachineBookmark,
} from "../ducks/call-technician";

import { OCCURRENCE_STATUS as STATUS } from "../../../constants";
import * as storage from "../../../utils/storage";
import socket from "../../../utils/socket";

class CallTechinicianPage extends React.Component {
  constructor(props) {
    super(props);

    this.onSelectMachineOpen = this.onSelectMachineOpen.bind(this);
    this.onMachineSelected = this.onMachineSelected.bind(this);
    this.onOccurrencesUpdate = this.onOccurrencesUpdate.bind(this);

    this.notifyAchievementDebounced = debounce(
      () => {
        this.props.notifyAchievement();
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    );
  }

  onSelectMachineOpen() {
    this.props.fetchAllMachines();
  }

  onMachineSelected(machineId) {
    storage.set("selected_machine_id", machineId);
    this.props.selectMachine(machineId);
  }

  onOccurrencesUpdate(data) {
    console.log("onOccurrencesUpdate");
    const { selectedMachine, activeOccurrence } = this.props;

    const updated_at = get(activeOccurrence, "originals.updated_at");

    if (!selectedMachine || data.machine_id !== selectedMachine.id) {
      return;
    }

    if (!activeOccurrence || updated_at !== data.updated_at) {
      this.props.selectMachine(selectedMachine.id);
    }
  }

  componentWillUnmount() {
    if (this.props.socketConnected) {
      socket.removeAllListeners("occurrences-update");
    }
  }

  componentDidMount() {
    this.props.fetchAllMachines();

    const id = storage.get("selected_machine_id");
    if (id) {
      this.props.selectMachine(id);
    }

    if (this.props.socketConnected) {
      socket.on("occurrences-update", this.onOccurrencesUpdate);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.socketConnected && nextProps.socketConnected) {
      socket.on("occurrences-update", this.onOccurrencesUpdate);
    }
  }

  render() {
    const {
      machines,
      cardLoading,
      machinesLoading,
      achievementLoading,
      selectedMachine,
      activeOccurrence,
      showFinishObservationForm,
      machinesBookmark,
    } = this.props;

    const status = get(activeOccurrence, "status", STATUS.FINISHED);

    const options = machines.map((machine) => {
      return {
        text: machine.name,
        value: machine.id,
      };
    });

    const showDimmer = selectedMachine === undefined;
    const showSector = status === STATUS.WAITING || status === STATUS.ATTENDING;

    return (
      <Container fluid>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Dropdown
                className="call-technician-machine-dropdown"
                deburr
                placeholder="Selecione uma máquina"
                search
                selection
                onChange={(e, { value }) => this.onMachineSelected(value)}
                loading={machinesLoading}
                options={options}
                value={selectedMachine?.id}
                style={{ marginRight: 6 }}
              />
              {/* <Button
                title="Adicionar ao acesso rápido"
                onClick={() => this.props.addMachineBookmark(selectedMachine)}
                disabled={!selectedMachine}
                icon
              >
                <Icon name="add" />
                Acesso rápido
              </Button> */}
            </Grid.Column>
            <Grid.Column floated="right" textAlign="right">
              <Button
                icon="trophy"
                content="Notificar meta"
                disabled={!selectedMachine}
                loading={achievementLoading}
                onClick={this.notifyAchievementDebounced}
                primary
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {machinesBookmark.length > 0 && (
          <MachinesBookmarkBar
            machinesBookmark={machinesBookmark}
            selectedMachine={selectedMachine}
            onMachineClick={this.onMachineSelected}
            onMachineRemove={this.props.removeMachineBookmark}
          />
        )}

        <Dimmer.Dimmable dimmed={showDimmer} style={{ margin: "1em 0" }}>
          <Dimmer inverted active={cardLoading}>
            <Loader />
          </Dimmer>
          <Dimmer inverted active={showDimmer && !cardLoading}>
            <Header as="h2">SELECIONE UMA MÁQUINA</Header>
          </Dimmer>
          <CallTechnicianCard fluid style={{ fontSize: "1.5em" }} />
          {showSector && (
            <SectorHighlight sector={get(activeOccurrence, "sector")} />
          )}
        </Dimmer.Dimmable>

        <FormModal
          id="edit_modal"
          size="tiny"
          header="Observações finais"
          open={showFinishObservationForm}
          onClose={this.props.closeFinishObservationForm}
          formRef={() => this.form}
        >
          <FinishObservationForm
            ref={(form) => (this.form = form)}
            submit={this.props.submitFinishObservationForm}
            occurrence={activeOccurrence}
          />
        </FormModal>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    achievementLoading: getAchievementLoading(state),
    cardLoading: getCardLoading(state),
    machines: getMachines(state),
    machinesLoading: getMachinesLoading(state),
    selectedMachine: getSelectedMachine(state),
    socketConnected: getSocketConnected(state),
    activeOccurrence: getActiveOccurrence(state),
    showFinishObservationForm: getShowFinishObservationForm(state),
    machinesBookmark: getMachinesBookmark(state),
  };
}

export default connect(mapStateToProps, {
  fetchAllMachines,
  selectMachine,
  notifyAchievement,
  closeFinishObservationForm,
  submitFinishObservationForm,
  addMachineBookmark,
  removeMachineBookmark,
})(CallTechinicianPage);
