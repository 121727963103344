import React from "react";
import PropTypes from "prop-types";

import result from "lodash/result";
import { Form, Message } from "semantic-ui-react";
import Datetime from "react-datetime";

import Occurrence from "../../../models/Occurrence";

import "react-datetime/css/react-datetime.css";

class OccurrenceEditForm extends React.Component {
    state = {
        data: {
            started_on: null,
            arrived_on: null,
            finished_on: null,
            canceled_on: null,
            observation: "",
            finish_observation: ""
        },
        loading: false,
        errors: []
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onCanceledChange = this.onCanceledChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onCanceledChange(e, data) {
        if (data.checked) {
            this.setState({
                data: { ...this.state.data, canceled_on: new Date() }
            });
        } else {
            this.setState({ data: { ...this.state.data, canceled_on: null } });
        }
    }

    onDateChange(name, date) {
        const value = result(date, "toDate", null);
        this.setState({
            data: { ...this.state.data, [name]: value }
        });
    }

    onChange(e, { name, value }) {
        this.setState({ data: { ...this.state.data, [name]: value } });
    }

    validate() {
        const { data } = this.state;

        let errors = [];

        if (data.started_on === null) {
            errors.push({
                field: "started_on",
                message: "Campo de início é obrigatório"
            });
        }

        return errors;
    }

    onSubmit() {
        const errors = this.validate();

        this.setState({ errors });

        if (errors.length === 0) {
            return this.props.submit(this.state.data);
        } else {
            return Promise.reject();
        }
    }

    componentDidMount() {
        const { occurrence } = this.props;
        if (occurrence) {
            this.setState({
                data: {
                    started_on: occurrence.started_on,
                    arrived_on: occurrence.arrived_on,
                    finished_on: occurrence.finished_on,
                    canceled_on: occurrence.canceled_on,
                    observation: occurrence.observation,
                    finish_observation: occurrence.finish_observation
                }
            });
        }
    }

    render() {
        const { data, errors } = this.state;

        const isCanceled = !!data.canceled_on;

        const errorsFields = errors.map(e => e.field);

        return (
            <Form
                id="occurrences_edit_form"
                onSubmit={this.onSubmit}
                error={errors.length > 0}
            >
                <Form.Field
                    required
                    error={errorsFields.indexOf("started_on") !== -1}
                >
                    <label>Iniciado em</label>
                    <Datetime
                        value={data.started_on}
                        dateFormat="DD/MM/YY"
                        timeFormat="HH:mm:ss"
                        locale="pt-BR"
                        inputProps={{
                            disabled: isCanceled,
                            required: true,
                            name: "started_on"
                        }}
                        onChange={date => this.onDateChange("started_on", date)}
                    />
                </Form.Field>

                <Form.Field error={errorsFields.indexOf("arrived_on") !== -1}>
                    <label>Chegada do técnico em</label>
                    <Datetime
                        value={data.arrived_on}
                        dateFormat="DD/MM/YY"
                        timeFormat="HH:mm:ss"
                        locale="pt-BR"
                        inputProps={{
                            disabled: isCanceled,
                            name: "arrived_on"
                        }}
                        onChange={date => this.onDateChange("arrived_on", date)}
                    />
                </Form.Field>

                <Form.Field error={errorsFields.indexOf("finished_on") !== -1}>
                    <label>Atendimento finalizado em</label>
                    <Datetime
                        value={data.finished_on}
                        dateFormat="DD/MM/YY"
                        timeFormat="HH:mm:ss"
                        locale="pt-BR"
                        inputProps={{
                            disabled: isCanceled,
                            name: "finished_on"
                        }}
                        onChange={date =>
                            this.onDateChange("finished_on", date)
                        }
                    />
                </Form.Field>

                <Form.Checkbox
                    label="Cancelado"
                    name="canceled"
                    checked={isCanceled}
                    onChange={this.onCanceledChange}
                />

                <Form.TextArea
                    label="Observações Iniciais"
                    name="observation"
                    value={data.observation}
                    onChange={this.onChange}
                    placeholder="Observações"
                    error={errorsFields.indexOf("observation") !== -1}
                />

                <Form.TextArea
                    label="Observações Finais"
                    name="finish_observation"
                    value={data.finish_observation}
                    onChange={this.onChange}
                    placeholder="Observações"
                    error={errorsFields.indexOf("finish_observation") !== -1}
                />

                <Message error list={errors.map(e => e.message)} />
            </Form>
        );
    }
}

OccurrenceEditForm.propTypes = {
    submit: PropTypes.func.isRequired,
    occurrence: PropTypes.instanceOf(Occurrence)
};

export default OccurrenceEditForm;
