import React from "react";

import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import xor from "lodash/xor";

class ListAudioPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.handleAudioEnded = this.handleAudioEnded.bind(this);
    this.loadNextAudio = this.loadNextAudio.bind(this);
    this.playAudio = this.playAudio.bind(this);

    this.nextPlayIndex = 0;
    this.nextPlaySrc = "";
  }

  componentDidMount() {
    this.audio = new Audio();
    this.audio.addEventListener("ended", this.handleAudioEnded);

    this.loadNextAudio();
    this.playAudio();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);

    if (this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { sources } = this.props;

    const difference = xor(sources, prevProps.sources);

    if (!isEmpty(difference)) {
      this.nextPlayIndex = 0;
      this.loadNextAudio();
      this.playAudio();
    } else if (this.props.muted !== prevProps.muted) {
      this.playAudio();
    }
  }

  loadNextAudio() {
    const { sources } = this.props;
    let nextPlayIndex = 0;
    let nextPlaySrc = "";

    if (isArray(sources) && !isEmpty(sources)) {
      nextPlayIndex = (this.nextPlayIndex + 1) % sources.length;
      nextPlaySrc = sources[nextPlayIndex];
    }

    if (nextPlaySrc && nextPlaySrc !== this.nextPlaySrc) {
      this.audio.src = nextPlaySrc;
      this.audio.load();
    }

    this.nextPlayIndex = nextPlayIndex;
    this.nextPlaySrc = nextPlaySrc;
  }

  handleAudioEnded() {
    if (!this.audio) {
      return;
    }

    this.loadNextAudio();
    if (this.props.delayMS) {
      this.timeout = setTimeout(this.playAudio, this.props.delayMS);
    } else {
      this.playAudio();
    }
  }

  playAudio() {
    const { muted } = this.props;

    if (muted) {
      this.audio.pause();
      return;
    }

    if (this.nextPlaySrc) {
      this.audio.play();
    } else {
      this.audio.pause();
    }
  }

  render() {
    return <div />;
  }
}

export default ListAudioPlayer;
