import React from "react";
import ReactGA from "react-ga";
import appVersion from "./appVersion";
import electronAppVersion from "./electronAppVersion";

export function analyticsInit() {
  ReactGA.initialize("UA-121999302-1", {
    testMode: process.env.REACT_APP_ENV === "development",
    debug: process.env.REACT_APP_ENV === "development"
  });
  ReactGA.set({ release: appVersion, electronVersion: electronAppVersion });
}

export const analyticsMiddleware = store => next => action => {
  ReactGA.event({
    category: "REDUX",
    action: action.type
  });

  return next(action);
};

export const withAnalytics = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends React.Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};
