import React from "react";

import validator from "validator";

import { Message, Form } from "semantic-ui-react";

class UserEditForm extends React.Component {
  state = {
    data: {
      name: "",
      email: "",
      username: "",
      password: "",
      password_confirmation: "",
      type: 1,
      notification_rule_id: undefined,
      filter_rule_id: undefined,
      permission_id: undefined
    },
    errors: []
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e, props) {
    const { name, value } = props;

    this.setState({
      data: { ...this.state.data, [name]: value }
    });
  }

  validate() {
    const { data } = this.state;

    let errors = [];

    if (validator.isEmpty(data.name)) {
      errors.push({ field: "name", message: "Nome é obrigatório" });
    }

    if (!validator.isEmail(data.email)) {
      errors.push({ field: "email", message: "Email inválido" });
    }

    // If user is new instead of updating, check for password validation
    if (!data.id) {
      if (validator.isEmpty(data.password)) {
        errors.push({
          field: "password",
          message: "Senha é obrigatório"
        });
      }

      if (!validator.equals(data.password, data.password_confirmation)) {
        errors.push({
          field: "password_confirmation",
          message: "Senhas não conferem"
        });
      }
    }

    return errors;
  }

  onSubmit() {
    const errors = this.validate();

    this.setState({ errors });

    if (errors.length === 0) {
      return this.props.submit(this.state.data).catch(err => {
        const { response } = err;
        if (response.status === 422) {
          const errors = Object.keys(response.data.errors).reduce(
            (total, current) => {
              const error = response.data.errors[current].map(error => ({
                field: current,
                message: error
              }));
              return total.concat(error);
            },
            []
          );

          this.setState({ errors });
        }
      });
    } else {
      return Promise.reject();
    }
  }

  componentDidMount() {
    const { user } = this.props;

    if (user) {
      this.setState({
        data: {
          ...user
        }
      });
    }
  }

  render() {
    const { data, errors } = this.state;
    const { notificationRules, filterRules, permissions } = this.props;

    const errorsFields = errors.map(e => e.field);

    return (
      <Form onSubmit={this.onSubmit} error={errorsFields.length > 0}>
        <Form.Input
          label="Nome"
          type="text"
          name="name"
          value={data.name}
          onChange={this.onChange}
          placeholder="Nome"
          required
          autoFocus
          error={errorsFields.indexOf("name") !== -1}
        />

        <Form.Input
          label="Email"
          type="text"
          name="email"
          value={data.email}
          onChange={this.onChange}
          placeholder="Email"
          required
          error={errorsFields.indexOf("email") !== -1}
        />

        {/* Only input password in new users, old ones shoudl use reset password */}
        {!data.id && (
          <Form.Group widths="equal">
            <Form.Input
              label="Senha"
              type="password"
              name="password"
              value={data.password}
              onChange={this.onChange}
              placeholder="Senha"
              required
              error={errorsFields.indexOf("password") !== -1}
            />

            <Form.Input
              label="Confirmar Senha"
              type="password"
              name="password_confirmation"
              value={data.password_confirmation}
              onChange={this.onChange}
              placeholder="Confirmar Senha"
              required
              error={errorsFields.indexOf("password_confirmation") !== -1}
            />
          </Form.Group>
        )}

        <Form.Group widths="equal">
          <Form.Dropdown
            label="Tipo"
            selection
            required
            onChange={this.onChange}
            value={data.type}
            name="type"
            options={[
              {
                key: 0,
                value: 1,
                text: "Normal"
              },
              {
                key: 1,
                value: 0,
                text: "Admin"
              }
            ]}
          />

          <Form.Dropdown
            label="Regra de acesso"
            selection
            name="permission_id"
            onChange={this.onChange}
            value={data.permission_id}
            options={permissions.map(p => ({
              key: p.id,
              value: p.id,
              text: p.name
            }))}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Dropdown
            label="Regra de notificação"
            selection
            name="notification_rule_id"
            onChange={this.onChange}
            value={data.notification_rule_id}
            options={notificationRules.map(p => ({
              key: p.id,
              value: p.id,
              text: p.name
            }))}
          />

          <Form.Dropdown
            label="Regra de filtro"
            selection
            name="filter_rule_id"
            onChange={this.onChange}
            value={data.filter_rule_id}
            options={filterRules.map(p => ({
              key: p.id,
              value: p.id,
              text: p.name
            }))}
          />
        </Form.Group>

        <Message error list={errors.map(e => e.message)} />
      </Form>
    );
  }
}

export default UserEditForm;
