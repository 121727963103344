import React from "react";
import { connect } from "react-redux";
import { Modal } from "semantic-ui-react";

import { OccurrenceActivities, useOccurrenceActivities } from "../../Shared";
import {
  getActivitiesModalOccurrence,
  closeOccurrenceActivitiesModal,
} from "../ducks/kanban";

const OccurrenceActivitiesModal = ({
  occurrence,
  closeOccurrenceActivitiesModal,
}) => {
  const { activities } = useOccurrenceActivities(occurrence?.id);

  return (
    <Modal
      closeIcon={true}
      open={!!occurrence}
      onClose={closeOccurrenceActivitiesModal}
    >
      <Modal.Header>Registro de atividades</Modal.Header>
      <Modal.Content scrolling>
        <OccurrenceActivities activities={activities} />
      </Modal.Content>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    occurrence: getActivitiesModalOccurrence(state),
  };
}

export default connect(mapStateToProps, { closeOccurrenceActivitiesModal })(
  OccurrenceActivitiesModal
);
