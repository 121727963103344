import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import isEmpty from "lodash/isEmpty";

import {
  FETCH_ALL_REQUEST,
  FETCH_ALL_SUCCESS,
  FILTER_CHANGED,
  REDUCER_NAME,
  CREATE_SUCCESS
} from "../constants";

import api from "../../../api";

const allFetchedRequest = createAction(FETCH_ALL_REQUEST);
const allFetchedSuccess = createAction(FETCH_ALL_SUCCESS);
const createdSuccess = createAction(CREATE_SUCCESS);

export const changeFilter = createAction(FILTER_CHANGED);

export const fetchAll = (force = false) => (dispatch, getState) => {
  const priorities = getPriorities(getState());
  if (!isEmpty(priorities) && !force) {
    return dispatch(allFetchedSuccess(priorities));
  }

  dispatch(allFetchedRequest());

  return api.priorities.fetchAll().then(priorities => {
    dispatch(allFetchedSuccess(priorities));
  });
};

export const moveUp = id => dispatch => {
  dispatch(allFetchedRequest());

  return api.priorities.up(id).then(priorities => {
    dispatch(allFetchedSuccess(priorities));
  });
};

export const moveDown = id => dispatch => {
  dispatch(allFetchedRequest());

  return api.priorities.down(id).then(priorities => {
    dispatch(allFetchedSuccess(priorities));
  });
};

export const create = data => dispatch => {
  dispatch(allFetchedRequest());

  return api.priorities
    .create(data)
    .then(() => dispatch(createdSuccess()))
    .then(() => api.priorities.fetchAll())
    .then(priorities => {
      dispatch(allFetchedSuccess(priorities));
    });
};

export const update = (id, data) => dispatch => {
  dispatch(allFetchedRequest());

  return api.priorities
    .update(id, data)
    .then(() => api.priorities.fetchAll())
    .then(priorities => {
      dispatch(allFetchedSuccess(priorities));
    });
};

export const remove = id => dispatch => {
  dispatch(allFetchedRequest());

  return api.priorities
    .delete(id)
    .then(() => api.priorities.fetchAll())
    .then(priorities => {
      dispatch(allFetchedSuccess(priorities));
    });
};

const initialState = {
  priorities: [],
  loading: false,
  filter: {
    sector_id: 0
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return { ...state, loading: true };
    case FETCH_ALL_SUCCESS:
      return { ...state, loading: false, priorities: action.payload };
    case FILTER_CHANGED:
      const { name, value } = action.payload;
      return { ...state, filter: { ...state.filter, [name]: value } };
    default:
      return state;
  }
};

export const getFilter = state => state[REDUCER_NAME].filter;
export const getLoading = state => state[REDUCER_NAME].loading;
export const getPriorities = state => state[REDUCER_NAME].priorities;

export const getPrioritiesFiltered = createSelector(
  [getPriorities, getFilter],
  (priorities, filter) => {
    if (filter.sector_id === 0) {
      return priorities;
    } else {
      return priorities.filter(
        priority => priority.sector_id === filter.sector_id
      );
    }
  }
);
