import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import api from "../../../api";

import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import difference from "lodash/difference";
import get from "lodash/get";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";

import {
  MACHINE_GROUPS_FETCHING,
  MACHINE_GROUPS_FETCHED,
  MACHINE_GROUPS_CREATING,
  MACHINE_GROUPS_CREATED,
  OVERVIEW_FETCHING,
  OVERVIEW_FETCHED,
  EDIT_FORM_OPEN,
  EDIT_FORM_CLOSE,
  DELETE_CONFIRM_CLOSE,
  DELETE_CONFIRM_OPEN,
  FILTER_CHANGED,
  REDUCER_NAME,
} from "../constants";

import { OCCURRENCE_STATUS as STATUS } from "../../../constants";

export const machineGroupsFetched = createAction(MACHINE_GROUPS_FETCHED);
export const machineGroupCreated = createAction(MACHINE_GROUPS_CREATED);
export const overviewFetched = createAction(OVERVIEW_FETCHED);
export const openEditForm = createAction(EDIT_FORM_OPEN);
export const closeEditForm = createAction(EDIT_FORM_CLOSE);
export const openDeleteConfirm = createAction(DELETE_CONFIRM_OPEN);
export const closeDeleteConfirm = createAction(DELETE_CONFIRM_CLOSE);
export const filterChanged = createAction(FILTER_CHANGED);

export const changeFilter = ({ name, value }) => (dispatch, getState) => {
  const filter = getFilter(getState());

  let shouldUpdate = true;

  if (name === "groups") {
    if (includes(value, 0) && !includes(filter.groups, 0)) {
      // if All selected, remove others and let only all option
      value = [0];
    } else if (includes(filter.groups, 0) && value.length > 1) {
      // if we had All selected, and selected another one. remove All
      value = difference(value, [0]);
    } else if (value.length === 0) {
      // if we removed every option, let all by default.
      value = [0];
      if (includes(filter.groups, 0)) {
        // if we tried to remove the all option, do not update
        shouldUpdate = false;
      }
    }
  }

  if (shouldUpdate) {
    dispatch(filterChanged({ name, value }));
  }
};

export const fetchAllMachineGroups = (force = false) => (
  dispatch,
  getState
) => {
  const groups = getMachineGroups(getState());
  if (!isEmpty(groups) && !force) {
    return dispatch(machineGroupsFetched(groups));
  }

  dispatch({ type: MACHINE_GROUPS_FETCHING });

  return api.machineGroups
    .fetchAll()
    .then((groups) => dispatch(machineGroupsFetched(groups)));
};

export const fetchOverview = (params) => (dispatch, getState) => {
  dispatch({ type: OVERVIEW_FETCHING });

  return api.machineGroups
    .overview(params)
    .then((groups) => dispatch(overviewFetched(groups)));
};

export const createMachineGroup = (data) => (dispatch) => {
  dispatch({ type: MACHINE_GROUPS_CREATING });

  return api.machineGroups.create(data).then((group) => {
    dispatch(machineGroupCreated(group));
    return group;
  });
};

export const upMachineGroup = (id) => (dispatch, getState) => {
  dispatch({ type: OVERVIEW_FETCHING });

  return api.machineGroups
    .up(id)
    .then((groups) => dispatch(overviewFetched(groups)));
};

export const downMachineGroup = (id) => (dispatch, getState) => {
  dispatch({ type: OVERVIEW_FETCHING });

  return api.machineGroups
    .down(id)
    .then((groups) => dispatch(overviewFetched(groups)));
};

export const updateMachineGroup = (id, data) => (dispatch) => {
  return api.machineGroups.update(id, data);
};

export const deleteMachineGroup = (id) => (dispatch) => {
  dispatch({ type: OVERVIEW_FETCHING });

  return api.machineGroups.delete(id);
};

const initialState = {
  filter: { groups: [0] },
  formOpen: false,
  deleteConfirmOpen: false,
  selectedMachineGroup: undefined,
  groupsOverview: [],
  groupsOverviewFetching: false,
  groupsLoading: false,
  groups: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FILTER_CHANGED:
      const { name, value } = action.payload;
      const filter = { ...state.filter, [name]: value };
      return {
        ...state,
        filter,
      };
    case MACHINE_GROUPS_FETCHING:
    case MACHINE_GROUPS_CREATING:
      return { ...state, groupsLoading: true };
    case MACHINE_GROUPS_FETCHED:
      return { ...state, groups: action.payload, groupsLoading: false };
    case MACHINE_GROUPS_CREATED:
      const groups = state.groups.concat([action.payload]);
      return { ...state, groups, groupsLoading: false };
    case OVERVIEW_FETCHING:
      return { ...state, groupsOverviewFetching: true };
    case OVERVIEW_FETCHED:
      return {
        ...state,
        groupsOverview: action.payload,
        groupsOverviewFetching: false,
      };
    case EDIT_FORM_OPEN:
      return {
        ...state,
        formOpen: true,
        selectedMachineGroup: action.payload,
      };
    case EDIT_FORM_CLOSE:
      return {
        ...state,
        formOpen: false,
        selectedMachineGroup: undefined,
      };
    case DELETE_CONFIRM_OPEN:
      return {
        ...state,
        deleteConfirmOpen: true,
        selectedMachineGroup: action.payload,
      };
    case DELETE_CONFIRM_CLOSE:
      return {
        ...state,
        deleteConfirmOpen: false,
        selectedMachineGroup: undefined,
      };
    default:
      return state;
  }
};

export const getDeleteConfirmOpen = (state) =>
  state[REDUCER_NAME].deleteConfirmOpen;

export const getEditFormOpen = (state) => state[REDUCER_NAME].formOpen;
export const getSelected = (state) => state[REDUCER_NAME].selectedMachineGroup;

export const getMachineGroups = (state) => state[REDUCER_NAME].groups;
export const getMachineGroupsLoading = (state) =>
  state[REDUCER_NAME].groupsLoading;

export const getOverview = (state) => state[REDUCER_NAME].groupsOverview;
export const getLoading = (state) => state[REDUCER_NAME].groupsOverviewFetching;

export const getFilter = (state) => state[REDUCER_NAME].filter;

export const getOverviewFiltered = createSelector(
  [getOverview, getFilter],
  (groups, filter) => {
    if (includes(filter.groups, 0)) {
      return groups;
    } else {
      return groups.filter((group) => includes(filter.groups, group.id));
    }
  }
);

export const getSoundList = createSelector(getOverviewFiltered, (groups) =>
  uniq(
    flatten(groups.map((group) => group.machines))
      .filter(
        (machine) =>
          get(machine, "last_occurrence.priority.sound.url") &&
          get(machine, "last_occurrence.status") === STATUS.WAITING
      )
      .sort((a, b) => b.level - a.level)
      .map((machine) => machine.last_occurrence.priority.sound.url)
  )
);
