import { createAction } from "redux-actions";
import { EVENTS, ACTIONS } from "react-joyride";

import { CREATE_SUCCESS as MACHINE_CREATE_SUCCESS } from "../../Machines/constants";
import { CREATE_SUCCESS as PRIORITY_CREATE_SUCCESS } from "../../Priorities/constants";
import { SECTOR_CREATED } from "../../Sectors/constants";
import {
  MACHINE_SELECTED,
  OCCURRENCE_REGISTERED,
  OCCURRENCE_ARRIVED,
  CLOSE_FINISH_OBSERVATION_FORM,
} from "../../CallTechnician/constants";

import { REDUCER_NAME, JOYRIDE_CB, START_TUTORIAL } from "../constants";

export const joyrideCallback = createAction(JOYRIDE_CB);
export const startTutorial = createAction(START_TUTORIAL);

const initialState = {
  run: false,
  stepIndex: 0,
};

const updateStateFromJoyride = (state, data) => {
  const { action, index, type } = data;

  if ((type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) && state.run) {
    // Need to set our running state to false, so we can restart if we click start again.
    return { ...state, run: false };
  } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
    return { ...state, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) };
  } else if (type === EVENTS.TOOLTIP_CLOSE) {
    return { ...state, stepIndex: index + 1 };
  }

  return state;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case JOYRIDE_CB:
      return updateStateFromJoyride(state, action.payload);
    case START_TUTORIAL:
      return { ...state, run: true };
    case MACHINE_CREATE_SUCCESS:
      if (state.stepIndex === 3) {
        return { ...state, stepIndex: state.stepIndex + 1 };
      }
      return state;
    case SECTOR_CREATED:
      if (state.stepIndex === 6) {
        return { ...state, stepIndex: state.stepIndex + 1 };
      }
      return state;
    case PRIORITY_CREATE_SUCCESS:
      if (state.stepIndex === 9) {
        return { ...state, stepIndex: state.stepIndex + 1 };
      }
      return state;
    case MACHINE_SELECTED:
      if (state.stepIndex === 12) {
        return { ...state, stepIndex: state.stepIndex + 1 };
      }
      return state;
    case OCCURRENCE_REGISTERED:
      if (state.stepIndex === 13) {
        return { ...state, stepIndex: state.stepIndex + 1 };
      }
      return state;
    case OCCURRENCE_ARRIVED:
      if (state.stepIndex === 14) {
        return { ...state, stepIndex: state.stepIndex + 1 };
      }
      return state;
    case CLOSE_FINISH_OBSERVATION_FORM:
      if (state.stepIndex === 15) {
        return { ...state, stepIndex: state.stepIndex + 1 };
      }
      return state;
    default:
      return state;
  }
};

export const getStepIndex = (state) => state[REDUCER_NAME].stepIndex;
export const getRun = (state) => state[REDUCER_NAME].run;
