import gtag, { install } from "ga-gtag";

export function gtag_report_conversion(url) {
  if (process.env.REACT_APP_ENV !== "production") {
    return;
  }

  install("AW-578725815");

  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-578725815/xLCpCOeg2fsBELfP-pMC",
    event_callback: callback,
  });
  return false;
}
