import axios from "axios";
import ReactGA from "react-ga";

import auth from "./auth";
import devices from "./devices";
import machineGroups from "./machine-groups";
import machines from "./machines";
import notificationRules from "./notification-rules";
import filterRules from "./filter-rules";
import occurrences from "./occurrences";
import permissions from "./permissions";
import priorities from "./priorities";
import sectors from "./sectors";
import users from "./users";
import contact from "./contact";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

// Add a request interceptor
axios.interceptors.request.use((config) => {
  config.headers["CLIENT-TIME"] = Date.now();

  return config;
});

// calculate the time offset
axios.interceptors.response.use(
  (response) => {
    const serverTime = Number(response.headers["server-time"]);
    const clientTime = Number(response.headers["client-time"]);
    const responseTime = Number(response.headers["x-response-time"]);

    const networkDelay = Math.round((Date.now() - clientTime) / 2.0);

    const offset = serverTime - clientTime - networkDelay;

    response.timeOffset = offset;

    if (response.config.url.includes("api") && responseTime) {
      ReactGA.timing({
        category: "api call",
        variable: response.config.url,
        value: responseTime,
      });
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  auth,
  devices,
  machineGroups,
  machines,
  notificationRules,
  filterRules,
  occurrences,
  permissions,
  priorities,
  sectors,
  users,
  contact,
};
