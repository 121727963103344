import React from "react";
import NumberFormat from "react-number-format";
import { Input } from "semantic-ui-react";

const MaskedNumberInput = ({ onChange, name, ...rest }) => (
  <NumberFormat
    {...rest}
    onValueChange={({ formattedValue }) => {
      onChange(null, { name, value: formattedValue });
    }}
    customInput={Input}
  />
);

export default MaskedNumberInput;
