const get = require("lodash/get");
const find = require("lodash/find");

const { USER_TYPES } = require("../constants");

module.exports = (user, slug, checkEdit) => {
  if (!user) {
    return false;
  }

  if (!user.permission) {
    return true;
  }

  if (
    user.type === USER_TYPES.ADMIN ||
    slug === "dashboard" ||
    slug === "help"
  ) {
    return true;
  }

  const userPages = get(user, "permission.pages", []);
  const page = find(userPages, { slug });

  return checkEdit ? get(page, "rule.edit", false) : page !== undefined;
};
