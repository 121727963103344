import { createAction } from "redux-actions";
import { createSelector } from "reselect";

import get from "lodash/get";
import findIndex from "lodash/findIndex";
import includes from "lodash/includes";
import difference from "lodash/difference";

import api from "../../../api";

import * as storage from "../../../utils/storage";
import userHasPage from "../../../utils/userHasPage";
import socket from "../../../utils/socket";

import {
  KANBAN_FETCHING,
  KANBAN_FETCHED,
  KANBAN_FILTER_CHANGED,
  KANBAN_FILTER_SET,
  KANBAN_UPDATED,
  KANBAN_ALERT,
  KANBAN_ALERT_CLEAR,
  KANBAN_OPEN_ACTIVITIES_MODAL,
  KANBAN_CLOSE_ACTIVITIES_MODAL,
  REDUCER_NAME,
} from "../constants";
import Occurrence from "../../../models/Occurrence";

export const clearOccurrenceAlert = createAction(KANBAN_ALERT_CLEAR);

export const kanbanFetched = createAction(
  KANBAN_FETCHED,
  // Payload creator
  (response) =>
    response.data.data.map(
      (occurrence) => new Occurrence(occurrence, response.timeOffset)
    ),
  // Meta creator
  (response) => ({ timeOffset: response.timeOffset })
);

export const kanbanFilterChanged = createAction(KANBAN_FILTER_CHANGED);
export const setKanbanFilter = createAction(KANBAN_FILTER_SET);
export const updateKanban = createAction(KANBAN_UPDATED);
export const showOccurrenceAlert = createAction(KANBAN_ALERT);
export const openOccurrenceActivitiesModal = createAction(
  KANBAN_OPEN_ACTIVITIES_MODAL
);
export const closeOccurrenceActivitiesModal = createAction(
  KANBAN_CLOSE_ACTIVITIES_MODAL
);

export const closeOccurrenceAlert = (occurrence) => (dispatch, getState) => {
  const alertOccurrence = getAlertOccurrence(getState());

  if (alertOccurrence && alertOccurrence.id === occurrence.id) {
    dispatch(clearOccurrenceAlert());
  }
};

export const changeKanbanFilter = ({ name, value }) => (dispatch, getState) => {
  const filter = getFilter(getState());

  let shouldUpdate = true;

  if (name === "sectors") {
    if (includes(value, 0) && !includes(filter.sectors, 0)) {
      // if All selected, remove others and let only all option
      value = [0];
    } else if (includes(filter.sectors, 0) && value.length > 1) {
      // if we had All selected, and selected another one. remove All
      value = difference(value, [0]);
    } else if (value.length === 0) {
      // if we removed every option, let all by default.
      value = [0];
      if (includes(filter.sectors, 0)) {
        // if we tried to remove the all option, do not update
        shouldUpdate = false;
      }
    }
  }

  if (shouldUpdate) {
    dispatch(kanbanFilterChanged({ name, value }));
  }
};

export const fetchKanban = (params) => (dispatch) => {
  dispatch({ type: KANBAN_FETCHING });
  return api.occurrences
    .fetchKanban()
    .then((response) => dispatch(kanbanFetched(response)));
};

function updateOccurrences(state, action) {
  const occurrence = new Occurrence(action.payload, state.timeOffset);

  const index = findIndex(state.occurrences, { id: occurrence.id });
  let occurrences = undefined;

  // TODO: User normalizr to refactor this
  if (index === -1) {
    occurrences = state.occurrences.concat([occurrence]);
  } else {
    occurrences = state.occurrences.slice();
    occurrences[index] = occurrence;
  }

  return {
    ...state,
    occurrences,
  };
}

export const setupKanban = (user) => (dispatch) => {
  if (!userHasPage(user, "kanban")) {
    return;
  }

  socket.on("kanban-update", (data) => dispatch(updateKanban(data)));

  socket.on("occurrences-alert", (occurrence) => {
    console.log("received ", "occurrences-alert");
    dispatch(showOccurrenceAlert(new Occurrence(occurrence)));

    setTimeout(() => {
      dispatch(closeOccurrenceAlert(occurrence));
    }, 3 * 60 * 1000);
  });

  return dispatch(fetchKanban());
};

const initialState = {
  loading: false,
  filter: { sectors: [0] },
  occurrences: [],
  alertOccurrence: null,
  activitiesModalOccurrence: null,
  timeOffset: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case KANBAN_FETCHED:
      return {
        ...state,
        loading: false,
        occurrences: action.payload,
        timeOffset: action.meta.timeOffset,
      };
    case KANBAN_FETCHING:
      return { ...state, loading: true };
    case KANBAN_FILTER_CHANGED:
      const { name, value } = action.payload;
      const filter = { ...state.filter, [name]: value };
      storage.set("kanban_filter", filter);
      return {
        ...state,
        filter,
      };
    case KANBAN_FILTER_SET:
      storage.set("kanban_filter", action.payload);
      return {
        ...state,
        filter: action.payload,
      };
    case KANBAN_UPDATED:
      return updateOccurrences(state, action);
    case KANBAN_ALERT:
      return { ...state, alertOccurrence: action.payload };
    case KANBAN_ALERT_CLEAR:
      return { ...state, alertOccurrence: null };
    case KANBAN_OPEN_ACTIVITIES_MODAL:
      return { ...state, activitiesModalOccurrence: action.payload };
    case KANBAN_CLOSE_ACTIVITIES_MODAL:
      return { ...state, activitiesModalOccurrence: null };
    default:
      return state;
  }
};

export const getAlertOccurrence = (state) =>
  state[REDUCER_NAME].alertOccurrence;
export const getActivitiesModalOccurrence = (state) =>
  state[REDUCER_NAME].activitiesModalOccurrence;
export const getAllOccurrences = (state) => state[REDUCER_NAME].occurrences;
export const getLoading = (state) => state[REDUCER_NAME].loading;
export const getFilter = (state) => state[REDUCER_NAME].filter;

export const getAllOccurrencesFiltered = createSelector(
  [getAllOccurrences, getFilter],
  (occurrences, filter) => {
    if (includes(filter.sectors, 0)) {
      return occurrences;
    } else {
      return occurrences.filter((occurrence) =>
        includes(filter.sectors, occurrence.sector_id)
      );
    }
  }
);

export const getWaitingOccurrences = createSelector(
  getAllOccurrencesFiltered,
  (occurrences) =>
    occurrences
      .filter((occurrence) => occurrence.isWaiting())
      .sort((a, b) => get(b, "machine.level", -1) - get(a, "machine.level", -1))
);
export const getArrivedOccurrences = createSelector(
  getAllOccurrencesFiltered,
  (occurrences) =>
    occurrences
      .filter((occurrence) => occurrence.isArrived())
      .sort((a, b) => get(b, "machine.level", -1) - get(a, "machine.level", -1))
);
export const getFinishedOccurrences = createSelector(
  getAllOccurrencesFiltered,
  (occurrences) =>
    occurrences
      .filter((occurrence) => occurrence.isFinished())
      .sort((a, b) => b.updated_at - a.updated_at)
);

export const getSoundList = createSelector(
  getWaitingOccurrences,
  (occurrences) =>
    occurrences
      .filter((occurrence) => get(occurrence, "priority.sound.url"))
      .map((occurrence) => occurrence.priority.sound.url)
);
