import React from "react";
import { connect } from "react-redux";

import { Button, Card, Confirm } from "semantic-ui-react";

import moment from "moment";
import get from "lodash/get";

import {
  ListAudioPlayer,
  CardWithMarker,
  OccurrenceTimerCounter,
} from "../../Shared";
import CallTechnicianButton from "./CallTechnicianButton";
import CallTechnicianForm from "./CallTechnicianForm";

import {
  getActiveOccurrence,
  getSelectedMachine,
  getLoading,
  registerOccurrence,
  onCanceledOccurrence,
  nextStateOccurrence,
  escalateOccurrence,
} from "../ducks/call-technician";

import {
  getSectors,
  getLoading as getLoadingSectors,
  fetchAll as fetchAllSectors,
} from "../../Sectors";

// Circular dependency problem
import { setErrorMessage } from "../../App/ducks/app";

import isElectron from "../../../utils/isElectron";
import {
  OCCURRENCE_STATUS as STATUS,
  MACHINE_LEVEL_OPTIONS,
} from "../../../constants";
import CallTechnicianOccurrenceActivities from "./CallTechnicianOccurrenceActivities";

let ipcRenderer = null;
if (isElectron()) {
  ipcRenderer = window.ipcRenderer;
}

const isActiveOccurrence = (occurrence) =>
  occurrence &&
  occurrence.status !== STATUS.FINISHED &&
  occurrence.status !== STATUS.CANCELED;

const LastOccurrence = ({ occurrence }) => (
  <div style={{ margin: "32px 0 18px" }}>
    <label style={{ fontSize: "20px", color: "#9b9b9b" }}>
      Ultimo chamado:
    </label>

    <div style={{ fontSize: "2em", lineHeight: "1em" }}>
      <span>
        {occurrence
          ? moment(occurrence.started_on).format("DD/MM/YYYY")
          : "-- / -- / ----"}
      </span>
      <span style={{ marginLeft: "18px" }}>
        {occurrence
          ? moment(occurrence.started_on).format("HH:mm:ss")
          : "-- : -- : --"}
      </span>
    </div>
  </div>
);

class CallTechnicianCard extends React.Component {
  state = {
    confirmOpen: false,
    pendingHotkeyRequest: undefined,
  };

  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleConfirmConfirm = this.handleConfirmConfirm.bind(this);
    this.processPendingHotkeyRequest = this.processPendingHotkeyRequest.bind(
      this
    );
  }

  handleFormSubmit(data) {
    const { machine } = this.props;
    return this.props.registerOccurrence({
      machine_id: machine.id,
      ...data,
    });
  }

  handleConfirmConfirm() {
    const { activeOccurrence } = this.props;

    this.setState({ confirmOpen: false });
    return this.props.onCanceledOccurrence(activeOccurrence.id);
  }

  componentWillUnmount() {
    if (ipcRenderer) {
      ipcRenderer.removeAllListeners("sectors-hotkey");
    }
  }

  componentWillMount() {
    if (ipcRenderer) {
      ipcRenderer.on("sectors-hotkey", (e, data) => {
        this.setState({ pendingHotkeyRequest: { ...data } });
      });
    }
  }

  componentDidMount() {
    this.props.fetchAllSectors();
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, machine } = this.props;
    const { pendingHotkeyRequest } = this.state;

    if (machine && pendingHotkeyRequest && !loading) {
      this.processPendingHotkeyRequest();
    }
  }

  processPendingHotkeyRequest() {
    const { activeOccurrence } = this.props;
    const { pendingHotkeyRequest } = this.state;

    if (
      !activeOccurrence ||
      activeOccurrence.status === STATUS.FINISHED ||
      activeOccurrence.status === STATUS.CANCELED ||
      activeOccurrence.sector_id === pendingHotkeyRequest.sector_id
    ) {
      this.props.nextStateOccurrence(pendingHotkeyRequest);
    } else {
      this.props.setErrorMessage(
        "É preciso finalizar o chamado atual antes de realizar outro de diferente setor!"
      );
    }

    this.setState({ pendingHotkeyRequest: null });
  }

  render() {
    const {
      machine,
      activeOccurrence,
      sectors,
      sectorsLoading,
      loading,
    } = this.props;

    const status = get(activeOccurrence, "status", STATUS.FINISHED);

    let timerCounterColor = "#4caf50";
    let markerColor = "#4caf50";

    if (status === STATUS.WAITING) {
      timerCounterColor = "#f44336";
      markerColor = "#f44336";
    } else if (status === STATUS.ATTENDING) {
      timerCounterColor = "#ffce3b";
      markerColor = "#ffce3b";
    }

    const soundUrl = get(activeOccurrence, "priority.sound.url");
    const activePriorityName = get(activeOccurrence, "priority.name");

    return (
      <CardWithMarker
        fluid
        markerColor={markerColor}
        style={{ display: "flex" }}
      >
        <Card.Content style={{ flex: "1" }}>
          <Card.Header id="machine_header">
            <h1>Máquina: {get(machine, "name", "")}</h1>
          </Card.Header>

          <Card.Meta>
            <h4>
              Prioridade de atendimento:{" "}
              {get(
                MACHINE_LEVEL_OPTIONS.find(
                  ({ value }) => value === get(machine, "level", 0)
                ),
                "text"
              )}
            </h4>
            {activePriorityName &&
              (status === STATUS.WAITING || status === STATUS.ATTENDING) && (
                <h3>
                  Nível de escalonamento:{" "}
                  <span style={{ color: "rgba(0, 0, 0, 0.87)" }}>
                    {activePriorityName}
                  </span>
                </h3>
              )}
          </Card.Meta>

          <LastOccurrence
            occurrence={
              activeOccurrence
                ? activeOccurrence
                : get(machine, "last_occurrence", undefined)
            }
          />

          <div>
            <label style={{ fontSize: "20px", color: "#9b9b9b" }}>
              Tempo decorrido:
            </label>

            <OccurrenceTimerCounter
              occurrence={activeOccurrence}
              color={timerCounterColor}
              style={{
                fontSize: "3.4em",
                lineHeight: "1em",
                fontWeight: "bold",
              }}
            />
          </div>

          <CallTechnicianButton
            onCancelClick={() => this.setState({ confirmOpen: true })}
            onConfirmClick={() => !loading && this.props.nextStateOccurrence()}
          />
          <Button
            style={{ marginTop: "20px", width: "100%", maxWidth: "350px" }}
            disabled={status !== STATUS.WAITING && status !== STATUS.ATTENDING}
            onClick={() => !loading && this.props.escalateOccurrence()}
            loading={loading}
            primary
          >
            Escalonar manualmente
          </Button>
        </Card.Content>

        {isActiveOccurrence(activeOccurrence) ? (
          <CallTechnicianOccurrenceActivities
            occurrenceId={activeOccurrence.id}
          />
        ) : (
          <Card.Content
            style={{ flex: "1", backgroundColor: "rgb(248, 248, 249)" }}
          >
            <CallTechnicianForm
              submit={(data) => this.handleFormSubmit(data)}
              disabled={
                status === STATUS.WAITING || status === STATUS.ATTENDING
              }
              sectors={sectors}
              sectorsLoading={sectorsLoading}
            />
          </Card.Content>
        )}

        <Confirm
          size="tiny"
          open={this.state.confirmOpen}
          content="Tem certeza que deseja cancelar o chamado?"
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.setState({ confirmOpen: false })}
          onConfirm={this.handleConfirmConfirm}
        />

        <ListAudioPlayer
          sources={soundUrl ? [soundUrl] : []}
          muted={status !== STATUS.WAITING}
          delayMS={0}
        />
      </CardWithMarker>
    );
  }
}

function mapStateToProps(state) {
  return {
    sectors: getSectors(state),
    sectorsLoading: getLoadingSectors(state),
    activeOccurrence: getActiveOccurrence(state),
    machine: getSelectedMachine(state),
    loading: getLoading(state),
  };
}

export default connect(mapStateToProps, {
  fetchAllSectors,
  registerOccurrence,
  onCanceledOccurrence,
  nextStateOccurrence,
  escalateOccurrence,
  setErrorMessage,
})(CallTechnicianCard);
