import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Form } from "semantic-ui-react";

import DateRangePicker from "./DateRangePicker";

import {
  getMachines,
  fetchAll as fetchAllMachines,
  getLoading as getMachinesLoading,
} from "../../Machines";

import {
  getPriorities,
  fetchAll as fetchAllPriorities,
  getLoading as getPrioritiesLoading,
} from "../../Priorities";

import {
  getSectors,
  fetchAll as fetchAllSectors,
  getLoading as getSectorsLoading,
} from "../../Sectors";

import { getFilter, filterChanged } from "../ducks/occurrences";

import { OCCURRENCE_STATUS as STATUS } from "../../../constants";

const STATUS_OPTIONS = [
  { key: 0, value: 0, text: "Todos" },
  { key: STATUS.WAITING, value: STATUS.WAITING, text: "Em espera" },
  { key: STATUS.ATTENDING, value: STATUS.ATTENDING, text: "Em atendimento" },
  { key: STATUS.FINISHED, value: STATUS.FINISHED, text: "Finalizado" },
  { key: STATUS.CANCELED, value: STATUS.CANCELED, text: "Cancelado" },
];

class OccurrenceTableFilter extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleRangePicked = this.handleRangePicked.bind(this);
  }

  onChange(e, { name, value }) {
    this.props.filterChanged({ name, value });
  }

  handleRangePicked(startDate, endDate) {
    this.props.filterChanged({
      name: "date",
      value: [
        moment(startDate).format("YYYYMMDD"),
        moment(endDate).format("YYYYMMDD"),
      ],
    });
  }

  componentDidMount() {
    this.props.fetchAllMachines();
    this.props.fetchAllPriorities();
    this.props.fetchAllSectors();
  }

  render() {
    const {
      machines,
      machinesLoading,
      priorities,
      prioritiesLoading,
      sectorsLoading,
      sectors,
      filter,
    } = this.props;

    const machineOptions = [
      { key: 0, value: 0, text: "Todas" },
      ...machines.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      })),
    ];

    const prioritiesOptions = [
      { key: 0, value: 0, text: "Todas" },
      ...priorities.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      })),
    ];

    const sectorOptions = [
      { key: 0, value: 0, text: "Todos" },
      ...sectors.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      })),
    ];

    return (
      <Form id="occurrences_filter">
        <Form.Group style={{ marginBottom: "0" }}>
          <Form.Field>
            <label>Data</label>
            <DateRangePicker onRangePicked={this.handleRangePicked} />
          </Form.Field>
          <Form.Dropdown
            label="Setor"
            placeholder="Selecione um setor"
            selection
            loading={sectorsLoading}
            options={sectorOptions}
            name="sector_id"
            value={filter.sector_id}
            onChange={this.onChange}
          />
          <Form.Dropdown
            label="Nível de escalonamento"
            placeholder="Nível de escalonamento"
            selection
            loading={prioritiesLoading}
            options={prioritiesOptions}
            name="priority_id"
            value={filter.priority_id}
            onChange={this.onChange}
          />
          <Form.Dropdown
            label="Máquina"
            placeholder="Máquina"
            selection
            search
            options={machineOptions}
            loading={machinesLoading}
            name="machine_id"
            value={filter.machine_id}
            onChange={this.onChange}
          />
          <Form.Dropdown
            label="Status"
            placeholder="Status"
            options={STATUS_OPTIONS}
            selection
            name="status"
            value={filter.status}
            onChange={this.onChange}
          />
        </Form.Group>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    filter: getFilter(state),
    machines: getMachines(state),
    machinesLoading: getMachinesLoading(state),
    priorities: getPriorities(state),
    prioritiesLoading: getPrioritiesLoading(state),
    sectors: getSectors(state),
    sectorsLoading: getSectorsLoading(state),
  };
}

export default connect(mapStateToProps, {
  filterChanged,
  fetchAllMachines,
  fetchAllPriorities,
  fetchAllSectors,
})(OccurrenceTableFilter);
