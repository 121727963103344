import React from "react";
import PropTypes from "prop-types";

import validator from "validator";

import { Form, Icon, Label, Message, Popup } from "semantic-ui-react";

import { SECTOR_COLORS } from "../constants";

import getStringFromKeyCode from "../../../utils/getStringFromKeyCode";

class SectorEditForm extends React.Component {
  state = {
    data: {
      name: "",
      color: "red",
      sound: "",
      hotkey: ""
    },
    hotkeyInputFinished: true,
    errors: []
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onKeyUpHandler = this.onKeyUpHandler.bind(this);
  }

  onChange(e, props) {
    const name = props.name;
    const value = props.value;

    this.setState({ data: { ...this.state.data, [name]: value } });
  }

  validate() {
    const { data } = this.state;

    let errors = [];

    if (validator.isEmpty(data.name)) {
      errors.push({ field: "name", message: "Nome é obrigatório" });
    }

    return errors;
  }

  onSubmit() {
    const errors = this.validate();

    this.setState({ errors });

    if (errors.length === 0) {
      const { data } = this.state;

      let formData = new FormData();

      formData.append("name", data.name);
      formData.append("color", data.color);
      formData.append("hotkey", data.hotkey);

      return this.props.submit(formData);
    } else {
      return Promise.reject();
    }
  }

  onKeyUpHandler(e) {
    this.setState({ hotkeyInputFinished: true });
  }

  onKeyDownHandler(e) {
    if (e.repeat) return;

    if (e.key === "Backspace") {
      this.setState({ data: { ...this.state.data, hotkey: "" } });
      return;
    }

    const key = getStringFromKeyCode(e.keyCode);
    if (!key) {
      return;
    }

    let hotkey = "";
    if (this.state.data.hotkey && !this.state.hotkeyInputFinished) {
      hotkey = this.state.data.hotkey + "+" + key;
    } else {
      hotkey = key;
    }

    this.setState({
      data: { ...this.state.data, hotkey: hotkey },
      hotkeyInputFinished: false
    });
  }

  componentDidMount() {
    const { sector } = this.props;
    if (sector) {
      this.setState({
        data: {
          ...sector,
          hotkey: sector.hotkey ? sector.hotkey : ""
        }
      });
    }
  }

  render() {
    const { data, errors } = this.state;

    const errorsFields = errors.map(e => e.field);

    return (
      <Form error={errorsFields.length > 0}>
        <Form.Input
          label="Nome"
          type="text"
          name="name"
          value={data.name}
          onChange={this.onChange}
          placeholder="Ex: Manutenção, Brigada de Incêndio"
          required
          autoFocus
          error={errorsFields.indexOf("name") !== -1}
        />

        <Form.Dropdown
          trigger={<Label color={data.color} />}
          label="Cor"
          name="color"
          onChange={this.onChange}
          value={data.color}
          selection
          width={2}
          compact
          options={SECTOR_COLORS.map(c => ({
            label: { color: c.color },
            key: c.color,
            value: c.color,
            text: ""
          }))}
        />

        <Form.Field>
          <label>
            Atalho
            <Popup
              trigger={<Icon name="help circle" size="large" color="blue" />}
              content="Atalhos para chamada de técnico. Pressione as teclas na sequência correta, para limpar o campo basta começar a sequência novamente. Os atalhos funcionam apenas na aplicação desktop."
              basic
            />
          </label>
          <Form.Input
            name="hotkey"
            onKeyDown={this.onKeyDownHandler}
            onKeyUp={this.onKeyUpHandler}
            value={data.hotkey}
            width={4}
          />
        </Form.Field>

        <Message error list={errors.map(e => e.message)} />
      </Form>
    );
  }
}

SectorEditForm.propTypes = {
  submit: PropTypes.func.isRequired,
  sector: PropTypes.shape({
    name: PropTypes.string
  })
};

export default SectorEditForm;
