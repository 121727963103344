export const SECTOR_FETCHING = "Sectors/SECTOR_FETCHING";
export const SECTOR_FETCHED = "Sectors/SECTOR_FETCHED";
export const SECTOR_CREATED = "Sectors/SECTOR_CREATED";
export const SECTOR_UPDATED = "Sectors/SECTOR_UPTDATED";
export const SECTOR_DELETED = "Sectors/SECTOR_DELETED";

export const SECTOR_COLORS = [
    { color: "red", text: "vermelho", rgb: "#ffecec" },
    { color: "orange", text: "laranja", rgb: "#fbd3bb" },
    { color: "yellow", text: "amarelo", rgb: "#feeab7" },
    { color: "green", text: "verde", rgb: "#bce9c6" },
    { color: "blue", text: "azul", rgb: "#bcd9ef" },
    { color: "violet", text: "violeta", rgb: "#cfc2ee" },
    { color: "purple", text: "roxo", rgb: "#e1c1ed" },
    { color: "pink", text: "rosa", rgb: "#f5c2de" },
    { color: "brown", text: "marrom", rgb: "#e2d0c4" },
    { color: "grey", text: "cinza", rgb: "#d4d4d4" },
    { color: "black", text: "preto", rgb: "#bbbcbb" }
];

export const REDUCER_NAME = "sectors";
