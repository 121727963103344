import React from "react";
import _get from "lodash/get";

class Expandable extends React.Component {
  constructor(props) {
    super(props);

    this.contentRef = React.createRef();

    this.state = {
      maxHeight: null
    };
  }

  componentDidMount() {
    this.setState({
      maxHeight: _get(this.contentRef.current, "scrollHeight")
    });
  }

  render() {
    const { isVisible } = this.props;

    return (
      <div
        style={{
          transition: "all .3s ease-out",
          opacity: isVisible ? 1 : 0,
          maxHeight: isVisible
            ? _get(
                this.contentRef.current,
                "scrollHeight",
                this.state.maxHeight
              )
            : 0,
          overflow: isVisible ? "visible" : "hidden"
        }}
      >
        <div ref={this.contentRef}>{this.props.children}</div>
      </div>
    );
  }
}

export default Expandable;
