import React from "react";
import get from "lodash/get";

import { Button, Icon, Table } from "semantic-ui-react";

import { SortableTableHeader } from "../../Shared";

const HEADERS = [
    { name: null, label: "Setor" },
    { name: null, label: "Nível de escalonamento" },
    { name: null, label: "Máquina" },
    { name: null, label: "Ação" }
];

class EditConditionTable extends React.Component {
    renderTableBody() {
        const { conditions } = this.props;

        return (
            <Table.Body>
                {conditions.map((condition, key) => (
                    <Table.Row key={key}>
                        <Table.Cell>
                            {get(condition, "sector.name", "Todos")}
                        </Table.Cell>
                        <Table.Cell>
                            {get(condition, "priority.name", "Todos")}
                        </Table.Cell>
                        <Table.Cell>
                            {get(condition, "machine.name", "Todas")}
                        </Table.Cell>
                        <Table.Cell>
                            <Button
                                icon
                                basic
                                onClick={() => this.props.onDeleteClick(key)}
                            >
                                <Icon name="delete" />
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        );
    }

    render() {
        return (
            <Table selectable>
                <SortableTableHeader headers={HEADERS} />
                {this.renderTableBody()}
            </Table>
        );
    }
}

export default EditConditionTable;
