import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Confirm } from "semantic-ui-react";

import get from "lodash/get";

import userHasPage from "../../../utils/userHasPage";

import { FormModal } from "../../Shared";
import { getUser } from "../../Auth";

import MachinesTableContainer from "./MachinesTableContainer";
import MachineEditForm from "./MachineEditForm";

import {
  getFormOpen,
  getEditMachine,
  getConfirmOpen,
  confirmDelete,
  cancelDelete,
  submitEdit,
  cancelEdit,
  fetchAll
} from "../ducks/machine";

class MachinesPage extends Component {
  componentDidMount() {
    this.props.fetchAll();
  }

  render() {
    const { canEdit, formOpen, confirmOpen, editMachine } = this.props;

    return (
      <Container fluid>
        <MachinesTableContainer canEdit={canEdit} />

        <FormModal
          id="edit_modal"
          size="tiny"
          open={formOpen}
          onClose={() => this.props.cancelEdit()}
          formRef={() => this.form}
        >
          <MachineEditForm
            ref={form => (this.form = form)}
            machine={editMachine}
            submit={data => this.props.submitEdit(data)}
          />
        </FormModal>

        <Confirm
          open={confirmOpen}
          content={`Tem certeza que deseja excluir a máquina: ${get(
            editMachine,
            "name"
          )}?`}
          confirmButton="Sim"
          cancelButton="Não"
          onCancel={() => this.props.cancelDelete()}
          onConfirm={() => this.props.confirmDelete()}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const user = getUser(state);

  return {
    formOpen: getFormOpen(state),
    editMachine: getEditMachine(state),
    confirmOpen: getConfirmOpen(state),
    canEdit: userHasPage(user, "machines", true)
  };
}

export default connect(mapStateToProps, {
  confirmDelete,
  cancelDelete,
  submitEdit,
  cancelEdit,
  fetchAll
})(MachinesPage);
