const SPECIAL_KEY_CODES = {
    16: "Shift",
    17: "Ctrl",
    18: "Alt",
    112: "F1",
    113: "F2",
    114: "F3",
    115: "F4",
    116: "F5",
    117: "F6",
    118: "F7",
    119: "F8",
    120: "F9",
    121: "F10",
    122: "F11",
    123: "F12"
};

export default (keyCode) => {
    if (SPECIAL_KEY_CODES[keyCode]) {
        return SPECIAL_KEY_CODES[keyCode];
    } else if (keyCode >= 49 && keyCode <= 90) {
        return String.fromCharCode(keyCode);
    } else {
        return false;
    }
}
