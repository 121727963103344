import React from "react";
import PropTypes from "prop-types";

import TimerCounter from "./TimerCounter";

import Occurrence from "../../../models/Occurrence";

const OccurrenceTimerCounter = ({ occurrence, ...rest }) => {
    if (!occurrence) {
        return <TimerCounter start={new Date()} value={0} paused {...rest} />;
    }

    const start =
        occurrence.arrived_on && !occurrence.isFinished()
            ? occurrence.arrived_on
            : occurrence.started_on;

    let value = undefined;
    if (occurrence.isFinished()) {
        const end = occurrence.isSuccess()
            ? occurrence.finished_on
            : occurrence.canceled_on;
        value = Math.round((end - start) / 1000);
    }

    return (
        <TimerCounter
            start={start}
            paused={occurrence.isFinished()}
            value={value}
            {...rest}
        />
    );
};

OccurrenceTimerCounter.propTypes = {
    occurrence: PropTypes.instanceOf(Occurrence)
};

export default OccurrenceTimerCounter;
