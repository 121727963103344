import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid, Button, Dimmer, Loader, Table, Icon } from "semantic-ui-react";

import get from "lodash/get";

import { SortableTableHeader } from "../../Shared";

import {
  getMachines,
  getLoading,
  addClick,
  editClick,
  deleteClick
} from "../ducks/machine";
import { MACHINE_LEVEL_OPTIONS } from "../../../constants";

const HEADERS = [
  { name: null, label: "Nome" },
  { name: null, label: "Prioridade" },
  { name: null, label: "Grupo" },
  { name: null, label: "Ações" }
];

class MachinesTable extends Component {
  renderTableBody() {
    const { machines, canEdit } = this.props;
    return (
      <Table.Body>
        {machines.map((machine, key) => (
          <Table.Row key={machine.id}>
            <Table.Cell>{machine.name}</Table.Cell>
            <Table.Cell>
              {get(
                MACHINE_LEVEL_OPTIONS.find(
                  ({ value }) => value === machine.level
                ),
                "text"
              )}
            </Table.Cell>
            <Table.Cell>{get(machine, "group.name", "")}</Table.Cell>
            <Table.Cell width={3}>
              <Button
                icon
                basic
                disabled={!canEdit}
                onClick={() => this.props.editClick(machine)}
                style={{ marginRight: "20px" }}
              >
                <Icon name="edit" />
              </Button>

              <Button
                icon
                basic
                disabled={!canEdit}
                onClick={() => this.props.deleteClick(machine)}
                style={{ marginRight: "20px" }}
              >
                <Icon name="delete" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  render() {
    const { loading, canEdit } = this.props;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h1>Máquinas</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <Dimmer active={loading} inverted>
              <Loader />
            </Dimmer>
            <Button
              className="add-machine-btn"
              primary
              icon="add"
              content="Nova máquina"
              onClick={() => this.props.addClick()}
              disabled={!canEdit}
            />
            <Table selectable>
              <SortableTableHeader headers={HEADERS} />
              {this.renderTableBody()}
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    machines: getMachines(state),
    loading: getLoading(state)
  };
}

export default connect(
  mapStateToProps,
  {
    addClick,
    editClick,
    deleteClick
  }
)(MachinesTable);
