export function millisecondsToString(milliseconds) {
    const seconds = Math.floor((milliseconds / 1000) % 60).toString();
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60).toString();
    const hours = Math.floor(milliseconds / 1000 / 60 / 60).toString();

    return (
        hours.padStart(2, "0") +
        ":" +
        minutes.padStart(2, "0") +
        ":" +
        seconds.padStart(2, "0")
    );
}

export function secondsToString(seconds_elapsed) {
    const seconds = Math.floor(seconds_elapsed % 60).toString();
    const minutes = Math.floor((seconds_elapsed / 60) % 60).toString();
    const hours = Math.floor(seconds_elapsed / 60 / 60).toString();

    return (
        hours.padStart(2, "0") +
        ":" +
        minutes.padStart(2, "0") +
        ":" +
        seconds.padStart(2, "0")
    );
}
