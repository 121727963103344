import React from "react";
import PropTypes from "prop-types";
import { Progress } from "semantic-ui-react";

//TODO: Refactor this, extract HOC with elapsed and pause logic
class TimerProgress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            elapsed: 0
        };

        this.tick = this.tick.bind(this);
    }

    tick() {
        const { start } = this.props;
        
        if(!start) return;

        this.setState({
            elapsed: Math.round((Date.now() - start.getTime()) / 1000)
        });
    }

    componentDidMount() {
        if (this.props.paused) {
            this.tick();
        } else {
            this.timer = setInterval(this.tick, 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const { paused, start, value, ...rest } = this.props;

        const elapsed =
            value !== undefined ? value : Math.max(this.state.elapsed, 1);
        const total = Math.max(this.props.total, 1);

        return (
            <Progress
                value={elapsed}
                total={total}
                indicating={!paused}
                {...rest}
            />
        );
    }
}

TimerProgress.propTypes = {
    start: PropTypes.instanceOf(Date).isRequired,
    total: PropTypes.number.isRequired,
    paused: PropTypes.bool,
    value: PropTypes.number
};

TimerProgress.defaultProps = {
    start: new Date(),
    total: 100
};

export default TimerProgress;
