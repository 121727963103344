import { createAction } from "redux-actions";
import { createSelector } from "reselect";
import _nth from "lodash/nth";

import api from "../../../api";

import fileDownload from "../../../utils/downloadFile";

import {
  REDUCER_NAME,
  OCCURRENCES_FETCHED,
  FILTER_CHANGED,
  SORT_CHANGED,
  PAGE_CHANGED,
  STATS_LOADING,
  DOWNLOAD_LOADING,
  DOWNLOAD_DONE,
  OCCURRENCES_LOADING,
  STATS_FETCHED,
  CLOSE_EDIT,
  EDIT_CLICK,
  DELETE_CLICK,
  DELETE_CONFIRM,
  DELETE_CANCELED,
  DELETE_SUCCESS,
} from "../constants";

export const statsLoading = createAction(STATS_LOADING);
export const downloadLoading = createAction(DOWNLOAD_LOADING);
export const downloadDone = createAction(DOWNLOAD_DONE);
export const occurrencesLoading = createAction(OCCURRENCES_LOADING);
export const occurrencesFetched = createAction(OCCURRENCES_FETCHED);
export const statsFetched = createAction(STATS_FETCHED);
export const filterChanged = createAction(FILTER_CHANGED);
export const sortChanged = createAction(SORT_CHANGED);
export const pageChanged = createAction(PAGE_CHANGED);
export const closeEdit = createAction(CLOSE_EDIT);
export const editClick = createAction(EDIT_CLICK);

export const deleteClick = createAction(DELETE_CLICK);
export const cancelDelete = createAction(DELETE_CANCELED);
const deleteSuccess = createAction(DELETE_SUCCESS);

export const fetchAllOccurrences = (params) => (dispatch) => {
  dispatch(occurrencesLoading());

  return api.occurrences
    .fetchAll(params)
    .then((occurrences) => dispatch(occurrencesFetched(occurrences)));
};

export const fetchStats = (params) => (dispatch) => {
  dispatch(statsLoading());

  return api.occurrences
    .stats(params)
    .then((stats) => dispatch(statsFetched(stats)));
};

export const downloadExport = (filename, mime) => (dispatch) => {
  dispatch(downloadLoading());
  return api.occurrences
    .export()
    .then((data) => fileDownload(data, filename, mime))
    .then(() => dispatch(downloadDone()));
};

export const submitEdit = (data) => (dispatch, getState) => {
  const selected = getSelected(getState());
  const params = getParams(getState());

  return api.occurrences
    .update(selected.id, data)
    .then(() => dispatch(closeEdit()))
    .then(() => {
      dispatch(fetchAllOccurrences(params));
      dispatch(fetchStats(params));
    });
};

export const confirmDelete = () => (dispatch, getState) => {
  const selected = getSelected(getState());
  const params = getParams(getState());

  if (!selected) return;

  dispatch({ type: DELETE_CONFIRM });

  return api.occurrences
    .delete(selected.id)
    .then(() => dispatch(deleteSuccess()))
    .then(() => {
      dispatch(fetchAllOccurrences(params));
      dispatch(fetchStats(params));
    });
};

const initialState = {
  selected: null,
  formOpen: false,
  deleteConfirmOpen: false,
  downloadLoading: false,
  loading: false,
  occurrences: [],
  meta: {},
  statsLoading: false,
  stats: {
    number: 0,
    avg_arriving: 0,
    avg_attending: 0,
  },
  page: 1,
  order_by: "started_on",
  order: "descending",
  filter: {
    priority_id: 0,
    machine_id: 0,
    sector_id: 0,
    status: 0,
    date: [],
  },
};

export default function occurrence(state = initialState, action = {}) {
  switch (action.type) {
    case DOWNLOAD_LOADING:
      return { ...state, downloadLoading: true };
    case DOWNLOAD_DONE:
      return { ...state, downloadLoading: false };
    case OCCURRENCES_LOADING:
      return { ...state, loading: true };
    case OCCURRENCES_FETCHED:
      return {
        ...state,
        loading: false,
        occurrences: action.payload.data,
        meta: action.payload.meta,
      };
    case STATS_LOADING:
      return { ...state, statsLoading: true };
    case STATS_FETCHED:
      return { ...state, statsLoading: false, stats: action.payload };
    case SORT_CHANGED:
      let direction = "ascending";
      if (action.payload === state.order_by) {
        direction = state.order === "ascending" ? "descending" : "ascending";
      }

      return { ...state, order: direction, order_by: action.payload };
    case FILTER_CHANGED:
      const { name, value } = action.payload;
      return { ...state, filter: { ...state.filter, [name]: value } };
    case PAGE_CHANGED:
      return { ...state, page: action.payload };
    case EDIT_CLICK:
      return { ...state, formOpen: true, selected: action.payload };
    case CLOSE_EDIT:
      return { ...state, formOpen: false, selected: null };
    case DELETE_CLICK:
      return { ...state, deleteConfirmOpen: true, selected: action.payload };
    case DELETE_CONFIRM:
      return {
        ...state,
        deleteConfirmOpen: false,
        selected: null,
        loading: true,
      };
    case DELETE_CANCELED:
      return { ...state, deleteConfirmOpen: false, selected: null };
    default:
      return state;
  }
}

export const getDownloadLoading = (state) =>
  state[REDUCER_NAME].downloadLoading;

export const getLoading = (state) => state[REDUCER_NAME].loading;
export const getOccurrences = (state) => state[REDUCER_NAME].occurrences;
export const getMeta = (state) => state[REDUCER_NAME].meta;

export const getStats = (state) => state[REDUCER_NAME].stats;
export const getStatsLoading = (state) => state[REDUCER_NAME].statsLoading;
export const getSelected = (state) => state[REDUCER_NAME].selected;
export const getEditFormOpen = (state) => state[REDUCER_NAME].formOpen;

export const getDeleteConfirmOpen = (state) =>
  state[REDUCER_NAME].deleteConfirmOpen;

export const getOrder = (state) => state[REDUCER_NAME].order;
export const getOrderBy = (state) => state[REDUCER_NAME].order_by;
export const getFilter = (state) => state[REDUCER_NAME].filter;
export const getPage = (state) => state[REDUCER_NAME].page;

export const getParams = createSelector(
  [getOrder, getOrderBy, getPage, getFilter],
  (order, order_by, page, filter) => ({
    order: order === "ascending" ? "asc" : "desc",
    order_by,
    page,
    priority_id: filter.priority_id,
    machine_id: filter.machine_id,
    sector_id: filter.sector_id,
    status: filter.status,
    startDate: _nth(filter.date, 0),
    endDate: _nth(filter.date, 1),
  })
);
