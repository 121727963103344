import React, { Component } from "react";
import Raven from "raven-js";

import oops from "../../../assets/imgs/oops.jpg";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Raven.captureException(error, { extra: errorInfo });
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
                <div
                    className="snap"
                    onClick={() =>
                        Raven.lastEventId() && Raven.showReportDialog()
                    }
                >
                    <img src={oops} alt="Oops" />
                    <p>Sentimos muito — algo deu errado.</p>
                    <p>
                        Nosso time foi notificado do erro, mas clique aqui para
                        enviar uma descrição do que houve.
                    </p>
                </div>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
