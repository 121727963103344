import React from "react";
import { connect } from "react-redux";

import get from "lodash/get";

import { Button } from "semantic-ui-react";

import { getActiveOccurrence, getLoading } from "../ducks/call-technician";

import { OCCURRENCE_STATUS as STATUS } from "../../../constants";

const BUTTON_COLOR_STATUS_MAP = {
  [STATUS.WAITING]: "red",
  [STATUS.ATTENDING]: "yellow",
};

const CONFIRM_LABEL_STATUS_MAP = {
  [STATUS.WAITING]: "Registrar chegada do Técnico",
  [STATUS.ATTENDING]: "Finalizar atendimento",
};

class CallTechnicianButton extends React.Component {
  render() {
    const { activeOccurrence, loading } = this.props;
    const status = get(activeOccurrence, "status", STATUS.FINISHED);

    const buttonColor = BUTTON_COLOR_STATUS_MAP[status];
    const confirmLabel = CONFIRM_LABEL_STATUS_MAP[status];

    const disabled = status !== STATUS.WAITING && status !== STATUS.ATTENDING;

    return (
      <div
        style={{
          display: "flex",
          marginTop: "0.5em",
          maxWidth: "350px",
        }}
      >
        <Button
          disabled={disabled}
          icon="cancel"
          onClick={() => this.props.onCancelClick()}
          style={{
            margin: "0",
            borderRadius: ".28571429rem 0 0 .28571429rem",
          }}
        />
        <Button
          className="call-technician-btn"
          loading={loading}
          disabled={disabled}
          fluid={true}
          color={buttonColor ? buttonColor : "green"}
          onClick={() => this.props.onConfirmClick()}
          style={{
            margin: "0",
            borderRadius: "0 .28571429rem .28571429rem 0",
          }}
        >
          {confirmLabel ? confirmLabel : "Nenhum chamado ativo"}
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeOccurrence: getActiveOccurrence(state),
    loading: getLoading(state),
  };
}

export default connect(mapStateToProps)(CallTechnicianButton);
