import React from "react";

import { connect } from "react-redux";

import { Rail, Header, List, Label, Loader } from "semantic-ui-react";

import {
  fetchAll as fetchAllSectors,
  getSectors,
  getLoading as getSectorsFetching
} from "../../Sectors";

class OverviewLegend extends React.Component {
  componentDidMount() {
    this.props.fetchAllSectors();
  }

  renderSectorItems() {
    const { sectors, sectorsLoading } = this.props;

    if (sectorsLoading) {
      return <Loader active />;
    }

    return sectors.map(sector => (
      <List.Item key={sector.id}>
        <List.Icon>
          <Label color={sector.color} />
        </List.Icon>
        <List.Content>{sector.name}</List.Content>
      </List.Item>
    ));
  }

  renderStatusItems() {
    return [
      <List.Item key="check circle">
        <List.Icon name="check circle" />
        <List.Content>Sem chamado</List.Content>
      </List.Item>,
      <List.Item key="exclamation triangle">
        <List.Icon name="exclamation triangle" />
        <List.Content>Aguardando atendimento</List.Content>
      </List.Item>,
      <List.Item key="wrench">
        <List.Icon name="wrench" />
        <List.Content>Em atendimento</List.Content>
      </List.Item>
    ];
  }

  render() {
    return (
      <Rail
        attached
        internal
        position="right"
        style={{ width: "200px", right: "1rem" }}
      >
        <Header>Legenda</Header>
        <List relaxed divided>
          {this.renderSectorItems()}
          {this.renderStatusItems()}
        </List>
      </Rail>
    );
  }
}

function mapStateToProps(state) {
  return {
    sectors: getSectors(state),
    sectorsLoading: getSectorsFetching(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSectors: () => dispatch(fetchAllSectors)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewLegend);
