import React from "react";
import PropTypes from "prop-types";

import get from "lodash/get";

import TimerProgress from "./TimerProgress";

import Occurrence from "../../../models/Occurrence";

const OccurrenceTimerProgress = ({ occurrence, ...rest }) => {
    if (!occurrence) {
        return null;
    }

    let start = 0;

    if (occurrence.isFinished()) {
        // else if is finished use arrived_on, seconds in sync
        start = occurrence.arrived_on;
    } else {
        // if not finished and not just created, use updated_at
        // by priority change, seconds will not be in sync with client clock
        start = occurrence.updated_at;
    }

    const total = occurrence.isArrived()
        ? get(occurrence, "priority.arrived_timeout_s", 0)
        : get(occurrence, "priority.started_timeout_s", 0);
    const value = occurrence.isFinished()
        ? Math.round((occurrence.finished_on - start) / 1000)
        : undefined;

    return (
        <TimerProgress
            start={start}
            total={total}
            paused={occurrence.isFinished()}
            value={value}
            className="reverse"
            {...rest}
        />
    );
};

OccurrenceTimerProgress.propTypes = {
    occurrence: PropTypes.instanceOf(Occurrence).isRequired
};

export default OccurrenceTimerProgress;
