import axios from "axios";
import * as storage from "../utils/storage";

export default {
  login: ({ username, password }) => {
    const body = {
      grant_type: "password",
      client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
      username: username,
      password: password,
      scope: "*",
    };

    return axios.post("/oauth/token", body).then((response) => response.data);
  },

  signup: (data) => {
    return axios.post("/api/v1/signup", data).then((response) => response.data);
  },

  getSocketToken: () => {
    if (storage.get("socket_token")) {
      return Promise.resolve(storage.get("socket_token"));
    }

    return axios.get("/api/v1/socket/token").then((response) => {
      storage.set("socket_token", response.data.socket_token);
      return response.data.socket_token;
    });
  },

  registerDevice: (token) => {
    const body = {
      platform: "web",
      fcm_token: token,
    };
    return axios
      .post("api/v1/users/devices", body)
      .then((response) => response.data);
  },
};
