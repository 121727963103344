import React from "react";
import { connect } from "react-redux";

import get from "lodash/get";

import {
  Container,
  Button,
  Dimmer,
  Label,
  Loader,
  Table
} from "semantic-ui-react";

import { getUser } from "../../Auth";
import userHasPage from "../../../utils/userHasPage";

import { SortableTableHeader } from "../../Shared";

import {
  getLoading,
  getRules,
  fetchAll,
  editClick,
  deleteClick
} from "../ducks/filter-rules";

const HEADERS = [
  { name: null, label: "Nome" },
  { name: null, label: "Condições  (setor - escalonamento - grupo - máquina)" },
  { name: null, label: "Ações" }
];

class TableContainer extends React.Component {
  componentDidMount() {
    this.props.fetchAll();
  }

  renderConditionLabel(condition) {
    return (
      <Label key={condition.id} style={{ margin: "6px" }}>
        {get(condition, "sector.name", "Todos") +
          " - " +
          get(condition, "priority.name", "Todos") +
          " - " +
          get(condition, "machine_group.name", "Todas") +
          " - " +
          get(condition, "machine.name", "Todas")}
      </Label>
    );
  }

  renderTableBody() {
    const { rules, canEdit } = this.props;

    return (
      <Table.Body>
        {rules.map((rule, key) => (
          <Table.Row key={rule.id}>
            <Table.Cell>{rule.name}</Table.Cell>
            <Table.Cell>
              {rule.conditions.map(condition =>
                this.renderConditionLabel(condition)
              )}
            </Table.Cell>
            <Table.Cell width={3}>
              <Button
                basic
                icon="edit"
                disabled={!canEdit}
                onClick={() => this.props.editClick(rule)}
                style={{ marginRight: "20px" }}
              />
              <Button
                basic
                icon="delete"
                disabled={!canEdit}
                onClick={() => this.props.deleteClick(rule)}
                style={{ marginRight: "20px" }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  render() {
    const { loading } = this.props;
    return (
      <Container fluid>
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        <Table selectable>
          <SortableTableHeader headers={HEADERS} />
          {this.renderTableBody()}
        </Table>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const user = getUser(state);

  return {
    canEdit: userHasPage(user, "notification-rules", true),
    loading: getLoading(state),
    rules: getRules(state)
  };
}

export default connect(mapStateToProps, { fetchAll, editClick, deleteClick })(
  TableContainer
);
