import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Joyride, { EVENTS } from "react-joyride";

import { getStepIndex, getRun, joyrideCallback } from "../ducks/tutorial";
import { STEPS } from "../constants";

class AppTutorial extends Component {
  handleJoyrideCallback = (data) => {
    const { type } = data;

    if (type === EVENTS.STEP_AFTER && data.index === 1) {
      this.props.history.push("/machines");
    } else if (type === EVENTS.STEP_AFTER && data.index === 4) {
      this.props.history.push("/sectors");
    } else if (type === EVENTS.STEP_AFTER && data.index === 7) {
      this.props.history.push("/priorities");
    } else if (type === EVENTS.STEP_AFTER && data.index === 10) {
      this.props.history.push("/call-technician");
    }

    this.props.joyrideCallback(data);
  };

  render() {
    const { run, stepIndex } = this.props;

    return (
      <Joyride
        ref={(joyride) => (this.joyride = joyride)}
        continuous
        floaterProps={{
          styles: {
            floater: {
              transform: "scale(0.1)",
            },
            floaterOpening: {
              transform: "perspective(1px) scale(1)",
            },
            floaterWithAnimation: {
              transform: "perspective(1px) scale(1)",
            },
            floaterCentered: {
              transform: "perspective(1px) scale(1) translate(-50%, -50%)",
            },
          },
        }}
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        steps={STEPS}
        stepIndex={stepIndex}
        callback={this.handleJoyrideCallback}
        locale={{
          back: "Voltar",
          close: "Sair",
          last: "Último",
          next: "Próximo",
          skip: "Pular",
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    stepIndex: getStepIndex(state),
    run: getRun(state),
  };
}

export default compose(
  connect(mapStateToProps, { joyrideCallback }),
  withRouter
)(AppTutorial);
