import React from "react";
import PropTypes from "prop-types";

import validator from "validator";

import { Message, Form } from "semantic-ui-react";

class PasswordResetForm extends React.Component {
    state = {
        data: {
            password: "",
            password_confirmation: ""
        },
        errors: []
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = (e, props) => {
        const value = props.value;
        const name = props.name;

        this.setState({
            data: { ...this.state.data, [name]: value }
        });
    };

    validate = () => {
        const { data } = this.state;

        let errors = [];

        // If user is new instead of updating, check for password validation
        if (validator.isEmpty(data.password)) {
            errors.push({
                field: "password",
                message: "Senha é obrigatório"
            });
        }

        if (!validator.equals(data.password, data.password_confirmation)) {
            errors.push({
                field: "password_confirmation",
                message: "Senhas não conferem"
            });
        }

        return errors;
    };

    onSubmit = () => {
        const errors = this.validate();

        this.setState({ errors });

        if (errors.length === 0) {
            return this.props.submit(this.state.data);
        } else {
            return Promise.reject();
        }
    };

    render() {
        const { data, errors } = this.state;

        const errorsFields = errors.map(e => e.field);

        return (
            <Form onSubmit={this.onSubmit} error={errorsFields.length > 0}>
                <Form.Group widths="equal">
                    <Form.Input
                        label="Senha"
                        type="password"
                        name="password"
                        value={data.password}
                        onChange={this.onChange}
                        placeholder="Senha"
                        required
                        error={errorsFields.indexOf("password") !== -1}
                    />

                    <Form.Input
                        label="Confirmar Senha"
                        type="password"
                        name="password_confirmation"
                        value={data.password_confirmation}
                        onChange={this.onChange}
                        placeholder="Confirmar Senha"
                        required
                        error={
                            errorsFields.indexOf("password_confirmation") !== -1
                        }
                    />
                </Form.Group>

                <Message error list={errors.map(e => e.message)} />
            </Form>
        );
    }
}

PasswordResetForm.propTypes = {
    submit: PropTypes.func.isRequired
};

export default PasswordResetForm;
