export const FETCH_ALL_REQUEST = "Machines/FETCH_ALL_REQUEST";
export const FETCH_ALL_SUCCESS = "Machines/FETCH_ALL_SUCCESS";

export const UPDATE_SUCCESS = "Machines/UPDATE_SUCCESS";
export const CREATE_SUCCESS = "Machines/CREATE_SUCCESS";
export const DELETE_SUCCESS = "Machines/DELETE_SUCCESS";
export const ADD_CLICK = "Machines/ADD_CLICK";
export const EDIT_CLICK = "Machines/EDIT_CLICK";
export const DELETE_CLICK = "Machines/DELETE_CLICK";
export const DELETE_CANCELED = "Machines/DELETE_CANCELED";
export const EDIT_CANCELED = "Machines/EDIT_CANCELED";

export const REDUCER_NAME = "machines";
