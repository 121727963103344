import { NotificationRulesPage } from "./modules/NotificationRules";
import { FilterRulesPage } from "./modules/FilterRules";
import { PrioritiesPage } from "./modules/Priorities";
import { MachinesPage } from "./modules/Machines";
import { UsersPage } from "./modules/Users";
import { PermissionsPage } from "./modules/Permissions";
import { SectorsPage } from "./modules/Sectors";
import { CallTechnicianPage } from "./modules/CallTechnician";
import { KanbanPage } from "./modules/Kanban";
import { OverviewPage } from "./modules/Overview";
import { OccurrencesPage } from "./modules/Occurrences";
import { LoginPage, SignupPage } from "./modules/Auth";
import { DashboardPage } from "./modules/Dashboard";
import { HelpPage } from "./modules/Help";

export const USER_ROUTES = [
  {
    path: "/dashboard",
    component: DashboardPage,
    slug: "dashboard",
  },
  {
    path: "/call-technician",
    component: CallTechnicianPage,
    slug: "call-technician",
  },
  {
    path: "/kanban",
    component: KanbanPage,
    slug: "kanban",
  },
  {
    path: "/occurrences",
    component: OccurrencesPage,
    slug: "occurrences",
  },
  {
    path: "/machines",
    component: MachinesPage,
    slug: "machines",
  },
  {
    path: "/priorities",
    component: PrioritiesPage,
    slug: "priorities",
  },
  {
    path: "/users",
    component: UsersPage,
    slug: "users",
  },
  {
    path: "/permissions",
    component: PermissionsPage,
    slug: "permissions",
  },
  {
    path: "/sectors",
    component: SectorsPage,
    slug: "sectors",
  },
  {
    path: "/overview",
    component: OverviewPage,
    slug: "overview",
  },
  {
    path: "/notification-rules",
    component: NotificationRulesPage,
    slug: "notification-rules",
  },
  {
    path: "/filter-rules",
    component: FilterRulesPage,
    slug: "filter-rules",
  },
  {
    path: "/help",
    component: HelpPage,
    slug: "help",
  },
];

export const GUEST_ROUTES = [
  {
    path: "/",
    component: LoginPage,
  },
  {
    path: "/signup",
    component: SignupPage,
  },
];
